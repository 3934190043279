'use strict';

var onDocumentLoad = require('@mol-fe/mol-fe-client-utils/src/dom/onDocumentLoad');

var elementIsVisibleInViewPort = require('./elementIsVisibleInViewPort');

var _require = require('./viewport'),
    onScroll = _require.onScroll,
    onOrientationChange = _require.onOrientationChange;

var _require2 = require('./calc'),
    calcPositionStyles = _require2.calcPositionStyles,
    calcPaddingStyles = _require2.calcPaddingStyles;

function waitForElementToLoad(el) {
  return new Promise(function (resolve) {
    switch (el.tagName.toLowerCase()) {
      case 'img':
        resolve(waitForImg(el));
        break;
      case 'video':
        resolve();
        break;
      default:
        resolve();
    }
  });
}

function waitForImg(img) {
  return new Promise(function (resolve, reject) {
    if (!img.src) {
      reject();
    } else if (img.complete) {
      resolve();
    } else {
      img.addEventListener('load', resolve);
      img.addEventListener('error', reject);
    }
  });
}

function isMobile() {
  return window.PageCriteria && window.PageCriteria.isMobile && !isTablet();
}

function isTablet() {
  return window.PageCriteria && window.PageCriteria.isTablet;
}

function nodeListToArray(nodeList) {
  return [].map.call(nodeList, function (el) {
    return el;
  });
}

module.exports = {
  elementIsVisibleInViewPort: elementIsVisibleInViewPort,
  isMobile: isMobile,
  isTablet: isTablet,
  onDocumentLoad: onDocumentLoad,
  onOrientationChange: onOrientationChange,
  onScroll: onScroll,
  waitForElementToLoad: waitForElementToLoad,
  waitForImg: waitForImg,
  calcPositionStyles: calcPositionStyles,
  calcPaddingStyles: calcPaddingStyles,
  nodeListToArray: nodeListToArray
};
