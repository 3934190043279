'use strict';

var InteractiveSocialButton = require('../base/interactive-social-button');
var SharePopup = require('./share-popup');

var ShareButton = InteractiveSocialButton.extend();
ShareButton.prototype.buttonName = 'ShareButton';

ShareButton.prototype.getPopup = function () {
  return new SharePopup(this.player_);
};

module.exports = ShareButton;
