'use strict';

var TapToUnmuteControl = videojs.Button.extend({
  init: function (player) {
    videojs.Button.call(this, player);
    var _this = this;

    var handleVolumeChange = function () {
      if (!player.muted()) {
        _this.onClick();
      }
    };

    player.on('volumechange', handleVolumeChange);

    this.on('dispose', function () {
      player.off('volumechange', handleVolumeChange);
    });
  }
});

TapToUnmuteControl.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'vjs-tap-to-unmute-control'
  });
};

TapToUnmuteControl.prototype.onClick = function (event) {
  var player = this.player();

  player.muted(false);
  player.removeChild(this);
  this.dispose();

  if (event) {
    event.stopPropagation();
  }

  return false;
};

module.exports = TapToUnmuteControl;
