'use strict';

var RelatedVideo = require('./related-videos-video');

var RelatedVideoList = videojs.Component.extend({
  init: function (player, options) {
    var index;

    videojs.Component.call(this, player, options);

    for (index = 0; index < options.videos.length; index++) {
      this.addChild(new RelatedVideo(player, { video: options.videos[index] }));
    }
  }
});

RelatedVideoList.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'mol-related-videos-video-list'
  });
};

module.exports = RelatedVideoList;
