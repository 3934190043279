'use strict';

/**
 * Loads the script source.
 *
 * @ignore
 * @param {string} src - The script source.
 * @param {Object} options - The allowed options are:
 *                           placeholder: Element that should contain the script. Defaults to the parentNode of the currentScript or
 *                                        if missing to document.head .
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
var loadScript = function loadScript(src, options) {
  if (!src) {
    throw new TypeError('Missing required "src" parameter');
  }

  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    var scriptPlaceholder = options && options.placeholder;

    script.type = 'text/javascript';
    script.async = false;
    script.defer = true;
    script.onerror = function () {
      return reject(new URIError('The script ' + src + ' is not accessible.'));
    };
    script.onload = function () {
      return resolve(script);
    };

    if (!scriptPlaceholder) {
      scriptPlaceholder = document.currentScript ?
      /* istanbul ignore next */
      document.currentScript.parentNode : document.head;
    }

    script.src = src;
    scriptPlaceholder.appendChild(script);
  });
};

exports.default = loadScript;
