'use strict';

/*jshint camelcase: false*/

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var urlUtils = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/urlUtils');

var DFPTagBuilderError = require('./dfp-tag-builder-error');

/**
 * Builder class to ease the creation of master video tags manual.
 * A master video tag is a URL used by the video player to retrieve the video ad.
 * Here's a sample master video tag:
 *
 * http://pubads.g.doubleclick.net/gampad/ads?
 *  env=vp&
 *  gdfp_req=1&
 *  impl=s&
 *  output=xml_vast2&
 *  iu=/6062/video-demo&
 *  sz=400x300&
 *  unviewed_position_start=1&
 *  url=http://www.simplevideoad.com&
 *  ciu_szs=728x90,300x250&
 *  correlator=7105
 *
 *
 * @param videoAdServerUrl {String} passed from the clj_fe it is used to know the base_url and the default params.
 * @returns {DFPTagBuilder}
 * @constructor
 */
function DFPTagBuilder(videoAdServerUrl) {
  if (!(this instanceof DFPTagBuilder)) {
    return new DFPTagBuilder(videoAdServerUrl);
  }

  if (!videoAdServerUrl) {
    throw new DFPTagBuilderError('Missing videoAdServerUrl');
  }

  var defaultParams = {
    env: 'vp',
    gdfp_req: 1,
    impl: 's',
    output: 'xml_vast3',
    unviewed_position_start: 1,
    hl: 'en'
  };

  var vendorAdUrl = getUrlParts(videoAdServerUrl);

  this.base_url = vendorAdUrl.base_url;
  this.params = utilities.extend({}, defaultParams, params(vendorAdUrl.search));

  /*** Local Functions ***/

  function getUrlParts(url) {
    var parts = url.split('?');
    return {
      base_url: parts[0],
      search: parts[1]

    };
  }

  function params(search) {
    function isNotDynamicParam(key, value) {
      return value && !(/(\[.+])/g.test(value));
    }

    if (!search) {
      return {};
    }

    return urlUtils.queryStringToObj(search, isNotDynamicParam);
  }
}

/**
 * Encodes the passed obj map into a string that can be added to a video tag
 *
 *  For more info please refer to https://support.google.com/dfp_sb/answer/1080597
 *
 * @param map {object} key value pairs to add to ta master video tag
 * @return {string}
 */
DFPTagBuilder.encodeCustomParams = function (map) {
  if (utilities.isObject(map)) {
    utilities.forEach(map, function (value, key) {
      if (utilities.isArray(value)) {
        map[key] = value.map(encodeURIComponent).join(',');
      } else {
        map[key] = encodeURIComponent(value);
      }
    });

    return encodeURIComponent(urlUtils.objToQueryString(map));
  }

  throw new DFPTagBuilderError('encodeCustomParams only accepts an object map of key value pairs.');
};

/**
 * Indicates current ad uni.
 * Must follow the format:
 *      /network id/directory tree.../ad unit
 *
 * @param iu {string} current add unit
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.iu = function (iu) {
  var ALLOWED_FORMAT = /^\/[a-zA-z0-9\-_\.~]+(\/[a-zA-z0-9\-\_\.\~]+)*/;

  if (!ALLOWED_FORMAT.test(iu)) {
    throw new DFPTagBuilderError('\'iu\' param must follow format: /network_id/directory_tree.../ad_unit');
  }

  this.params.iu = encodeURIComponent(iu);
  return this;
};

/**
 * Size of master video ad slot.
 * Multiple sizes aren't supported. Important: Don't include 'v' after the size.
 *
 * Sample: 400x300
 * @param size {string} of master video ad slot
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.sz = function (size) {
  var ALLOWED_FORMAT = /^[0-9]+x[0-9]+/;

  if (!ALLOWED_FORMAT.test(size)) {
    throw new DFPTagBuilderError('\'sz\' param must follow format like: 300x400.');
  }

  this.params.sz = size;
  return this;
};

/**
 * The address from which the ad request is being sent. By default, this is the same URL as that of the page that will
 * display the ads, but you can change it.
 *
 * For example:   http://www.simplevideoad.com
 *
 * @param url {string} address of the requester
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.url = function (url) {
  this.params.url = encodeURIComponent(urlUtils.urlParts(url).href);
  return this;
};

/**
 * A random number, typically the time stamp of the page view, that's shared by multiple requests coming from the same page
 * view. Correlator is used to implement competitive exclusions, including in cookieless environments.
 *
 * For example: 4345645667
 *
 * @param correlator {number}  A random number, typically the time stamp of the page view
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.correlator = function (correlator) {
  if (utilities.isNumber(correlator) && !isNaN(correlator)) {
    this.params.correlator = correlator;
    return this;
  }

  throw new DFPTagBuilderError('the passed correlator is not a number');
};

/**
 * The URL of the page that contains the player that is making the request.
 * Required if you use Ad Exchange or AdSense for dynamic allocation. description_url should be a video-specific URL
 * (not the domain for all ad requests).
 *
 * For Example:
 *             http%3A%2F%2Fwww.simplevideoad.com%2Fgolf.html
 *             (i.e. the single-encoded version of http://www.simplevideoad.com/golf.html)
 *
 * @param url {string} the URL of the page that contains the player that is making the request
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.description_url = function (url) {
  this.params.description_url = encodeURIComponent(urlUtils.urlParts(url).href);
  return this;
};

/**
 * The language code used to request ads in that language.
 * Used for language of ad selection and video ad rendering in dynamic allocation to video in the AdExchange
 * or AdSense Video.
 *
 * The language can be any ISO 639-1 (two-letter) or ISO 639-2 (three-letter) code.
 * Defaults to English (en).
 *
 * Please refer here for a list of valid codes: http://www.loc.gov/standards/iso639-2/php/English_list.php.
 *
 * @param hl {string}
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.hl = function (hl) {
  this.params.hl = hl;
  return this;
};

/**
 * Comma-separated list of companion sizes.
 *
 * For example: 728x90,300x250
 * @param sizes {string}
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.ciu_szs = function (sizes) {
  this.params.ciu_szs = sizes;
  return this;
};

/**
 * Slot-specific customised parameters
 *
 * For example: excl_cat%3Dairline_exclusion_label%7C
 *
 * @param scp {string}
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.scp = function (scp) {
  this.params.scp = scp;
  return this;
};

/**
 * Customised targeting parameters
 *
 * @param params {object} customized targeting parameters
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.cust_params = function (params) {
  this.params.cust_params = DFPTagBuilder.encodeCustomParams(params);
  return this;
};

/**
 * Configures whether the page should request personalized or non-personalized ads. Personalized ads served by default.
 *
 * @param  {boolean} nonPersonalizedAds Whether to serve non personalized ads. Defaults to true.
 * @return {DFPTagBuilder}
 */
DFPTagBuilder.prototype.setRequestNonPersonalizedAds = function (nonPersonalizedAds) {
  if (nonPersonalizedAds || typeof nonPersonalizedAds === 'undefined') {
    this.params.npa = 1;
  }

  return this;
};

/**
 * An integer generated for each video stream; the number needs to be the same within a stream
 * and unique within a page view. Used for competitive exclusions,
 * frequency capping and related features if a user is watching multiple videos on the same page.
 *
 * While it is not required, must be passed for features such as competitive exclusions,
 * frequency capping and related features to work correctly.
 *
 * For example: 17
 *
 * @param scor {number}
 * @returns {DFPTagBuilder}
 */
DFPTagBuilder.prototype.scor = function (scor) {
  if (utilities.isNumber(scor) && !isNaN(scor)) {
    this.params.scor = scor;
    return this;
  }

  throw new DFPTagBuilderError('the passed scor is not a number');
};

DFPTagBuilder.prototype.addParam = function (name, value) {
  this.params[name] = value;
  return this;
};

DFPTagBuilder.prototype.build = function () {
  var params = this.params;
  var requiredParams = ['env', 'gdfp_req', 'impl', 'output', 'unviewed_position_start', 'hl', 'url', 'correlator', 'description_url'];
  utilities.forEach(requiredParams, function (param) {
    if (!params[param]) {
      throw new DFPTagBuilderError('Error building url, missing required param \'' + param + '\'');
    }
  });

  return this.base_url + '?' + urlUtils.objToQueryString(this.params);
};

module.exports = DFPTagBuilder;
