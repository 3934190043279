'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var urlUtils = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/urlUtils');
var campaignManager = require('./campaign-manager');
var adUtils = require('../../utils/ad-utils');

var players = {};

videojs.plugin('ads-setup', function molVastSetup(opts) {
  var player = this;
  var defaults = {
    vpaidEnabled: !player.options().isMobile
  };
  var options = overwriteAdOptions(
    utilities.extend({}, defaults, opts, campaignManager.getConfig())
  );

  // Requested by commercial on MOL-15712
  var unmutePlayer = function () {
    player.muted(false);
  };

  player.on('fullscreenchange', unmutePlayer);
  player.on('flyout-expand', unmutePlayer);
  players[player.id()] = player;

  //We need the player to be ready in order to properly generate the ad tag.
  player.ready(function () {
    if (options.useIMASDK && adUtils.isIMASupportedBrowser()) {
      player.adSDK = 'IMA';
      player['ima-setup'](options);
    } else {
      player.adSDK = 'MOL';
      player['vast-setup'](options);
    }
  });

  /*** local functions ***/
  function overwriteAdOptions(options) {
    var queryOptions = urlUtils.queryStringToObj(window.location.search);
    options.useIMASDK = utilities.isDefined(queryOptions.useIMASDK) ? queryOptions.useIMASDK == 'true' : options.useIMASDK;
    options.adsCancelTimeout = utilities.isDefined(queryOptions.adsCancelTimeout) ? parseInt(queryOptions.adsCancelTimeout, 10) : options.adsCancelTimeout;

    if (utilities.isString(queryOptions.referer)) {
      options.domain = queryOptions.referer;
    } else {
      options.domain = player.options().isEmbedded ? 'notdailymail' : 'dailymail';
    }

    return options;
  }
});
