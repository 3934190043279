'use strict';

// EXTERNAL DEPENDENCIES
require('video.js/dist/video-js/video.dev');

// libs
// TODO: can be replaced with standard bower_components version when moving to videojs 5
require('../../libs/videojs-media-sources');
require('../../libs/videojs.hls');

// VIDEOJS PATCHES
require('./patches/');

// VIDEOJS COMPONENTS
require('./components/');

// VIDEOJS PLUGINS
require('./plugins/');
