'use strict';

function getBundleLocation() {
  var DM = window.DM;

  if (DM && DM.getPageMetadata) {
    return DM.getPageMetadata().videoBundleLocation;
  }

  return null;
}

function getZeroClipboardSWFPath() {
  var bl = mol.getBundleLocation();
  if (bl) {
    return bl + '/assets/ZeroClipboard.swf';
  } else {
    return '../assets/ZeroClipboard.swf';
  }
}

function getVideoJsSWFPath() {
  var bl = mol.getBundleLocation();
  if (bl) {
    return bl + '/assets/video-js.swf';
  } else {
    return '../assets/video-js.swf';
  }
}

function getVpaidFlashLoaderPath() {
  var bl = mol.getBundleLocation();
  if (bl) {
    return bl + '/assets/VPAIDFlash.swf';
  } else {
    return '../assets/VPAIDFlash.swf';
  }
}

function getVideoPlayerVersion() {
  var bl = mol.getBundleLocation();
  if (bl) {
    bl = bl.replace(/\/+$/, '');
    return bl.split('/').pop();
  }

  return 'UNKNOWN';
}

var mol = {
  getBundleLocation: getBundleLocation,
  getZeroClipboardSWFPath: getZeroClipboardSWFPath,
  getVideoJsSWFPath: getVideoJsSWFPath,
  getVpaidFlashLoaderPath: getVpaidFlashLoaderPath,
  getVideoPlayerVersion: getVideoPlayerVersion
};

module.exports = mol;
