'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var utilities = utils.utilityFunctions;
var dom = utils.dom;

/* Interactive Social Share Button - Base class for all social share buttons that need a popup that covers the whole video
 ================================================================================ */

// Interactive Social Button - Base class for all the social buttons that need to open a full width popup
var InteractiveSocialButton = videojs.Button.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Button.call(this, player, options);
    this.buttonPressed_ = false;
  }
});

InteractiveSocialButton.prototype.createEl = function () {
  return videojs.Button.prototype.createEl.call(this, 'div', {
    className: 'vjs-social-share-control vjs-control vjs-social-share-control-' + utilities.snakeCase(this.buttonName, '-'),
    innerHTML: '<div class="vjs-social-icon vjs-' + utilities.snakeCase(this.buttonName, '-') + '-icon "></div>'
  });
};

//getPopup method - To be overridden by the specific InteractiveSocialButton implementations
InteractiveSocialButton.prototype.getPopup = function () { };

InteractiveSocialButton.prototype.onClick = function () {
  if (this.buttonPressed_) {
    this.closePopup();
  } else {
    this.openPopup();
  }
};

InteractiveSocialButton.prototype.openPopup = function () {
  var player = this.player();
  var popupComponent = this.getPopup();
  dom.addClass(this.el_, 'active');
  this.buttonPressed_ = true;

  player.wasPlaying_ = !player.paused();

  if (popupComponent) {
    popupComponent.isOpen_ = false;
    player.trigger('CloseButton.pressed');//We ensure that no other popup is already open
    this.buttonPressed_ = true;
    this.currentPopup_ = popupComponent;
    popupComponent.one('FullWidthPopup.close', videojs.bind(this, InteractiveSocialButton.prototype.closePopup));
    player.one('play', videojs.bind(this, InteractiveSocialButton.prototype.closePopup));
    player.addChild(popupComponent);

    //hack to prevent the video from resuming when you click one popup button when another is already open.
    setTimeout(function () {
      if (player.hasStarted() && !player.paused()) {
        player.pause();
      }

      popupComponent.isOpen_ = true;
    }, 0);
  }

  var videoElement = document.querySelector('#' + player.tagAttributes.id + ' video');

  if (videoElement) {
    //iPhone 3&4 (iOS 6&7) requires the video tag to be hidden whilst the social button popup is open for buttons to work
    // note: player.tag is returned null, so using query selector
    dom.addClass(videoElement, 'hideVideoForSocialShare');
  }
};

InteractiveSocialButton.prototype.closePopup = function () {
  var player = this.player();
  var popupComponent = this.currentPopup_;

  dom.removeClass(this.el_, 'active');

  var videoElement = document.querySelector('#' + player.tagAttributes.id + ' video');

  if (videoElement) {
    dom.removeClass(videoElement, 'hideVideoForSocialShare');
  }

  this.buttonPressed_ = false;

  if (popupComponent && popupComponent.isOpen_) {
    popupComponent.isOpen_ = false;
    player.removeChild(popupComponent);

    if (player.hasStarted() && player.wasPlaying_) {
      player.play();
    }

    //this.player_.off('CloseButton.pressed');
  }
};

module.exports = InteractiveSocialButton;
