'use strict';

var onScroll = require('./onScroll');
var onOrientationChange = require('./onOrientationChange');

function createStoppableListener(stopFunction) {
  return {
    stop: stopFunction.bind(stopFunction)
  };
}

function onScrollStoppable(targets, cb, delay) {
  if (targets.constructor !== Array) {
    targets = [targets];
  }

  var eventHandler = onScroll(targets, cb, delay);
  return createStoppableListener(function () {
    targets.forEach(function (target) {
      if (!target || !target.removeEventListener) {
        return;
      }

      target.removeEventListener('scroll', eventHandler);
    });
  });
}

function onOrientationChangeStoppable(cb) {
  var eventHandler = onOrientationChange(cb);
  return createStoppableListener(function () {
    window.removeEventListener('orientationchange', eventHandler);
    window.removeEventListener('resize', eventHandler);
  });
}

module.exports = {
  onScroll: onScrollStoppable,
  onOrientationChange: onOrientationChangeStoppable
};
