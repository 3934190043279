'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

require('babel-polyfill');

var _es6Promisify = require('es6-promisify');

var _es6Promisify2 = _interopRequireDefault(_es6Promisify);

var _logger = require('./util/logger');

var _ima = require('./ima/ima');

var _ima2 = _interopRequireDefault(_ima);

var _imaClient = require('./ima/imaClient');

var _imaClient2 = _interopRequireDefault(_imaClient);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function molImaPlugin(settings) {
  var player = this;
  var videoEl = player.el().querySelector('.vjs-tech');
  var getAdTag = function getAdTag() {
    if (typeof settings.adTagUrl === 'function') {
      return (0, _es6Promisify2.default)(settings.adTagUrl)();
    }

    return Promise.resolve(settings.adTagUrl);
  };

  var timeout = settings.adCancelTimeout || 15000;
  var _firstPlay = true;
  var _adPlaying = false;
  var ima = void 0,
      adContainerDiv = void 0;

  if (!_ima2.default.isSupported()) {
    (0, _logger.log)('mol-fe-videojs-ima warn: IMA SDK is not supported on the current browser');
    return;
  }

  if (!videoEl || videoEl.nodeName !== 'VIDEO') {
    (0, _logger.log)('mol-fe-videojs-ima warn: IMA SDK can not work with non video elements', videoEl);
    return;
  }

  videoEl.autoplay = false;
  videoEl.pause();

  monkeyPatchPlayerApi(player);

  adContainerDiv = document.createElement('div');
  player.el().appendChild(adContainerDiv);
  ima = new _imaClient2.default(videoEl, adContainerDiv, settings);

  player.on('ima.firstPlay', function () {
    if (hasFlashTech(player)) {
      (0, _logger.log)('mol-fe-videojs-ima warn: IMA plugin can not work with a flash tech');
      return player.trigger('ima.adsCancel');
    }

    if (!settings.adsEnabled) {
      (0, _logger.log)('mol-fe-videojs-ima warn: Ads are not enabled');
      return player.trigger('ima.adsCancel');
    }

    getAdTag().then(function (adTag) {
      _adPlaying = true;

      if (!ima.adDisplayContainer || !ima.adsLoader) {
        var timeoutPromise = new Promise(function (resolve, reject) {
          setTimeout(function () {
            return reject(new Error('IMA Error: Timeout while trying to load the ad sdk'));
          }, timeout);
        });
        return Promise.race([ima.imaPromise, timeoutPromise]).then(function () {
          ima.requestAd(adTag);
          player.addClass('ima-ad-playing');
        });
      }

      ima.requestAd(adTag);
      player.addClass('ima-ad-playing');
    }).catch(function (error) {
      if (typeof error.getVastErrorCode === 'function') {
        error.message = error.getMessage();
        error.code = error.getVastErrorCode();
      }

      player.trigger({
        type: 'ima.adsError',
        error: error
      });
    });
  });

  player.on('ima.adEnd', resumeVideoContent);
  player.on('ima.adsCancel', resumeVideoContent);
  player.on('ima.adsError', function (evt) {
    var error = evt.error;
    (0, _logger.log)(error, error.message);

    if (error.stack) {
      (0, _logger.log)(error.stack);
    }

    if (_adPlaying) {
      resumeVideoContent();
    }
  });

  player.on('ima.muted', function () {
    player.muted(true);
  });

  player.on('ima.non-muted', function () {
    player.muted(false);
  });

  player.on('ima.volume', function (e) {
    player.volume(e.volume);
  });

  player.on('ima.fullscreen', function () {
    if (player.isFullscreen()) {
      player.exitFullscreen();
    } else {
      player.requestFullscreen();
    }
  });

  player.on('fullscreenchange', function () {
    ima.resizeAd(player.isFullscreen());
  });

  player.on('resize', function () {
    ima.resizeAd(player.isFullscreen());
  });

  player.ima = {
    reset: function reset(opts) {
      resetImaPlugin();

      if (opts.adsEnabled) {
        settings.adsEnabled = opts.adsEnabled;
      }

      if (opts.adTagUrl) {
        settings.adTagUrl = opts.adTagUrl;
      }

      player.removeClass('ima-ad-playing');
    },

    enable: function enable() {
      settings.adsEnabled = true;
    },

    disable: function disable() {
      settings.adsEnabled = false;
    },

    isEnabled: function isEnabled() {
      return settings.adsEnabled;
    },

    isPlayingAd: function isPlayingAd() {
      return _adPlaying;
    }
  };
  /*** Local Functions ***/
  function hasFlashTech(player) {
    return player.el().querySelector('.vjs-tech').tagName.toLowerCase() === 'object';
  }

  function resumeVideoContent() {
    _adPlaying = false;
    player.removeClass('ima-ad-playing');
    player.play();
  }

  function resetImaPlugin() {
    _firstPlay = true;
    _adPlaying = false;
    ima.reset();
  }

  /**
   What this function does is ugly and horrible and I should think twice before calling myself a good developer. With that said,
   it is the best solution I could find to mute the video until the 'play' event happens (on mobile devices) and the plugin can decide whether
   to play the ad or not.
    If you have a better solution please do tell me.
   */
  function monkeyPatchPlayerApi(player) {

    /**
     * Monkey patch needed to handle firstPlay and resume of playing ad.
     *
     * @returns {player}
     */
    var origPlay = player.play;
    player.play = function () {
      if (_adPlaying) {
        ima && ima.resume();
        player.trigger('play'); //We notify the play to the player
      } else {
        if (isFirstPlay()) {
          _firstPlay = false;
          player.trigger('ima.firstPlay');
        } else {
          origPlay.apply(this, arguments);
        }
      }

      return this;
    };
    /**
     * Monkey patch needed to handle pause of an ad using the player's api.
     *
     * @returns {player}
     */
    var origPause = player.pause;
    player.pause = function () {
      if (_adPlaying) {
        ima && ima.pause();
        player.trigger('pause'); //We notify the play to the player
      } else {
        origPause.apply(this, arguments);
      }

      return this;
    };
  }

  function isFirstPlay() {
    return _firstPlay;
  }
}

exports.default = molImaPlugin;
