'use strict';

var LinkPopupContent = require('./link-popup-content');
var FullWidthPopup = require('../base/full-width-popup');

var LinkPopup = FullWidthPopup.extend({
  /** @Constructor */
  init: function (player, options) {
    FullWidthPopup.call(this, player, options);
    this.addChild(new LinkPopupContent(player));
  }
});

LinkPopup.prototype.createEl = function () {
  return FullWidthPopup.prototype.createEl.call(this, 'div', {
    className: 'vjs-link-popup'
  });
};

module.exports = LinkPopup;
