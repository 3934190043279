'use strict';

// EXTERNAL DEPENDENCIES
// bower components
require('video.js/dist/video-js/video.dev');
require('@mol-fe/mol-fe-videojs-ima');
require('videojs-vast-vpaid/dist/videojs_4.vast.vpaid.js');

// VIDEO PLAYER CORE PLUGINS
require('./core/');

// VIDEO PLAYER UI
require('@mol-fe/mol-fe-videoplayer-ui');

// VIDEO PLAYER PLUGINS
require('./plugins/');

if (!DM.molFeVideoplayer) {

  DM.molFeVideoplayer = require('./setup/setup-mol-video-js-players');

  //TODO: [CS, MM] remove this alias once the decoupling of videos is stable and we have updated gallery and carousel please check MOL-7904
  DM.setupMolVideoJsPlayers = DM.molFeVideoplayer;

  DM.onDocReady(function () {
    DM.molFeVideoplayer.init(document);
    DM.later.go('videoplayer-ready');
  });

  require('./integration/mol-site-integration');

}
