'use strict';

/*jshint camelcase: false*/
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var Promise = require('es6-promise').Promise;
var DFPTagBuilder = require('./ad-tag-builders/dfp-tag-builder');
var VIDEOTAGS_TIMEOUT = 1500;
var browserUtils = require('./browser');
var isAutoplaying = require('./isAutoplaying');

function isFlyoutOnAndCollapsed(player) {
  return !!(player.flyoutState && player.flyoutState() === 0); //if the flyout state is 0 it means that it is on the right hand rail.
}

function isMuted(player) {
  return player.muted() || player.volume() === 0;
}

var adUtils = {
  getScreenSize: function getScreenSize(player, isMobile) {
    var tech;
    if (isMobile) {
      return {
        width: 640,
        height: 360
      };
    }

    tech = player.el().querySelector('.vjs-tech');
    return {
      width: tech.offsetWidth,
      height: tech.offsetHeight
    };
  },

  getCustomParams: function getCustomParams(player, options, screen) {
    var durationInSeconds = player.duration();

    //noinspection JSUnresolvedVariable
    var customParams = {
      length: (durationInSeconds > 120) ? 120 : Math.floor(durationInSeconds / 30) * 30,
      videoWidth: screen.width,
      videoHeight: screen.height,
      play: player.videoStarts ? player.videoStarts + 1 : 1,
      embed: !!player.options().isEmbedded,
      ttid: player.options().videoId,
      domain: options.domain,
      flyaway: isFlyoutOnAndCollapsed(player),
      s: isMuted(player) ? 'off' : 'on'
    };

    if (options.title) {
      customParams.videoTitle = options.title.replace(/\s/g, '_').replace(/[^_\w]/g, '');
    }

    if (options.source) {
      customParams.video = options.source.replace(/\s/g, '_');
    }

    if (window.adEnvironment) {
      customParams.environment = window.adEnvironment;
    }

    var values = options.cust_params || {};

    Object.keys(values).forEach(function (key) {
      customParams[key] = values[key];
    });

    customParams.sz = screen.width + 'x' + screen.height;

    if (options.indexHeaderParams) {
      Object.keys(options.indexHeaderParams).forEach(function (key) {
        customParams[key] = options.indexHeaderParams[key];
      });
    }

    return customParams;
  },

  buildDFPAdTag: function buildDFPAdTag(player, options) {
    var screen = utilities.isObject(options.screen) ? options.screen : adUtils.getScreenSize(player, player.options().isMobile);
    var linkBaseURL = player.options().linkBaseURL;

    var getOptions = function (_options) {
      var options = JSON.parse(JSON.stringify(_options));
      if (player.options()['fbia-embed'] && options.videoAdServerUrl.indexOf('dm.videofb') === -1) {
        options.videoAdServerUrl = options.videoAdServerUrl.replace(/dm\.video/g, 'dm.videofb');
      } else if (player.options().syndicationService === 'fbia'
        && options.videoAdServerUrl.indexOf('dm.videofb') === -1) {
        options.videoAdServerUrl = options.videoAdServerUrl.replace(/dm\.video/g, 'dm.videofb');
      } else if (player.options().syndicationService === 'gamp'
        && options.videoAdServerUrl.indexOf('dm.videoamp') === -1) {
        options.videoAdServerUrl = options.videoAdServerUrl.replace(/dm\.video/g, 'dm.videoamp');
      }

      var muted = isMuted(player);
      var autoplay = isAutoplaying(player);
      var flyaway = isFlyoutOnAndCollapsed(player);

      var adUnitModifier = '';

      if (autoplay) {
        adUnitModifier += 'ap';
      }

      if (flyaway) {
        adUnitModifier += 'fa';
      }

      if (adUnitModifier.length) {
        options.videoAdServerUrl = options.videoAdServerUrl.replace(/(dm\.video)([^&]+)/, '$1$2_' + adUnitModifier);
      }

      var returnPromise = Promise.resolve(options);

      if (window.adverts && (typeof window.adverts.getVideoTags === 'function')) {
        returnPromise = returnPromise.then(function (options) {

          var playerOpts = player.options();
          var articleURL = playerOpts.grapeshot && playerOpts.grapeshot.article && playerOpts.grapeshot.article.articleURL;
          var getVideoTagsPromise = window.adverts.getVideoTags({
            articleURL: articleURL || undefined,
            timeout: VIDEOTAGS_TIMEOUT,
            screen: screen,
            videoAdServerUrl: options.videoAdServerUrl,
            muted: muted,
            autoplay: autoplay
          })
          .then(function (params) {
            options = Object.assign(params, options);

            return options;
          });

          var timeoutPromise = new Promise(function (resolve) {
            setTimeout(function () {
              resolve(options);
            }, VIDEOTAGS_TIMEOUT);
          });

          return Promise.race([getVideoTagsPromise, timeoutPromise]);
        })
        .catch(function (error) {
          console.error(error);
          return options;
        });
      }

      return returnPromise;
    };

    return getOptions(options)
      .then(function (options) {
        return new DFPTagBuilder(options.videoAdServerUrl)
        .correlator(new Date().valueOf())//By request from Tom Pickworth <Tom.Pickworth@mailonline.co.uk> the correlator must be different on each request
        .cust_params(adUtils.getCustomParams(player, options, screen))
        .description_url(linkBaseURL)
        .url(linkBaseURL)
        .scor(options.scor)
        .sz(screen.width + 'x' + screen.height)
        .setRequestNonPersonalizedAds(!!options.npa)

        //NOTE: For some reason this ged param is required. We asked the ad guys Tom Pickworth <Tom.Pickworth@mailonline.co.uk> and
        // Matthew Wheatland <Matthew.Wheatland@mailonline.com> but we are yet to get a response from them
        .addParam('ged', 'ta1_ve2_eb11_pt20.16.20_td20_tt1_pd1_bs10_tv1_is0_er2930.0.3288.636_sv2_sp1_vi2491.0.3775.534_vp84_ct1_vb1_vl1_vr0')
        .addParam('vid', player.options().videoId)
        .build();
      });
  },

  isSupportedBrowser: function () {
    return browserUtils.isIE11() || !browserUtils.isIE();
  },

  isIMASupportedBrowser: function () {
    return browserUtils.isIE11() || !browserUtils.isIE();
  },

  isAutoplaying: isAutoplaying
};

module.exports = adUtils;
