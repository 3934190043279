'use strict';
/**
 * This plugin counts the number of video views the player has had and publish them in player.videoViews;
 */
videojs.plugin('video-starts', function () {
  var player = this;

  player.videoStarts = 0;
  player.one('playerstate.enter.contentPlaying', onVideoStart);

  /***** Local functions ****/
  function onVideoStart() {
    player.videoStarts++;
    once(player, ['playerstate.enter.ended', 'playerstate.enter.started'], onVideoComplete);
  }

  function onVideoComplete() {
    player.one('playerstate.enter.contentPlaying', onVideoStart);
  }

  function once(player, events, handler) {
    function listener() {
      handler.apply(null, arguments);

      events.forEach(function (event) {
        player.off(event, listener);
      });
    }

    events.forEach(function (event) {
      player.on(event, listener);
    });
  }
});
