'use strict';

var ramjet = require('ramjet/dist/ramjet');

var async = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/async');
var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');

var FlyoutControl = require('./flyout-control');

var flyoutUtils = require('./flyout-utils');

var FLYOUT_OFF = -1;
var FLYOUT_SIDE = 0;
var FLYOUT_CENTER = 1;

var SCROLLUP_OFFSET = 200;
var FLYOUT_CLASS = 'vjs-flyout-on';
var PLACEHOLDER_CLASS = 'vjs-flyout-placeholder';
var WRAPPER_FIXED_CLASS = 'vjs-flyout-wrapper-fixed';
var WRAPPER_ABSOLUTE_CLASS = 'vjs-flyout-wrapper-absolute';

var FLYOUT_CLASSES = [];
FLYOUT_CLASSES[FLYOUT_CENTER] = 'vjs-flyout-center';
FLYOUT_CLASSES[FLYOUT_SIDE] = 'vjs-flyout-side';

var DEFAULT_OPTIONS = {
  viewportOffset: 0,
  viewportGraceOffset: 0.75,
  switchBack: false,
  animations: true,

  // easing: 'easeInOut', //linear, easeIn, easeOut, easeInOut
  easing: 'easeOut', //linear, easeIn, easeOut, easeInOut
  easingScale: 'easeOut',
  animTime: 400,
  animTimeWait: 400
};

var forceFlyOutTriggered = false;
var forceFlyOutInProgress = false;

var BVP_POSITION_OFFSET = 100;

videojs.plugin('flyout', function flyoutSetup(opts) {

  var player = this;
  var playerEl = player.el();
  var containerEl = playerEl.parentNode;
  var containerClasses = containerEl.className;
  var playerHeight = playerEl.offsetHeight;

  var flyoutState = FLYOUT_OFF;
  var flyoutAnimCompleteState = FLYOUT_OFF;

  var flyAnim = {};
  flyAnim[FLYOUT_OFF] = flyback;
  flyAnim[FLYOUT_SIDE] = flyoutToSide;

  var FLYOUT_CLASSES_SIZE = [];
  FLYOUT_CLASSES_SIZE[FLYOUT_SIDE] = 'vjs-span-one-col';
  FLYOUT_CLASSES_SIZE[FLYOUT_CENTER] = /vjs-span-three-col/.test(containerClasses) ? 'vjs-span-three-col' : 'vjs-span-two-col';

  var options = flyoutUtils.overwriteAdOptions(utilities.extend(DEFAULT_OPTIONS, opts));

  // add control component
  player.addChild(new FlyoutControl(player));

  player.flyoutState = function () {
    return flyoutState;
  };

  var placeholderEl = flyoutUtils.createElBefore(containerClasses + ' ' + PLACEHOLDER_CLASS, containerEl);
  placeholderEl.style.backgroundImage = 'url(' + player.poster() + ')';
  placeholderEl.innerHTML = '<div class="' + PLACEHOLDER_CLASS + '-content"><span>.</span><span>.</span><span>.</span></div> ' +
                            '<div class="vjs-has-started vjs-visually-ready vjs-controls-enabled vjs-default-skin video-js vjs-paused vjs-user-inactive"></div>';

  var updatePlaceHolderText = function (text) {
    var placeholderContentEl = placeholderEl.querySelector('.' + PLACEHOLDER_CLASS + '-content');
    if (placeholderContentEl) {
      placeholderContentEl.innerHTML = text + '<span>.</span><span>.</span><span>.</span>';
    }
  };

  var absoluteElWrapper = flyoutUtils.createElBefore(WRAPPER_ABSOLUTE_CLASS, containerEl);
  var fixedElWrapper = flyoutUtils.createElBefore(WRAPPER_FIXED_CLASS, absoluteElWrapper);

  flyoutUtils.wrapElement(containerEl, absoluteElWrapper);
  flyoutUtils.wrapElement(absoluteElWrapper, fixedElWrapper);

  // this is for calculating scroll direction
  var currentPos = flyoutUtils.getVerticalOffset();
  var accumulatedScrollUp = 0;
  var scrollDelta = null;

  var flyoutPaused = false;
  var previousFlyoutState;

  var checkPositionOnScroll = function () {
    async.defer(checkPosition);
  };

  var checkPositionOnResize = utilities.debounce(function () {
    scrollDelta = 0;
    checkPositionOnScroll();
  }, 400);

  dom.addEventListener(window, 'scroll', function () {
    var newPos = flyoutUtils.getVerticalOffset();
    scrollDelta =  newPos - currentPos;
    currentPos = newPos;

    if (scrollDelta > 0) {
      accumulatedScrollUp = 0;
    } else {
      accumulatedScrollUp = accumulatedScrollUp + Math.abs(scrollDelta);
    }
  });

  dom.addEventListener(window, 'resize', checkPositionOnResize);
  dom.addEventListener(window, 'scroll', checkPositionOnScroll);

  player.on('dispose', function () {
    placeholderEl.parentNode.removeChild(placeholderEl);
    dom.removeEventListener(window, 'resize', checkPositionOnResize);
    dom.removeEventListener(window, 'scroll', checkPositionOnScroll);
    setTimeout(function () {
      fixedElWrapper.parentNode.removeChild(fixedElWrapper);
    }, 100);
  });

  player.on('flyout-minimize', minimize);
  player.on('flyout-expand', expand);
  player.on('flyout-switchon', switchOn);
  player.on('flyout-switchoff', switchOff);
  player.on('flyout-close', function () {
    player.pause();
    player.trigger({ type: 'flyout-switchoff', animationOff: true });
  });

  player.on('playerstate.enter.standby', function () {
    var offset = options.viewportGraceOffset * playerHeight;
    if (dom.isElementInViewport(placeholderEl, offset)) {
      player.trigger('flyout-switchoff');
    }
  });

  flyoutUtils.onDisableForceFlyout(function () {
    if (player.flyoutState() !== FLYOUT_OFF) {
      player.trigger('flyout-close');
    }

    disableForceFlyout();
  });

  checkPositionOnResize();

  var flyoutFlyback = utilities.throttle(function flyoutFlyback(animationOff) {
    var anim = flyAnim[flyoutState];

    if (flyoutAnimCompleteState !== flyoutState) {
      anim(animationOff);
    }
  }, options.animTime + options.animTimeWait);

  function switchOn(evt) {
    if (flyoutState !== FLYOUT_OFF) return;
    updatePlaceHolderText('Video playing bottom right');
    flyoutState = FLYOUT_SIDE;
    flyoutFlyback(evt.animationOff);
    player.flyoutOn = true;
  }

  function switchOff(evt) {
    if (flyoutState === FLYOUT_OFF) return;
    flyoutState = FLYOUT_OFF;
    flyoutFlyback(evt.animationOff);
    player.flyoutOn = false;
  }

  function expand() {
    setSyncState(FLYOUT_CENTER);
    updatePlaceHolderText('Video playing inline');
    player.flyoutExpanded = true;

    dom.removeClass(placeholderEl, FLYOUT_CLASSES[FLYOUT_SIDE]);
    dom.addClass(placeholderEl, FLYOUT_CLASSES[FLYOUT_CENTER]);
    dom.removeClass(containerEl, FLYOUT_CLASSES_SIZE[FLYOUT_SIDE]);
    dom.addClass(containerEl, FLYOUT_CLASSES_SIZE[FLYOUT_CENTER]);
    player.trigger('vast.resize');
  }

  function minimize() {
    setSyncState(FLYOUT_SIDE);
    updatePlaceHolderText('Video playing bottom right');
    player.flyoutExpanded = false;

    dom.removeClass(placeholderEl, FLYOUT_CLASSES[FLYOUT_CENTER]);
    dom.addClass(placeholderEl, FLYOUT_CLASSES[FLYOUT_SIDE]);
    dom.removeClass(containerEl, FLYOUT_CLASSES_SIZE[FLYOUT_CENTER]);
    dom.addClass(containerEl, FLYOUT_CLASSES_SIZE[FLYOUT_SIDE]);
    player.trigger('vast.resize');
  }

  function fireResizeOnAdstart() {
    player.trigger('vast.resize');
  }

  function flyoutToSide(animationOff) {
    utilities.forEach(FLYOUT_CLASSES, function (cl) {
      dom.removeClass(placeholderEl, cl);
    });

    utilities.forEach(FLYOUT_CLASSES_SIZE, function (cl) {
      dom.removeClass(containerEl, cl);
    });

    dom.addClass(containerEl, FLYOUT_CLASSES_SIZE[FLYOUT_SIDE]);
    dom.addClass(placeholderEl, FLYOUT_CLASS);
    dom.addClass(placeholderEl, FLYOUT_CLASSES[FLYOUT_SIDE]);

    anim(placeholderEl, containerEl,  animationOff, FLYOUT_SIDE);

    player.on('playerstate.enter.adPlaying', fireResizeOnAdstart);
  }

  function flyback(animationOff) {
    anim(containerEl, placeholderEl, animationOff, FLYOUT_OFF, function () {
      containerEl.className = containerClasses;
      dom.removeClass(placeholderEl, FLYOUT_CLASS);
    });

    player.on('playerstate.enter.adPlaying', fireResizeOnAdstart);
  }

  function setSyncState(newState) {
    flyoutState = newState;
    flyoutAnimCompleteState = newState;
  }

  function isFinished() {
    var el = player.el();
    return dom.hasClass(el, 'vjs-video-finished');
  }

  function isDisabled() {
    var isAdPlaying = player._states && player._states.state && player._states.state.get() === 'adPlaying';
    return (!isFinished() && player.paused() && !isAdPlaying);
  }

  function shouldSwitchOn(placeholderEl, offset) {
    var pos = dom.elementPositionInViewport(placeholderEl, offset);

    if (pos === 'INSIDE' || scrollDelta === 0) {
      return false;
    } else if (pos === 'ABOVE') {
      return scrollDelta >= 0;
    } else {
      return pos !== 'INSIDE' && accumulatedScrollUp >= SCROLLUP_OFFSET;
    }
  }

  function shouldSwitchOff(placeholderEl, offset) {
    if (forceFlyOutInProgress && DM.molContentPlayer) {
      var bvpEl = DM.molContentPlayer.el();
      var bvpRect = bvpEl.getBoundingClientRect();

      if (bvpRect.bottom > BVP_POSITION_OFFSET) {
        return true;
      }
    }

    if (isFinished() || canSwitchBack()) {
      var pos = dom.elementPositionInViewport(placeholderEl, offset);

      return pos === 'INSIDE';
    }

    return false;
  }

  function pauseFlyout() {
    previousFlyoutState = player.flyoutState();
    player.trigger({ type: 'flyout-switchoff', animationOff: true });
    flyoutPaused = true;
  }

  function resumeFlyout() {
    if (previousFlyoutState !== FLYOUT_OFF) {
      player.trigger({ type: 'flyout-switchon', animationOff: true });
    }

    if (previousFlyoutState === FLYOUT_CENTER) {
      player.trigger('flyout-expand');
    }

    flyoutPaused = false;
  }

  function forceFlyoutAutoplay() {
    if (opts.forceFlyout.muted) {
      player.muted(true);
    }

    forceFlyOutTriggered = true;
    forceFlyOutInProgress = true;
    player.forceFlyOutInProgress = true;

    player.trigger('flyout-switchon');
    player.one('flyout-switch-done', function () {
      player.play(true);
    });

    player.one('flyout-switchoff', function () {
      forceFlyOutInProgress = false;
      player.forceFlyOutInProgress = false;
    });

  }

  function checkPosition() {
    if (player.isFullscreen()) {
      if (!flyoutPaused) {
        pauseFlyout();
      }

      return;
    }

    if (flyoutPaused) {
      return resumeFlyout();
    }

    // it considers the grace area
    var offset = (flyoutState === FLYOUT_OFF ? options.viewportOffset : options.viewportGraceOffset) * playerHeight;

    if (canForceFlyout(offset)) {
      return isForceFlyoutEnabled() && forceFlyoutAutoplay();
    }

    if (isDisabled() && flyoutState === FLYOUT_OFF) {
      return;
    }

    if (flyoutState === FLYOUT_OFF) {
      if (!isFinished() && shouldSwitchOn(placeholderEl, offset) && !DM.molContentPlayer) {
        player.trigger('flyout-switchon');
      }
    } else {
      if (shouldSwitchOff(placeholderEl, offset)) {
        player.trigger('flyout-switchoff');
      }
    }
  }

  function isForceFlyoutEnabled() {
    var options = player.options().plugins.flyout;

    return options.forceFlyout &&
      (options.forceFlyout.active ||
      options.forceFlyout.enabled);
  }

  function disableForceFlyout() {
    var options = player.options();
    var forceFlyoutOptions = options.plugins.flyout.forceFlyout || {};

    forceFlyoutOptions.enabled = false;
    forceFlyoutOptions.active = false;
    options.plugins.flyout.forceFlyout = forceFlyoutOptions;
  }

  function canForceFlyout(offset) {
    if (DM.molContentPlayer) {
      var bvpEl = DM.molContentPlayer.el();
      var bvpRect = bvpEl.getBoundingClientRect();

      if (bvpRect.bottom > (-1 * BVP_POSITION_OFFSET)) {
        return false;
      }
    }

    return !forceFlyOutTriggered &&
      !player.hasStarted() &&
      shouldSwitchOn(placeholderEl, offset) &&
      !player.options().isMobile;
  }

  function canSwitchBack() {
    if (forceFlyOutInProgress) {
      return Boolean(options.forceFlyout && options.forceFlyout.switchBack);
    }

    return Boolean(options.switchBack);
  }

  function anim(fromEl, toEl, animationOff, state, cb) {
    // optional
    if (!utilities.isFunction(cb)) {
      cb = utilities.noop;
    }

    if (!options.animations || !!animationOff) {
      return next();
    }

    beforeAnim();

    ramjet.transform(fromEl, toEl, {
      duration: options.animTime,
      easing: ramjet[options.easing],
      easingScale: ramjet[options.easingScale],
      shallowClone: true,
      appendToBody: true, //TODO check if we can fix animation so we don't need to put ramjet animation in the body (so we can avoid adding that class in the body)
      done: onDone
    });

    // check if we can rename class
    dom.addClass(document.body, 'ramjet'); // this needs to be after ramjet.transform since will move the video from place

    function onDone() {
      afterAnim();
      next();
    }

    function next() {
      cb();
      afterCallback();
    }

    function beforeAnim() {
      ramjet.hide(containerEl);
    }

    function afterAnim() {
      dom.removeClass(document.body, 'ramjet'); // reset this to put video in the back to correct position
      ramjet.show(containerEl);
    }

    function afterCallback() {
      flyoutAnimCompleteState = state;
      player.trigger('flyout-switch-done');
      player.trigger('vast.resize');
      player.trigger('resize');
    }
  }

});

flyoutUtils.onDisableForceFlyout(function () {
  var molFeComponents =
    window.DM &&
    window.DM.molFeComponents;

  molFeComponents.getConfig &&
  molFeComponents.getConfig('mol-fe-videoplayer').then(function (config) {
    molFeComponents.setConfig &&
    molFeComponents.setConfig(
      'mol-fe-videoplayer',
      utilities.extend(
        config,
        {
          plugins: {
            flyout: {
              forceFlyout: {
                enabled: false
              }
            }
          }
        }
      )
    );
  });
});
