'use strict';

var FlyoutClose = videojs.Button.extend({});
FlyoutClose.prototype.createEl = function () {
  return videojs.createEl('span', {
    className: 'vjs-flyout-close vjs-flyout-button',
    innerHTML: 'Close'
  });
};

FlyoutClose.prototype.onClick = function () {
  this.player().trigger('flyout-close');
};

module.exports = FlyoutClose;
