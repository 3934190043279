'use strict';

var StreamingLabel = videojs.Component.extend();

StreamingLabel.prototype.createEl = function () {
  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'streaming-label vjs-control',
    innerHTML: 'LIVE'
  });
};

module.exports = StreamingLabel;
