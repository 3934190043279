'use strict';

var mol = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/mol');
var systemInfo = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/systemInfo');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var Promise = require('es6-promise').Promise;

var getPlayerConfig = function () {
  var emptyConfig = { plugins: {} };
  var getConfig = (window.DM && window.DM.molFeComponents && window.DM.molFeComponents.getConfig) ||
                  function () { return Promise.resolve(emptyConfig); };

  var timeoutPromise = new Promise(function (resolve) {
    setTimeout(function () {
      resolve(emptyConfig);
    }, 500);
  });

  return Promise.race([getConfig('mol-fe-videoplayer'), timeoutPromise]);
};

//The players' default options
var DEFAULT_OPTIONS = {
  title: '',
  descr: '',
  poster: '',
  preload: 'none',
  linkBaseURL: window.location.href,
  playerId: 0,
  videoId:0,
  plugins: {
    history: {},
    'player-state': {},
    'post-content': {},
    'player-events': {},
    'multiple-clicks-fix': {},
    reset: {},
    'social-share': {},
    title: {},
    mobile: {},
    streaming: {},
    'video-starts': {}
  }
};

var pageMetadata = DM && DM.pageEvents && DM.pageEvents.getPageMetadata ? DM.pageEvents.getPageMetadata() : {};

function isEmbedDmtvVideo(source) {
  return !!pageMetadata.embedVideo && typeof source === 'string' && source.toUpperCase() === 'DAILYMAILTV';
}

function PlayersSetup(opts) {
  if (!(this instanceof PlayersSetup)) {
    return new PlayersSetup(opts);
  }

  this.options = opts || DEFAULT_OPTIONS;
  this.options.flash = { swf: mol.getVideoJsSWFPath() };
}

PlayersSetup.prototype.run = function run(el) {
  var opts = this.options;
  var mediaEls = PlayersSetup.getMediaEls(el);
  var instantiableMediaEls = PlayersSetup.filterByInstantiability(mediaEls);

  return getPlayerConfig()
    .then(function (campaingManagerConfig) {
      utilities.forEach(instantiableMediaEls, function (mediaEl) {
        try {
          var options = PlayersSetup.mergeConfigs(opts,  PlayersSetup.getMediaOpts(mediaEl), campaingManagerConfig);

          var player = videojs(mediaEl, options);

          if (isEmbedDmtvVideo(options.source)) {
            player.el().classList.add('mol-embed-dmtv-video');
          }
        }
        catch (e) {
          console.error(e.message);
        }
      });
    });
};

PlayersSetup.mergeConfigs = function mergeConfigs(defaults, dataOptsConfig, campaingManagerConfig) {
  var options = utilities.extend({}, defaults, dataOptsConfig);

  if (options.plugins) {
    // if iOS 7 or lower we don't have related videos
    if (systemInfo.os === 'iOS' && systemInfo.osMajorVersion <= 7) {
      delete options.plugins['related-videos'];
    }

    Object.keys(options.plugins)
      .forEach(function (plugin) {
        // The strict equal is require as the configuration from clj_fe does not contain the enabled property
        if (campaingManagerConfig.plugins && campaingManagerConfig.plugins[plugin] && campaingManagerConfig.plugins[plugin].enabled === false) {
          delete options.plugins[plugin];
        }
      });

    if (options.plugins.flyout && options.plugins.flyout.forceFlyout) {
      options.plugins.flyout.forceFlyout.active = !options.isMobile;
      options.plugins.flyout.forceFlyout.enabled = !options.isMobile;
    }
  }

  return options;
};

PlayersSetup.initializeVideo = function initializeVideo(videoEl, options) {
  sanityCheck(videoEl, options);
  return videojs(videoEl, options || {});

  function sanityCheck(videoEl) {
    if (!videoEl) {
      throw new Error('Initialize Video Error: Video element \'' + videoEl + '\'');
    }

    if (options && !utilities.isObject(options)) {
      throw new Error('Initialize Video Error: Invalid options map');
    }
  }
};

PlayersSetup.getMediaEls = function getMediaEls(el) {
  // var vids = Array.prototype.slice.call(document.getElementsByTagName('video'));
  // var audios = Array.prototype.slice.call(document.getElementsByTagName('audio'));
  // var mediaEls = vids.concat(audios);

  // Because IE8 doesn't support calling slice on a node list, we need to loop through each list of elements
  // to build up a new, combined list of elements.
  el = el || document;

  if (PlayersSetup.isVideoElement(el)) {
    return [el];
  }

  var vids = el.getElementsByTagName('video');
  var mediaEls = [];
  var i, len;
  if (vids && vids.length > 0) {
    for (i = 0, len = vids.length; i < len; i++) {
      mediaEls.push(vids[i]);
    }
  }

  return mediaEls;
};

PlayersSetup.filterByInstantiability = function filterByInstantiability(videoEls) {
  return videoEls.filter(PlayersSetup.canBeInstantiated);
};

PlayersSetup.isVideoElement = function isVideoElement(el) {
  return !!el && !!el.tagName && el.tagName.toUpperCase() === 'VIDEO';
};

PlayersSetup.canBeInstantiated = function canBeInstantiated(videoEl) {
  return PlayersSetup.isVideoElement(videoEl) && !!videoEl.getAttribute('data-opts');
};

PlayersSetup.getMediaOpts = function getMediaOpts(mediaEl) {
  try {
    var strOpts = mediaEl.getAttribute('data-opts');
    return JSON.parse(strOpts);
  } catch (e) {
    throw new Error('Video Player Setup Error: There was an error parsing media options from video element');
  }
};

module.exports = PlayersSetup;
