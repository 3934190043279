'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _require = require('../utils'),
    nodeListToArray = _require.nodeListToArray;

var _require2 = require('../config'),
    playbackFw = _require2.playbackFw;

var VideoPreviewImgPlayBase = function () {
  function VideoPreviewImgPlayBase(videoPreviewImg) {
    _classCallCheck(this, VideoPreviewImgPlayBase);

    this.videoPreviewImg = videoPreviewImg;
    this.nextFrameDelay = parseInt(1000 / (this.videoPreviewImg.fps * playbackFw), 10);
    this.frameHolderClassName = 'frame-holder';
    this.isPlaying = false;
    this.curFrame = 0;
    this.initDone = false;
    this._frameHolders = [];
    this._frameHoldersCount = 1;
    this.animationStart = null;
    this.animationStartFrame = null;
  }

  _createClass(VideoPreviewImgPlayBase, [{
    key: '_createFrameHolder',
    value: function _createFrameHolder() {
      var el = document.createElement('div');
      el.className = this.frameHolderClassName + ' frame-holder-current';
      el.style['background-image'] = 'url("' + this.videoPreviewImg.filmSrc + '")';
      el.style['background-size'] = this.framesPerRow * 100 + '% ' + this.framesRows * 100 + '%';
      return el;
    }
  }, {
    key: '_createFrameHolders',
    value: function _createFrameHolders() {
      var _this = this;

      this._calcFrameGridParams();
      if (!this.frameHoldersElement) {
        this.frameHoldersElement = document.createElement('div');
        this.frameHoldersElement.className = 'frame-holders';
        this.videoPreviewImg.element.appendChild(this.frameHoldersElement);
      } else {
        nodeListToArray(this.frameHoldersElement.children).forEach(function (el) {
          _this.frameHoldersElement.removeChild(el);
        });
      }

      this._frameHolders = [];
      for (var i = 0; i < this._frameHoldersCount || 0; i++) {
        var frameHolder = this._createFrameHolder();
        this.frameHoldersElement.appendChild(frameHolder);
        this._frameHolders.push(frameHolder);
      }
    }
  }, {
    key: '_calcFrameGridParams',
    value: function _calcFrameGridParams() {
      var filmWidth = this.videoPreviewImg.filmElement.naturalWidth;
      this.framesPerRow = filmWidth / this.videoPreviewImg.frameWidth >> 0;
      this.framesRows = this.videoPreviewImg.filmElement.naturalHeight / this.videoPreviewImg.frameHeight >> 0;
    }
  }, {
    key: 'getCurFrameBackgroundPosition',
    value: function getCurFrameBackgroundPosition() {
      var column = this.curFrame % this.framesPerRow;
      var row = this.curFrame / this.framesPerRow >> 0;
      return '-' + column * 100 + '% -' + row * 100 + '%';
    }
  }, {
    key: 'nextFrame',
    value: function nextFrame() {
      var frameNum = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.curFrame + 1;

      this.curFrame = frameNum % this.videoPreviewImg.frameCount;
    }
  }, {
    key: 'setFrameHolderNext',
    value: function setFrameHolderNext(frameHolder) {
      frameHolder.className = this.frameHolderClassName + ' frame-holder-next';
      frameHolder.style['background-position'] = this.getCurFrameBackgroundPosition();
    }
  }, {
    key: 'init',
    value: function init() {
      if (this.initDone) {
        return;
      }

      this.initDone = true;
      this._createFrameHolders();
    }
  }, {
    key: 'play',
    value: function play() {
      if (this.isPlaying || !this.initDone) return;
      this.isPlaying = true;
      this.nextFrame();
      this.continueAnimation();
    }
  }, {
    key: 'continueAnimation',
    value: function continueAnimation() {
      var _this2 = this;

      this.animationRequestId = window.requestAnimationFrame(function (timestamp) {
        var frameCount = _this2.videoPreviewImg.frameCount;

        if (_this2.animationStart === null) {
          _this2.animationStart = timestamp;
        }

        if (_this2.animationStartFrame === null) {
          _this2.animationStartFrame = (_this2.curFrame - (_this2.curFrame - 1) % frameCount) % frameCount;
        }

        var nextFrame = (timestamp - _this2.animationStart) / _this2.nextFrameDelay + _this2.animationStartFrame >> 0;
        if (nextFrame >= frameCount) {
          _this2.animationStart = timestamp;
          _this2.animationStartFrame = nextFrame = 0;
        }

        if (_this2.animationStart === timestamp || nextFrame - _this2.curFrame > 0) {
          _this2.nextFrame(nextFrame);
        }

        _this2.isPlaying && _this2.continueAnimation();
      });
    }
  }, {
    key: 'stopAnimation',
    value: function stopAnimation() {
      window.cancelAnimationFrame(this.animationRequestId);
      this.animationStart = null;
      this.animationStartFrame = this.curFrame;
    }
  }, {
    key: 'pause',
    value: function pause() {
      this.isPlaying = false;
      this.stopAnimation();
    }
  }]);

  return VideoPreviewImgPlayBase;
}();

module.exports = VideoPreviewImgPlayBase;
