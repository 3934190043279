'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _browser = require('../util/browser');

var _browser2 = _interopRequireDefault(_browser);

var _loadScript = require('../util/loadScript');

var _loadScript2 = _interopRequireDefault(_loadScript);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IMA_SDK_URL = window.IMA_SDK_URL || '//imasdk.googleapis.com/js/sdkloader/ima3.js';
var IMA_LOAD_PROMISE = void 0;

var Ima = function () {
  function Ima() {
    _classCallCheck(this, Ima);
  }

  _createClass(Ima, null, [{
    key: 'isSupported',
    value: function isSupported() {
      return !_browser2.default.isOldIE();
    }
  }, {
    key: 'load',
    value: function load() {
      if (!IMA_LOAD_PROMISE) {
        if (window.google && window.google.ima) {
          IMA_LOAD_PROMISE = Promise.resolve(window.google.ima);
        } else {
          IMA_LOAD_PROMISE = (0, _loadScript2.default)(IMA_SDK_URL).catch(function () {
            return Promise.reject(new Error('Ima SDK load Error: Ad Blocker is probably on'));
          });
        }
      }

      return IMA_LOAD_PROMISE;
    }
  }]);

  return Ima;
}();

exports.default = Ima;
