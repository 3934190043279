'use strict';

var dataSource = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dataSource');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');

var NextVideoLink = require('./next-video-link');
/*
 {
 videos: []
 or
 videos: 'http://get.from/here.json'
 }
 */

videojs.plugin('playlist', function molPlaylistSetup(opts) {

  var player = this;
  var options = utilities.extend({}, opts);
  var videoPlaylist = dataSource(options.videos);
  var nextVideoLink;
  var cycle = opts.cycle;
  this.playlistCounter = 0;

  player.previous = function () {
    getPrevious(playVideo);
  };

  player.next = function () {
    getNext(playVideo);
  };

  player.gotoVideo = function (n) {
    playVideo(n);
  };

  // when a video finish I start with another
  player.on('playerstate.enter.ended', function () {
    if (nextVideoLink) {
      nextVideoLink.hide();
    }

    setTimeout(player.next, 10); // this is because leaveEnded was fired when enterEnded callbacks are still executing
  });

  // when someone trigger on next
  player.on('mol.playlist.next', player.next);

  // I show the video for the next
  player.on('playerstate.progress.enter.50', function () {
    if (nextVideoLink) {
      nextVideoLink.show();
    }
  });

  // I create a link for the next video
  player.on('playerstate.enter.started', function () {
    // removing the old component
    if (nextVideoLink) {
      player.removeChild(nextVideoLink);
      nextVideoLink.dispose();
      nextVideoLink = undefined;
    }

    getPlaylist(function (videos) {
      getNext(function (c) {
        var v = videos[c];
        if (v) {
          // creating the new component
          nextVideoLink = player.addChild(new NextVideoLink(player, { video: v }));
          nextVideoLink.hide();
        }
      });
    });
  });

  // ***************
  // inner functions
  // ***************
  function getPlaylist(cb) {
    videoPlaylist.get(function (err, videos) {
      if (err) {
        player.trigger({ type: 'warning', message:  'plugin.playlist: ' + err.message });
      }

      cb(videos || []);
    });
  }

  function getNext(cb) {
    var c = player.playlistCounter;

    getPlaylist(function (videos) {
      if (c >= (videos.length - 1)) {
        if (cycle) {
          c = 0;
        } else {
          return;
        }
      } else {
        c++;
      }

      cb(c);
    });
  }

  function getPrevious(cb) {
    var c = player.playlistCounter;
    getPlaylist(function (videos) {
      if (c <= 0) {
        if (cycle) {
          c = videos.length - 1;
        } else {
          return;
        }
      } else {
        c--;
      }

      cb(c);
    });
  }

  function playVideo(c) {
    getPlaylist(function (videos) {
      var v = videos[c];
      if (!v) return;
      player.playlistCounter = c;

      player.trigger({
        type:'playlist-play',
        index: c
      });

      player.trigger({
        type:'request-reset',
        video: {
          src: v.src,
          poster: v.poster,
          options: v
        },
        autoplay: true
      });
    });
  }
});
