'use strict';

/* eslint-disable no-console */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.log = log;
function log() {
  if (console && console.log) {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    console.log.apply(console, args);
  }
}
