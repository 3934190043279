'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var utilities = utils.utilityFunctions;
var dom = utils.dom;
var dataSource = utils.dataSource;
var systemInfo = utils.systemInfo;

videojs.plugin('sources', function sourcesPlugin(opts) {
  sanityCheck(opts);
  var player = this;
  var sources = dataSource(opts.url);
  opts.url = null; //We reset the url to prevent side effects when we reset the plugin

  player.ready(updateRenditions);
  player.on('reset', resetRenditions);
  player.changeRendition = changeRendition;

  /*** local functions ***/
  function updateRenditions() {
    sources.get(function (error, response) {
      //TODO: This hack should be part of the mol-fe-videoplayer and not part of mol-fe-videoplayer-ui
      var currentState = player._states && player._states.state && player._states.state.get();

      if (currentState === 'adsWaiting' || currentState === 'adPlaying') {
        utilities.once(player, ['vast.adEnd', 'ima.adEnd', 'vast.adsCancel', 'ima.adsCancel', 'request-reset'], function (evt) {
          if (evt.type === 'request-reset') {
            return;//Do not update the renditions on player reset
          }

          updatePlayerRenditions(error, response);
        });

      } else {
        updatePlayerRenditions(error, response);
      }
    });

    /*** Local functions ***/
    function updatePlayerRenditions(error, response) {
      if (error) {
        player.trigger({
          type: 'sources.error',
          error: error
        });
        player.renditions = [];
      } else {
        player.renditions = processRenditions((response && response.renditions) || []);
        if (player.renditions.length > 0) {
          player.changeRendition(player.renditions);
        }
      }

      player.trigger({
        type: 'mol.sources.updated',
        renditions: player.renditions
      });
    }
  }

  function resetRenditions() {
    var opts = player.options().plugins;
    sources = opts && opts.sources ? dataSource(opts.sources.url) : dataSource(null);
    updateRenditions();
  }

  function sanityCheck(opts) {
    if (!utilities.isString(opts.url)) {
      throw new Error('mol-fe-videoplayer Error: on sources plugin, missing url option');
    }
  }

  function processRenditions(renditions) {
    renditions = mapRenditions(renditions);
    return sortRenditions(renditions);
  }

  function mapRenditions(renditions) {
    return renditions.map(function (rendition) {
      return {
        id: rendition.id,
        src: rendition.url,
        type: rendition.type || guessType(rendition.url),
        width: rendition.frameWidth,
        height: rendition.frameHeight,
        videoContainer: rendition.videoContainer,
        bitrate: rendition.encodingRate,
        videoCodec: rendition.videoCodec,
        duration: utilities.isNumber(rendition.duration) ? rendition.duration : 0
      };
    });
  }

  function guessType(src) {
    var mimeMap = {
      ogv: 'video/ogg',
      webm: 'video/webm',
      flv: 'video/x-flv',
      mp4: 'video/mp4',
      m4v: 'video/mp4',
      m3u8: 'application/x-mpegURL',
      ts: 'video/MP2T',
      '3gp': 'video/3gpp',
      mov: 'video/quicktime',
      avi: 'video/x-msvideo',
      wmv: 'video/x-ms-wmv'
    };
    var match = src.match(/\.([^.\/\?]+)(\?[^\/]+)?$/i);
    var ext = match && match[1];
    return mimeMap[ext] || 'video/' + ext;
  }

  function sortRenditions(renditions) {
    var playerWidth = dom.getDimension(player.el()).width;

    if (systemInfo.os === 'iOS') {
      return sortRenditionsForIOS(renditions, playerWidth);
    }

    return sortByBestWidth(renditions.filter(isNotHLS), playerWidth);
  }

  function sortRenditionsForIOS(renditions, playerWidth) {
    var hlsRenditions = sortByBestWidth(renditions.filter(isHLS), playerWidth);
    var otherRenditions = sortByBestWidth(renditions.filter(isNotHLS), playerWidth);

    return hlsRenditions.concat(otherRenditions);
  }

  function isHLS(rendition) {
    return rendition.videoContainer === 'M2TS';
  }

  function isNotHLS(rendition) {
    return rendition.videoContainer !== 'M2TS';
  }

  function changeRendition(rendition, customState, callback) {
    var state;
    state = utilities.extend({}, getPlayerState(player), customState || {});
    callback = callback || utilities.noop;

    player.trigger('mol.sources.renditionChanging');
    if (state.playing) {
      player.pause();
    }

    player.src(rendition);
    updateDuration(player, rendition);
    restorePlayerState(player, state, function () {
      player.trigger('mol.sources.renditionChanged');
      callback();
    });
  }

  function sortByBestWidth(renditions, playerWidth) {
    return renditions.sort(function compareTo(a, b) {
      var deltaA = Math.abs(playerWidth - a.width);
      var deltaB = Math.abs(playerWidth - b.width);
      return deltaA - deltaB;
    });
  }

  function getPlayerState(player) {
    return {
      currentTime: player.currentTime(),
      playing: !player.paused()
    };
  }

  function updateDuration(player, rendition) {
    var sampleRendition = utilities.isArray(rendition) ? rendition[0] : rendition;
    var newDuration = sampleRendition.duration / 1000;

    if (newDuration > 0) {
      player.duration(newDuration);
      player.trigger('timeupdate');
    }

  }

  function restorePlayerState(player, state, cb) {
    if (state.playing) {
      player.load();
      player.play();
    }

    player.one('loadeddata', function () {
      player.currentTime(state.currentTime);
      cb();
    });
  }
});
