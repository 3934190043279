'use strict';

function isEditorialContentPlayer(player) {
  var plugins = player.options().plugins;

  return !!plugins && !!plugins['editorial-content'];
}

function isAutoplaying(player) {
  if (player.playlistClickToPlay) {
    return false;
  }

  return player.forceFlyOutInProgress ||
         player.autoplayInline ||
         ('playlistCounter' in player ? player.playlistCounter > 0 : false) ||
         ('autoplayIndex' in player ? player.autoplayIndex > 0 : false) ||
         isEditorialContentPlayer(player);
}

module.exports = isAutoplaying;
