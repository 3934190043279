'use strict';

var FacebookButton = require('@mol-fe/mol-fe-videoplayer-ui/src/scripts/components/control-bar/social-controls/share/social-buttons').FacebookButton;
var TwitterButton = require('@mol-fe/mol-fe-videoplayer-ui/src/scripts/components/control-bar/social-controls/share/social-buttons').TwitterButton;
var PinterestButton = require('@mol-fe/mol-fe-videoplayer-ui/src/scripts/components/control-bar/social-controls/share/social-buttons').PinterestButton;
var MailButton = require('@mol-fe/mol-fe-videoplayer-ui/src/scripts/components/control-bar/social-controls/share/social-buttons').MailButton;
var ShareButton = require('@mol-fe/mol-fe-videoplayer-ui/src/scripts/components/control-bar/social-controls/share/share-button');

var SocialListComponent = videojs.Component.extend({
  init: function (player, options) {
    videojs.Component.call(this, player, options);

    this.addChild(new FacebookButton(player));
    this.addChild(new TwitterButton(player));
    this.addChild(new PinterestButton(player));
    this.addChild(new MailButton(player));
    this.addChild(new ShareButton(player));
  }
});

SocialListComponent.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'mol-related-videos-panel-social-list'
  });
};

var RelatedVideosPanelSocial = videojs.Component.extend({
  init: function (player, options) {
    videojs.Component.call(this, player, options);

    this.addChild(new SocialListComponent(player));
  }
});

RelatedVideosPanelSocial.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'mol-related-videos-panel mol-related-videos-panel-social',
    innerHTML: '<span class="panel-title">Share this</span>'
  });
};

module.exports = RelatedVideosPanelSocial;
