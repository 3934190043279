'use strict';

var FacebookButton = require('./share/social-buttons').FacebookButton;
var TwitterButton = require('./share/social-buttons').TwitterButton;
var MailButton = require('./share/social-buttons').MailButton;

var ShareButton = require('./share/share-button');
var LinkButton = require('./link/link-button');
var EmbedButton = require('./embed/embed-button');

/* Social Share Controls - Control Component that groups all the social buttons
 ================================================================================ */

var SocialControls = videojs.Component.extend({

  init: function (player) {
    videojs.Component.call(this, player);

    this.addChild(new EmbedButton(player));
    this.addChild(new LinkButton(player));
    this.addChild(new ShareButton(player));
    this.addChild(new MailButton(player));
    this.addChild(new TwitterButton(player));
    this.addChild(new FacebookButton(player));
  }
});

SocialControls.prototype.createEl = function () {
  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-social-controls vjs-control'
  });
};

module.exports = SocialControls;
