'use strict';

var RELATED_VIDEOS_HISTORY_KEY = 'related-videos-history';
var MAX_HISTORY = 100;

function get() {
  try {
    return JSON.parse(localStorage.getItem(RELATED_VIDEOS_HISTORY_KEY)) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

function getMap() {
  return get()
    .reduce(function (map, id) {
      map[id] = true;
      return map;
    }, {});
}

function set(history) {
  if (!history) {
    localStorage.removeItem(RELATED_VIDEOS_HISTORY_KEY);
    return;
  }

  try {
    localStorage.setItem(RELATED_VIDEOS_HISTORY_KEY, JSON.stringify(history));
  } catch (e) {
    console.error(e);
  }
}

function push(referenceId) {
  var history = get();
  var inHistory = history.some(function (id) {
    return id === referenceId;
  });

  if (referenceId && !inHistory) {
    history.unshift(referenceId);
    set(history.slice(0, MAX_HISTORY));
  }
}

module.exports = {
  get: get,
  getMap: getMap,
  set: set,
  push: push
};
