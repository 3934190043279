'use strict';

var NODE_TYPE_ELEMENT = 1;
var SNAKE_CASE_REGEXP = /[A-Z]/g;
var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i;
/*jslint maxlen: 500 */
var ISO8086_REGEXP = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

function noop() { }

function isNull(o) {
  return o === null;
}

function isDefined(o) {
  return o !== undefined;
}

function isUndefined(o) {
  return o === undefined;
}

function isObject(obj) {
  return typeof obj === 'object';
}

function isFunction(str) {
  return typeof str === 'function';
}

function isNumber(num) {
  return typeof num === 'number';
}

function isWindow(obj) {
  return utilities.isObject(obj) && obj.window === obj;
}

function isArray(array) {
  return Object.prototype.toString.call(array) === '[object Array]';
}

function isArrayLike(obj) {
  if (obj === null || utilities.isWindow(obj) || utilities.isFunction(obj) || utilities.isUndefined(obj)) {
    return false;
  }

  var length = obj.length;

  if (obj.nodeType === NODE_TYPE_ELEMENT && length) {
    return true;
  }

  return utilities.isString(obj) || utilities.isArray(obj) || length === 0 ||
    typeof length === 'number' && length > 0 && (length - 1) in obj;
}

function isNodeList(nodes) {
  var stringRepr = Object.prototype.toString.call(nodes);

  return typeof nodes === 'object' &&
      /^\[object (HTMLCollection|NodeList|Object)\]$/.test(stringRepr) &&
      (typeof nodes.length === 'number') &&
      (nodes.length === 0 || (typeof nodes[0] === 'object' && nodes[0].nodeType > 0));
}

function isString(str) {
  return typeof str === 'string';
}

function isEmptyString(str) {
  return utilities.isString(str) && str.length === 0;
}

function isNotEmptyString(str) {
  return utilities.isString(str) && str.length !== 0;
}

function arrayLikeObjToArray(args) {
  return Array.prototype.slice.call(args);
}

function forEach(obj, iterator, scope) {
  var key, length;
  if (obj) {
    if (isFunction(obj)) {
      for (key in obj) {
        // Need to check if hasOwnProperty exists,
        // as on IE8 the result of querySelectorAll is an object without a hasOwnProperty function
        if (key !== 'prototype' && key !== 'length' && key !== 'name' && (!obj.hasOwnProperty || obj.hasOwnProperty(key))) {
          iterator.call(scope, obj[key], key, obj);
        }
      }
    } else if (isArray(obj)) {
      var isPrimitive = typeof obj !== 'object';
      for (key = 0, length = obj.length; key < length; key++) {
        if (isPrimitive || key in obj) {
          iterator.call(scope, obj[key], key, obj);
        }
      }
    } else if (obj.forEach && obj.forEach !== forEach) {
      obj.forEach(iterator, scope, obj);
    } else {
      for (key in obj) {
        if (obj.hasOwnProperty(key)) {
          iterator.call(scope, obj[key], key, obj);
        }
      }
    }
  }

  return obj;
}

function snakeCase(name, separator) {
  if (!name || !isString(name)) {
    return;
  }

  separator = separator || '_';
  return name.replace(SNAKE_CASE_REGEXP, function (letter, pos) {
    return (pos ? separator : '') + letter.toLowerCase();
  });
}

function isValidEmail(email) {
  if (!utilities.isString(email)) {
    return false;
  }

  return EMAIL_REGEXP.test(email.trim());
}

function extend(obj) {
  var arg, i, k;
  for (i = 1; i < arguments.length; i++) {
    arg = arguments[i];
    for (k in arg) {
      if (arg.hasOwnProperty(k)) {
        if (isObject(obj[k]) && !isNull(obj[k]) && isObject(arg[k])) {
          obj[k] = extend({}, obj[k], arg[k]);
        }else {
          obj[k] = arg[k];
        }
      }
    }
  }

  return obj;
}

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function decapitalize(s) {
  return s.charAt(0).toLowerCase() + s.slice(1);
}

/**
 * This method works the same way array.prototype.map works but if the transformer returns undefine, then
 * it won't be added to the transformed Array.
 */
function transformArray(array, transformer) {
  var transformedArray = [];

  array.forEach(function (item, index) {
    var transformedItem = transformer(item, index);
    if (utilities.isDefined(transformedItem)) {
      transformedArray.push(transformedItem);
    }
  });

  return transformedArray;
}

function toFixedDigits(num, digits) {
  var formattedNum = num + '';
  digits = utilities.isNumber(digits) ? digits : 0;
  num = utilities.isNumber(num) ? num : parseInt(num, 10);
  if (utilities.isNumber(num) && !isNaN(num)) {
    formattedNum = num + '';
    while (formattedNum.length < digits) {
      formattedNum = '0' + formattedNum;
    }

    return formattedNum;
  }

  return NaN + '';
}

function throttle(callback, delay) {
  var _this, args, timeout, throttling, more, result;

  var whenDone = utilities.debounce(function () {
    more = throttling = false;
  }, delay);

  return function () {
    _this = this; args = arguments;

    var later = function () {
      timeout = null;
      if (more) {
        result = callback.apply(_this, args);
      }

      whenDone();
    };

    if (!timeout) {
      timeout = setTimeout(later, delay);
    }

    if (throttling) {
      more = true;
    } else {
      throttling = true;
      result = callback.apply(_this, args);
    }

    whenDone();
    return result;
  };
}

/**
 *  Returns a function, that, as long as it continues to be invoked, will not be triggered.
 *  The function will be called after it stops being called for N milliseconds.
 *  If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 */
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var _this = this;
    var args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(_this, args);
      }
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(_this, args);
    }
  };
}

/**
 * Helper function to listen to many events until one of them gets fired, then we
 * execute the handler and unsubscribe all the event listeners;
 *
 * @param player specific player from where to listen for the events
 * @param events array of events
 * @param handler function to execute once one of the events fires
 */
function once(player, events, handler) {
  function listener() {
    handler.apply(null, arguments);

    events.forEach(function (event) {
      player.off(event, listener);
    });
  }

  events.forEach(function (event) {
    player.on(event, listener);
  });
}

// a function designed to blow up the stack in a naive way
// but it is ok for videoJs children components
function treeSearch(root, getChildren, found) {
  var children = getChildren(root);
  for (var i = 0; i < children.length; i++) {
    if (found(children[i])) {
      return children[i];
    } else {
      var el = treeSearch(children[i], getChildren, found);
      if (el) {
        return el;
      }
    }
  }
}

function echoFn(val) {
  return function () {
    return val;
  };
}

//Note: Supported formats come from http://www.w3.org/TR/NOTE-datetime
// and the iso8601 regex comes from http://www.pelagodesign.com/blog/2009/05/20/iso-8601-date-validation-that-doesnt-suck/
function isISO8601(value) {
  if (utilities.isNumber(value)) {
    value = value + '';  //we make sure that we are working with strings
  }

  if (!utilities.isString(value)) {
    return false;
  }

  return ISO8086_REGEXP.test(value.trim());
}

function rotateRight(arr, n) {
  var out = [],
      len = arr.length,
      i = n % len,
      j;

  for (j = 0; j < len; j++) {
    out.push(arr[i]);
    if (i >= len - 1) {
      i = 0;
    } else {
      i++;
    }
  }

  return out;
}

/**
 * Checks if the Browser is IE9 and below
 * @returns {boolean}
 */
function isOldIE() {
  var version = utilities.getInternetExplorerVersion(navigator);
  if (version === -1) {
    return false;
  }

  return version < 10;
}

/**
 * Returns the version of Internet Explorer or a -1 (indicating the use of another browser).
 * Source: https://msdn.microsoft.com/en-us/library/ms537509(v=vs.85).aspx
 * @returns {number} the version of Internet Explorer or a -1 (indicating the use of another browser).
 */
function getInternetExplorerVersion(navigator) {
  var rv = -1;

  if (navigator.appName == 'Microsoft Internet Explorer') {
    var ua = navigator.userAgent;
    var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
    var res = re.exec(ua);
    if (res !== null) {
      rv = parseFloat(res[1]);
    }
  }

  return rv;
}

/*** Mobile Utility functions ***/
function isIDevice() {
  return /iP(hone|ad)/.test(utilities._UA);
}

function isMobile() {
  return /iP(hone|ad|od)|Android|Windows Phone/.test(utilities._UA);
}

function isIPhone() {
  return /iP(hone|od)/.test(utilities._UA);
}

function isAndroid() {
  return /Android/.test(utilities._UA);
}

function isMicrosoftBrowser() {
  var ua = window.navigator.userAgent;
  return ua.indexOf('Edge') > 0 || ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0;
}

var utilities = {
  _UA: navigator.userAgent,
  arrayLikeObjToArray: arrayLikeObjToArray,
  capitalize: capitalize,
  debounce: debounce,
  decapitalize: decapitalize,
  echoFn: echoFn,
  extend: extend,
  forEach: forEach,
  getInternetExplorerVersion: getInternetExplorerVersion,
  isAndroid: isAndroid,
  isArray: isArray,
  isArrayLike: isArrayLike,
  isDefined: isDefined,
  isEmptyString: isEmptyString,
  isFunction: isFunction,
  isIDevice: isIDevice,
  isIPhone: isIPhone,
  isISO8601: isISO8601,
  isMobile: isMobile,
  isMicrosoftBrowser: isMicrosoftBrowser,
  isNotEmptyString: isNotEmptyString,
  isNull: isNull,
  isNodeList: isNodeList,
  isNumber: isNumber,
  isObject: isObject,
  isOldIE: isOldIE,
  isString: isString,
  isUndefined: isUndefined,
  isValidEmail: isValidEmail,
  isWindow: isWindow,
  noop: noop,
  once: once,
  rotateRight: rotateRight,
  snakeCase: snakeCase,
  throttle: throttle,
  toFixedDigits: toFixedDigits,
  transformArray: transformArray,
  treeSearch: treeSearch
};

module.exports = utilities;
