'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _type = require('./type');

var _type2 = _interopRequireDefault(_type);

var _browser = require('./browser');

var _browser2 = _interopRequireDefault(_browser);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isNotEmptyString(str) {
  return _type2.default.isString(str) && str.length !== 0;
}

var Dom = {};

Dom.isVisible = function isVisible(el) {
  var style = window.getComputedStyle(el);
  return style.visibility !== 'hidden';
};

Dom.isHidden = function isHidden(el) {
  var style = window.getComputedStyle(el);
  return style.display === 'none';
};

Dom.isShown = function isShown(el) {
  return !Dom.isHidden(el);
};

Dom.hide = function hide(el) {
  el.__prev_style_display_ = el.style.display;
  el.style.display = 'none';
};

Dom.show = function show(el) {
  if (Dom.isHidden(el)) {
    el.style.display = el.__prev_style_display_;
  }

  el.__prev_style_display_ = undefined;
};

Dom.hasClass = function hasClass(el, cssClass) {
  var classes, i, len;

  if (isNotEmptyString(cssClass)) {
    if (el.classList) {
      return el.classList.contains(cssClass);
    }

    classes = _type2.default.isString(el.getAttribute('class')) ? el.getAttribute('class').split(/\s+/) : [];
    cssClass = cssClass || '';

    for (i = 0, len = classes.length; i < len; i += 1) {
      if (classes[i] === cssClass) {
        return true;
      }
    }
  }

  return false;
};

Dom.toggleClass = function (el, cssClass, force) {
  var method = Dom.hasClass(el, cssClass) ? force !== true && 'removeClass' : force !== false && 'addClass';
  if (method) {
    Dom[method](el, cssClass);
  }
};

Dom.toggleEventListener = function (el, type, handler, force) {
  Dom[force ? 'addEventListener' : 'removeEventListener'](el, type, handler);
};

Dom.addClass = function (el, cssClass) {
  var classes;

  if (isNotEmptyString(cssClass)) {
    if (el.classList) {
      return el.classList.add(cssClass);
    }

    classes = _type2.default.isString(el.getAttribute('class')) ? el.getAttribute('class').split(/\s+/) : [];
    if (_type2.default.isString(cssClass) && isNotEmptyString(cssClass.replace(/\s+/, ''))) {
      classes.push(cssClass);
      el.setAttribute('class', classes.join(' '));
    }
  }
};

Dom.removeClass = function (el, cssClass) {
  var classes;

  if (isNotEmptyString(cssClass)) {
    if (el.classList) {
      return el.classList.remove(cssClass);
    }

    classes = _type2.default.isString(el.getAttribute('class')) ? el.getAttribute('class').split(/\s+/) : [];
    var newClasses = [];
    var i, len;
    if (_type2.default.isString(cssClass) && isNotEmptyString(cssClass.replace(/\s+/, ''))) {

      for (i = 0, len = classes.length; i < len; i += 1) {
        if (cssClass !== classes[i]) {
          newClasses.push(classes[i]);
        }
      }

      el.setAttribute('class', newClasses.join(' '));
    }
  }
};

Dom.addEventListener = function addEventListener(el, type, handler) {
  if (_type2.default.isArray(el)) {
    el.forEach(function (e) {
      Dom.addEventListener(e, type, handler);
    });

    return;
  }

  if (_type2.default.isArray(type)) {
    type.forEach(function (t) {
      Dom.addEventListener(el, t, handler);
    });

    return;
  }

  if (el.addEventListener) {
    el.addEventListener(type, handler, false);
  } else if (el.attachEvent) {
    // WARNING!!! this is a very naive implementation !
    // the event object that should be passed to the handler
    // would not be there for IE8
    // we should use "window.event" and then "event.srcElement"
    // instead of "event.target"
    el.attachEvent('on' + type, handler);
  }
};

Dom.removeEventListener = function removeEventListener(el, type, handler) {
  if (_type2.default.isArray(el)) {
    el.forEach(function (e) {
      Dom.removeEventListener(e, type, handler);
    });

    return;
  }

  if (_type2.default.isArray(type)) {
    type.forEach(function (t) {
      Dom.removeEventListener(el, t, handler);
    });

    return;
  }

  if (el.removeEventListener) {
    el.removeEventListener(type, handler, false);
  } else if (el.detachEvent) {
    el.detachEvent('on' + type, handler);
  } else {
    el['on' + type] = null;
  }
};

Dom.dispatchEvent = function dispatchEvent(el, event) {
  if (el.dispatchEvent) {
    el.dispatchEvent(event);
  } else {
    el.fireEvent('on' + event.eventType, event);
  }
};

Dom.trigger = function (el, evtName, opts) {
  function createEvt(evtName, opts) {
    var event = document.createEvent('Event');
    event.initEvent(evtName, true, true);
    if (opts) {
      Object.keys(opts).forEach(function (key) {
        return event[key] = opts[key];
      });
    }

    return event;
  }

  Dom.dispatchEvent(el, createEvt(evtName, opts));
};

Dom.isDescendant = function isDescendant(parent, child) {
  var node = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
};

Dom.getTextContent = function getTextContent(el) {
  return el.textContent || el.text;
};

Dom.prependChild = function prependChild(parent, child) {
  if (child.parentNode) {
    child.parentNode.removeChild(child);
  }

  return parent.insertBefore(child, parent.firstChild);
};

Dom.remove = function removeNode(node) {
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
};

Dom.isDomElement = function isDomElement(o) {
  return o instanceof Element;
};

Dom.click = function (el, handler) {
  Dom.addEventListener(el, 'click', handler);
};

Dom.once = function (el, type, handler) {
  function handlerWrap() {
    handler.apply(null, arguments);
    Dom.removeEventListener(el, type, handlerWrap);
  }

  Dom.addEventListener(el, type, handlerWrap);
};

//Note: there is no getBoundingClientRect on iPad so we need a fallback
Dom.getDimension = function getDimension(element) {
  var rect;

  //On IE9 and below getBoundingClientRect does not work consistently
  if (!_browser2.default.isOldIE() && element.getBoundingClientRect) {
    rect = element.getBoundingClientRect();
    return {
      width: rect.width,
      height: rect.height
    };
  }

  return {
    width: element.offsetWidth,
    height: element.offsetHeight
  };
};

Dom.getPositionOfElement = function getPositionOfElement(element) {

  //On IE9 and below getBoundingClientRect does not work consistently
  if (!_browser2.default.isOldIE() && element.getBoundingClientRect) {
    return element.getBoundingClientRect();
  }

  var top = 0,
      left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
    width: element.offsetWidth,
    height: element.offsetHeight
  };
};

Dom.appendChildren = function appendChildren(parent, children) {
  children.forEach(function (child) {
    parent.appendChild(child);
  });
};

Dom.createElementWithClasses = function createElementWithClasses(classes) {
  var controlDiv = document.createElement('div');
  classes.forEach(function (clss) {
    return Dom.addClass(controlDiv, clss);
  });
  return controlDiv;
};

exports.default = Dom;
