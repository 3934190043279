'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');

var adUtils = require('../../utils/ad-utils');

videojs.plugin('ima-setup', function molImaSetup(opts) {
  var player = this;
  var tech = player.el().querySelector('.vjs-tech');
  var options = utilities.extend({}, this.options_, opts);
  var isAutoplaying = function () {
    return adUtils.isAutoplaying(player);
  };

  options.scor = new Date().valueOf();
  player.adsEnabled = adUtils.isSupportedBrowser() && !!opts.adsEnabled;

  try {
    player['mol-fe-videojs-ima']({
      isAutoplaying: isAutoplaying,
      adsEnabled: player.adsEnabled,
      adCancelTimeout: opts.adsCancelTimeout,
      adTagUrl: getAdsUrl,
      vpaidEnabled: opts.vpaidEnabled,
      moat: {
        partnerCode: 'mailonlinejsvideo243552997523'
      }
    });
  } catch (e) {
    log('IMA ERROR: ' + e.message, e);
    player.trigger({
      type:'ima.adsError',
      error: e
    });
  }

  player.on('reset', resetPlugin);
  player.on('vast.reset', resetPlugin);
  player.on('playerstate.enter.ended', resetPlugin);

  player.on('vast.resize', function () {
    var resizeEvt = document.createEvent('Event');
    resizeEvt.initEvent('resize', true, true);
    dom.dispatchEvent(tech, resizeEvt);
  });

  //Note: for convenience we publish the same vast obj as the videojs-vast-vpaid plugin
  player.vast = {
    type: 'IMA',

    isEnabled: function () {
      return player.ima.isEnabled();
    },

    enable: function () {
      return player.ima.enable();
    },

    disable: function () {
      return player.ima.disable();
    }
  };

  return player.vast;

  /**** Local functions ***/

  function getAdsUrl(cb) {
    adUtils.buildDFPAdTag(player, options)
    .then(function (adTag) {
      cb(null, adTag);
    });
  }

  function resetPlugin() {
    var newOpts = player.options().plugins['ads-setup'];
    newOpts.scor = new Date().valueOf();
    options = utilities.extend(options, newOpts);
    player.adsEnabled = adUtils.isSupportedBrowser() && options.adsEnabled;

    player.ima.reset({
      adsEnabled: player.adsEnabled
    });
  }

  function log() {
    if (console && console.log) {
      console.log.apply(console, utilities.arrayLikeObjToArray(arguments));
    }
  }
});
