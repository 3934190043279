'use strict';

var FlyoutMinimize = require('./flyout-minimize');
var FlyoutExpand = require('./flyout-expand');
var FlyoutClose = require('./flyout-close');

var FlyoutControl = videojs.Component.extend({
  init: function (player, options) {
    videojs.Component.call(this, player);
    var flyoutMinimize = new FlyoutMinimize(player, options);
    var flyoutExpand = new FlyoutExpand(player, options);
    var flyoutClose = new FlyoutClose(player, options);
    this.addChild(flyoutMinimize);
    this.addChild(flyoutExpand);
    this.addChild(flyoutClose);
  }
});

FlyoutControl.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'vjs-flyout-control'
  });
};

module.exports = FlyoutControl;
