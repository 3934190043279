'use strict';

var systemInfo = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/systemInfo');
var viewport = require('../../utils/viewport');
var addContentPlayerToGlobal = require('../../utils/addContentPlayerToGlobal');

videojs.plugin('editorial-content', function () {
  var player = this;

  var startPlaying = function () {
    if (Math.abs(player.duration() - player.currentTime()) < 1) {
      player.trigger('autoplay-next-video-restart');
    } else {
      player.play();
    }
  };

  var stopPlaying = function () {
    if (Math.abs(player.duration() - player.currentTime()) < 1) {
      player.trigger('autoplay-next-video-cancel');
    }

    player.pause();
  };

  addContentPlayerToGlobal(player);
  player.muted(true);
  player.volume(0);

  player.el().classList.add('mol-editorial-content');

  if (systemInfo.mobile) {
    player.el().querySelector('.vjs-tech').setAttribute('playsinline', '');
  }

  player.on('playerstate.enter.standby', function () {
    var videoElement = player.el().getElementsByTagName('video')[0];

    // Targets webkit browsers that open video in full screen mode (ios6, ios7).
    // Exits the full screen mode.
    if (videoElement && videoElement.webkitExitFullScreen) {
      videoElement.webkitExitFullScreen();
    }
  });

  if (player.fetchRelatedVideos) {
    initEditorialContent();
  } else {
    player.on('related-videos-ready', initEditorialContent);
  }

  function initEditorialContent() {
    player.fetchRelatedVideos(function (error, relatedVideoList) {
      var video = relatedVideoList[0];

      player.trigger({
        type:'request-reset',
        video: {
          src: video.src,
          poster: video.poster,
          options: video
        },
        autoplay: false
      });

      viewport.check(player.el());
      player.el().addEventListener('viewport.enter', startPlaying);
      player.el().addEventListener('viewport.exit', stopPlaying);
    });
  }

  player.on('request-reset', function (evt) {
    var video = evt && evt.video && evt.video.options;

    if (video) {
      updateRelatedVideoList(video);
    }
  });

  function updateRelatedVideoList(nextVideo) {
    player.relatedVideos.get(function (error, relatedVideoList) {
      var idx = relatedVideoList.findIndex(function (video) {
        return video.referenceId === nextVideo.referenceId;
      });

      relatedVideoList.splice(idx, 1);
      relatedVideoList.push(nextVideo);

      player.relatedVideos.update(relatedVideoList);
    });
  }
});
