'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils').utilityFunctions;

/* Social Share Button - Base class for all social share buttons
 ================================================================================ */

var SocialButton = videojs.Button.extend();

SocialButton.prototype.createEl = function () {
  return videojs.Button.prototype.createEl.call(this, 'div', {
    className: 'vjs-social-share-control vjs-control vjs-social-share-control-' + utilities.snakeCase(this.buttonName, '-'),
    innerHTML: '<div class="vjs-social-icon vjs-' + utilities.snakeCase(this.buttonName, '-') + '-icon "></div>'
  });
};

SocialButton.prototype.onClick = utilities.debounce(function () {
  var player = this.player();

  if (this.socialMethod && player.socials && player.socials[this.socialMethod]) {
    player.socials[this.socialMethod].call(this);
  }  else {
    console.log('This social is not supported in this player');
  }
}, 500);

var socialButtonsMethodMap = {
  DiggButton: 'postToDiggit',
  FacebookButton: 'postToFB',
  MailButton: 'postToMail',
  GooglePlusButton: 'postToGPlus',
  LinkedinButton: 'postToLinkedIn',
  PinterestButton: 'postToPinterest',
  RedditButton: 'postToReddit',
  StumbleUponButton: 'postToStumbleUpon',
  TwitterButton: 'postToTWTTR'
};

var SocialButtons = {};

Object.keys(socialButtonsMethodMap).forEach(function (buttonName) {
  var socialMethod = socialButtonsMethodMap[buttonName];

  var button = SocialButton.extend();
  button.prototype.buttonName = buttonName;
  button.prototype.socialMethod = socialMethod;

  SocialButtons[buttonName] = button;
});

module.exports = SocialButtons;
