'use strict';

var viewport = require('../../utils/viewport');
var isAutoplaySupported = require('../../utils/isAutoplaySupported');
var TapToUnmuteControl = require('./tap-to-unmute-control');
var isOnWifiConnection = function () {
  var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
  var type = connection.type;

  return type === 'wifi';
};

videojs.plugin('autoplay-inline', function (options) {
  var player = this;
  var tapToUnmuteControl;

  if (!options.enabled) {
    return;
  }

  var startPlaying = function () {
    player.autoplayInline = true;

    if (player.duration() !== 0 && Math.abs(player.duration() - player.currentTime()) < 1) {
      player.trigger('autoplay-inline-restart');
    } else {
      tapToUnmuteControl = new TapToUnmuteControl(player);
      player.addChild(tapToUnmuteControl);
      player.muted(true);
      player.play();
    }

  };

  var stopPlaying = function () {
    if (player._states.state.get() === 'autoplay') {
      player.trigger('autoplay-inline-cancel');
    }

    player.pause();
    player.autoplayInline = false;
    player.removeChild(tapToUnmuteControl);
  };

  var playIfAllowed = function () {
    if (isOnWifiConnection()) {
      startPlaying();
    }
  };

  isAutoplaySupported(function (supported) {
    if (!supported) {
      return;
    }

    viewport.check(player.el());
    player.el().addEventListener('viewport.enter', playIfAllowed);
    player.el().addEventListener('viewport.exit', stopPlaying);
  });
});
