'use strict';

var maxTimeout = 1000;
var intervalDelay = 100;
var noChangeCountToEnd = 3;

/**
 * Modification of https://github.com/gajus/orientationchangeend
 *
 * @param cb
 */

function onOrientationChange(cb) {
  if (typeof cb !== 'function') {
    return;
  }

  var intervalId = void 0;
  var timeoutId = void 0;
  var lastInnerWidth = void 0;
  var lastInnerHeight = void 0;
  var noChangeCount = void 0;

  function done(event) {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
    if (event) {
      intervalId = null;
      timeoutId = null;
      cb(event);
    }
  }

  function eventHandler(event) {
    done();
    intervalId = setInterval(function () {
      if (window.innerWidth === lastInnerWidth && window.innerHeight === lastInnerHeight) {
        noChangeCount++;
        if (noChangeCount === noChangeCountToEnd) {
          done(event);
        }
      } else {
        lastInnerWidth = window.innerWidth;
        lastInnerHeight = window.innerHeight;
        noChangeCount = 0;
      }
    }, intervalDelay);

    timeoutId = setTimeout(function () {
      done(event);
    }, maxTimeout);
  }

  window.addEventListener('orientationchange', eventHandler);
  window.addEventListener('resize', eventHandler);

  return eventHandler;
}

module.exports = onOrientationChange;
