'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var keyboardAllowed = typeof Element !== 'undefined' && 'ALLOW_KEYBOARD_INPUT' in Element;
var fsApi = function () {
  var browserApi = void 0,
      fullscreenAPI = void 0,
      i = void 0;

  // Spec: https://dvcs.w3.org/hg/fullscreen/raw-file/tip/Overview.html
  var standardApi = ['requestFullscreen', 'exitFullscreen', 'fullscreenElement', 'fullscreenEnabled', 'fullscreenchange', 'fullscreenerror'];

  // map approach from Screenful.js - https://github.com/sindresorhus/screenfull.js
  var apiMap = [standardApi,

  // WebKit
  ['webkitRequestFullscreen', 'webkitExitFullscreen', 'webkitFullscreenElement', 'webkitFullscreenEnabled', 'webkitfullscreenchange', 'webkitfullscreenerror'],

  // Old WebKit (Safari 5.1)
  ['webkitRequestFullScreen', 'webkitCancelFullScreen', 'webkitCurrentFullScreenElement', 'webkitCancelFullScreen', 'webkitfullscreenchange', 'webkitfullscreenerror'],

  // Mozilla
  ['mozRequestFullScreen', 'mozCancelFullScreen', 'mozFullScreenElement', 'mozFullScreenEnabled', 'mozfullscreenchange', 'mozfullscreenerror'],

  // Microsoft
  ['msRequestFullscreen', 'msExitFullscreen', 'msFullscreenElement', 'msFullscreenEnabled', 'MSFullscreenChange', 'MSFullscreenError']];

  // determine the supported set of functions
  for (i = 0; i < apiMap.length; i++) {
    // check for exitFullscreen function
    if (apiMap[i][1] in document) {
      browserApi = apiMap[i];
      break;
    }
  }

  // map the browser API names to the spec API names
  // or leave return undefined
  if (browserApi) {
    fullscreenAPI = {};

    for (i = 0; i < browserApi.length; i++) {
      fullscreenAPI[standardApi[i]] = browserApi[i];
    }
  }

  return fullscreenAPI;
}();

var Fullscreen = function () {
  function Fullscreen() {
    _classCallCheck(this, Fullscreen);
  }

  _createClass(Fullscreen, null, [{
    key: 'api',
    value: function api() {
      return fsApi;
    }
  }, {
    key: 'isSupported',
    value: function isSupported() {
      return !!fsApi;
    }
  }, {
    key: 'isFullscreen',
    value: function isFullscreen() {
      return !!document[fsApi.fullscreenElement];
    }
  }, {
    key: 'enabled',
    value: function enabled() {
      return !!document[fsApi.fullscreenEnabled];
    }
  }, {
    key: 'element',
    value: function element() {
      return document[fsApi.fullscreenElement];
    }
  }, {
    key: 'request',
    value: function request(elem) {
      var request = fsApi.requestFullscreen;

      elem = elem || document.documentElement;

      // Work around Safari 5.1 bug: reports support for
      // keyboard in fullscreen even though it doesn't.
      // Browser sniffing, since the alternative with
      // setTimeout is even worse.
      if (/5\.1[\.\d]* Safari/.test(navigator.userAgent)) {
        elem[request]();
      } else {
        elem[request](keyboardAllowed && Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }, {
    key: 'exit',
    value: function exit() {
      document[fsApi.exitFullscreen]();
    }
  }, {
    key: 'toggle',
    value: function toggle(elem) {
      if (Fullscreen.isFullscreen()) {
        Fullscreen.exit();
      } else {
        Fullscreen.request(elem);
      }
    }
  }]);

  return Fullscreen;
}();

exports.default = Fullscreen;
