'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var utilities = utils.utilityFunctions;
var dom = utils.dom;

var VideoQualityItem = require('./video-quality-item');

/* Video Quality Menu - To select the allowed video qualities.
 ================================================================================ */
var VideoQuality = videojs.MenuButton.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.MenuButton.call(this, player, options);
    var _this = this;

    updateVisibility();

    player.on('mol.sources.updated', function () {
      _this.removeChild(_this.menu);

      _this.menu = _this.createMenu();
      _this.addChild(_this.menu);
      selectCurrentRendition();

      updateVisibility();
    });

    player.on('mol.sources.renditionChanged', selectCurrentRendition);

    /*** Local functions ***/
    function updateVisibility() {
      if (_this.items.length <= 1) {
        _this.hide();
      } else {
        _this.show();
      }
    }

    function selectCurrentRendition() {
      var currentSrc = player.src();
      if (!_this.items) {
        return;
      }

      _this.items.forEach(function (item) {
        item.selected(isSelected(item, currentSrc));
      });
    }

    function isSelected(item, currentSrc) {
      var i, len, rendition;
      for (i = 0, len = item.renditions.length; i < len; i += 1) {
        rendition = item.renditions[i];
        if (rendition.src === currentSrc) {
          return true;
        }
      }

      return false;
    }
  }
});

VideoQuality.prototype.createEl = function () {
  return videojs.Button.prototype.createEl.call(this, 'div', {
    className: 'vjs-video-quality-control vjs-control'
  });
};

VideoQuality.prototype.options_ = {
  title: 'Video Quality'
};

VideoQuality.prototype.createItems = function () {
  var player = this.player();
  var renditions = player.renditions;
  if (utilities.isArray(renditions)) {
    return createVQItems(this.player(), renditions.slice().sort(descending('height')));
  }

  return [];

  /*** Local functions ***/
  function descending(key) {
    return function compare(a, b) {
      if (a[key] > b[key]) {
        return -1;
      }

      if (a[key] < b[key]) {
        return 1;
      }

      return 0;
    };
  }

  function createVQItems(player, renditions) {
    var tmpMap = {};
    var items = [];
    renditions.forEach(function (rendition) {
      var key = rendition.height;
      var item = tmpMap[key];
      if (!item) {
        item = createVQItem(player, rendition);
        tmpMap[key] = item;
        items.push(item);
      }

      item.addRendition(rendition);
    });

    return items;
  }

  function createVQItem(player, rendition) {
    return new VideoQualityItem(player, {
      width: rendition.width,
      height: rendition.height,
      renditions: []
    });
  }
};

VideoQuality.prototype.onClick = function () {
  var hideMenu = videojs.bind(this, function (event) {
    var toElem = !!event.toElement ? event.toElement : event.relatedTarget;

    if (toElem !== this.el() && !dom.isDescendant(this.el(), toElem)) {
      //noinspection JSPotentiallyInvalidUsageOfThis
      this.unpressButton();
      dom.removeEventListener(this.el(), 'mouseout', hideMenu);
    }
  });

  dom.addEventListener(this.el(), 'mouseout', hideMenu);

  if (this.buttonPressed_) {
    this.unpressButton();
  } else {
    this.pressButton();
  }
};

VideoQuality.prototype.createMenu = function () {
  var menu = new videojs.Menu(this.player(), { contentElType: 'div' });

  // Add a title list item to the top
  if (this.options().title) {
    menu.contentEl().appendChild(videojs.createEl('div', {
      className: 'vjs-menu-title',
      innerHTML: utilities.capitalize(this.options().title),
      tabindex: -1
    }));
  }

  this.items = this.createItems();

  if (this.items) {
    this.items.forEach(function (vqItem) {
      menu.addItem(vqItem);
    });
  }

  return menu;
};

VideoQuality.prototype.pressButton = function () {
  videojs.MenuButton.prototype.pressButton.call(this);
  dom.addClass(this.el(), 'active');
};

VideoQuality.prototype.unpressButton = function () {
  videojs.MenuButton.prototype.unpressButton.call(this);
  dom.removeClass(this.el(), 'active');
};

module.exports = VideoQuality;
