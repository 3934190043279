'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _require = require('./utils'),
    nodeListToArray = _require.nodeListToArray;

var VideoPreviewBase = require('./base/VideoPreviewBase');

function createVideoElement(videoSrc, poster) {
  var styles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var video = document.createElement('video');
  if (poster) {
    video.poster = poster;
  }

  video.loop = true;
  video.innerHTML = '<source src="' + videoSrc + '" type="video/mp4"/>';
  Object.assign(video.style, styles);
  return video;
}

var VideoPreview = function (_VideoPreviewBase) {
  _inherits(VideoPreview, _VideoPreviewBase);

  function VideoPreview() {
    _classCallCheck(this, VideoPreview);

    return _possibleConstructorReturn(this, (VideoPreview.__proto__ || Object.getPrototypeOf(VideoPreview)).apply(this, arguments));
  }

  _createClass(VideoPreview, [{
    key: '_init',
    value: function _init() {
      var _this2 = this;

      if (this.element.tagName.toLowerCase() === 'video') {
        this.element.loop = true;
        var sources = this.element.getElementsByTagName('source');
        nodeListToArray(sources).forEach(function (source) {
          var srcUrl = source.getAttribute('data-src');
          if (srcUrl) {
            var newSource = document.createElement('source');
            newSource.src = srcUrl;
            newSource.type = source.type;
            _this2.element.replaceChild(newSource, source);
          }
        });
      } else {
        var videoSrc = this.element.getAttribute('data-video');
        if (!videoSrc) return; // do not proceed, as it will create an empty space - there is no asset to load
        var posterImg = this.element.getAttribute('data-poster') || this.element.src;
        this._registerMainElement(createVideoElement(videoSrc, posterImg, {
          width: this.element.clientWidth + 'px',
          height: this.element.clientHeight + 'px'
        }));
      }

      this.initDone = true;
    }
  }, {
    key: '_play',
    value: function _play() {
      _get(VideoPreview.prototype.__proto__ || Object.getPrototypeOf(VideoPreview.prototype), '_play', this).call(this);
      this.element && this.element.play && this.element.play();
    }
  }, {
    key: '_pause',
    value: function _pause() {
      _get(VideoPreview.prototype.__proto__ || Object.getPrototypeOf(VideoPreview.prototype), '_pause', this).call(this);
      this.element && this.element.pause && this.element.pause();
    }
  }]);

  return VideoPreview;
}(VideoPreviewBase);

module.exports = VideoPreview;
