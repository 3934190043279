'use strict';

var urlUtils = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/urlUtils');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');

function overwriteAdOptions(options) {
  var queryOptions = urlUtils.queryStringToObj(window.location.search);
  options.viewportOffset = utilities.isDefined(queryOptions.flyoutViewportOffset) ? parseFloat(queryOptions.flyoutViewportOffset) : options.viewportOffset;
  options.animTime = utilities.isDefined(queryOptions.flyoutAnimTime) ? parseInt(queryOptions.flyoutAnimTime, 10) : options.animTime;
  options.animTimeWait = utilities.isDefined(queryOptions.flyoutAnimTimeWait) ? parseInt(queryOptions.flyoutAnimTimeWait, 10) : options.animTimeWait;
  options.switchBack = utilities.isDefined(queryOptions.flyoutSwitchBack) ? queryOptions.flyoutSwitchBack === 'true' : options.switchBack;

  // switchBack needs to have the same offset
  if (options.switchBack) {
    options.viewportGraceOffset = options.viewportOffset;
  }

  return options;
}

function createElBefore(cssClasses, elBefore) {
  var el = document.createElement('div');
  el.className = cssClasses;
  elBefore.parentNode.insertBefore(el, elBefore);
  return el;
}

function wrapElement(el, newParent) {
  newParent.appendChild(el);
  return el;
}

function onDisableForceFlyout(fn) {
  window.addEventListener('message', function (event) {
    if (event.data === 'mol-fe-videoplayer.flyout-disable') {
      fn();
    }
  });
}

var getVerticalOffset = (function () {
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');

  if (supportPageOffset) {
    return function () {
      return window.pageYOffset;
    };
  } else if (isCSS1Compat) {
    return function () {
      return document.documentElement.scrollTop;
    };
  } else {
    return function () {
      return document.body.scrollTop;
    };
  }
}());

var flyoutUtils = {
  createElBefore: createElBefore,
  getVerticalOffset: getVerticalOffset,
  onDisableForceFlyout: onDisableForceFlyout,
  overwriteAdOptions: overwriteAdOptions,
  wrapElement: wrapElement
};

module.exports = flyoutUtils;
