'use strict';

require('./ads-setup/ads-setup-plugin');
require('./ima-setup/ima-setup-plugin');
require('./vast-setup/vast-setup-plugin');

require('./history/history-plugin');
require('./autoplay-next-video/autoplay-next-video-plugin');
require('./flyout/flyout-plugin');
require('./playlist/playlist-plugin');
require('./post-content/post-content-plugin');
require('./related-videos/related-videos-plugin');
require('./reset/reset-plugin');
require('./tracking/tracking-plugin');
require('./video-starts/video-starts-plugin');
require('./animated-preview/index');
require('./branded-content/branded-content-plugin');
require('./editorial-content/editorial-content-plugin');
require('./autoplay-inline/autoplay-inline-plugin');
