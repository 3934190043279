'use strict';

/*jshint bitwise: false*/

var isIE11 = exports.isIE11 = function () {
  return ~window.navigator.userAgent.indexOf('rv:11');
};

exports.isIE = function () {
  var ua = window.navigator.userAgent;
  return navigator.appName == 'Microsoft Internet Explorer'
    || ~ua.indexOf('Trident/')
    || isIE11();
};
