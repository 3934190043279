'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _require = require('./utils'),
    onOrientationChange = _require.onOrientationChange,
    onScroll = _require.onScroll,
    onDocumentLoad = _require.onDocumentLoad,
    isMobile = _require.isMobile,
    isTablet = _require.isTablet;

var VideoPreview = require('./VideoPreview');
var VideoPreviewImg = require('./VideoPreviewImg');

var VideoPreviewRegister = function () {
  function VideoPreviewRegister() {
    _classCallCheck(this, VideoPreviewRegister);

    this.initOnDocumentLoad = true;
    this.pool = [];
    this.parents = [window];
    this._showOriginalOnPause = false;
  }

  _createClass(VideoPreviewRegister, [{
    key: 'cleanUp',
    value: function cleanUp() {
      this.removeAll();
      this.pool = [];
    }
  }, {
    key: 'removeAll',
    value: function removeAll() {
      this.pool.forEach(function (videoPreview) {
        videoPreview._deRegisterMainElement();
      });
    }
  }, {
    key: 'addElements',
    value: function addElements(elements) {
      var _this = this;

      if (!elements) {
        return;
      }

      var isArray = elements.constructor === Array;
      if (!isArray) {
        elements = [elements];
      }

      var videoPreviews = elements.reduce(function (result, element) {
        var videoPreview = void 0;

        try {
          if (isTablet()) {
            if (element.getAttribute('data-hd-film')) {
              videoPreview = new VideoPreviewImg(element, 'hd');
            }
          } else if (isMobile()) {
            if (element.getAttribute('data-film')) {
              videoPreview = new VideoPreviewImg(element);
            }
          } else if (element.getAttribute('data-video') || element.tagName.toLowerCase() === 'video') {
            videoPreview = new VideoPreview(element);
          } else if (element.getAttribute('data-hd-film')) {
            videoPreview = new VideoPreviewImg(element, 'hd');
          } else if (element.getAttribute('data-film')) {
            videoPreview = new VideoPreviewImg(element);
          }

          if (videoPreview) {
            _this.synchVideoPreviewAttrs(videoPreview);
            videoPreview.synch();
            result.push(videoPreview);
          }
        } catch (err) {
          console.error(err);
        }

        return result;
      }, []);
      this.pool.push.apply(this.pool, videoPreviews);
      return isArray ? videoPreviews : videoPreviews[0];
    }
  }, {
    key: 'addParent',
    value: function addParent(parent) {
      parent && this.parents.indexOf(parent) === -1 && this.parents.push(parent);
    }
  }, {
    key: 'synchAll',
    value: function synchAll() {
      this.pool.forEach(function (videoPreview) {
        return videoPreview.synch();
      });
    }
  }, {
    key: 'synchResizeAll',
    value: function synchResizeAll() {
      this.pool.forEach(function (videoPreview) {
        return videoPreview.synchResize();
      });
    }
  }, {
    key: 'enableListeners',
    value: function enableListeners() {
      this.orientationChangeListener = onOrientationChange(this.synchResizeAll.bind(this));
      this.scrollListener = onScroll(this.parents, this.synchAll.bind(this), 50);
    }
  }, {
    key: 'disableListeners',
    value: function disableListeners() {
      this.orientationChangeListener && this.orientationChangeListener.stop();
      this.scrollListener && this.scrollListener.stop();
    }
  }, {
    key: 'synchVideoPreviewAttrs',
    value: function synchVideoPreviewAttrs(videoPreview) {
      videoPreview.showOriginalOnPause = this._showOriginalOnPause;
    }
  }, {
    key: 'showOriginalOnPause',
    set: function set(bool) {
      this._showOriginalOnPause = !!bool;
      this.pool.forEach(this.synchVideoPreviewAttrs.bind(this));
    },
    get: function get() {
      return this._showOriginalOnPause;
    }
  }]);

  return VideoPreviewRegister;
}();

var videoPreviewRegister = new VideoPreviewRegister();

onDocumentLoad(function () {
  if (isMobile()) {
    videoPreviewRegister.showOriginalOnPause = true;
    videoPreviewRegister.addParent(document.querySelector('.scrollable-content'));
  }

  videoPreviewRegister.initOnDocumentLoad && videoPreviewRegister.enableListeners();
});

module.exports = videoPreviewRegister;
