'use strict';

var EmbedPopupContent = require('./embed-popup-content');
var FullWidthPopup = require('../base/full-width-popup');

var EmbedPopup = FullWidthPopup.extend({
  /** @Constructor */
  init: function (player, options) {
    FullWidthPopup.call(this, player, options);
    this.addChild(new EmbedPopupContent(player));
  }
});

EmbedPopup.prototype.createEl = function () {
  return FullWidthPopup.prototype.createEl.call(this, 'div', {
    className: 'vjs-embed-popup'
  });
};

module.exports = EmbedPopup;
