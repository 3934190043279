'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var mol = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/mol');
var urlUtils = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/urlUtils');
var nielsen = require('./nielsen');
var flyoutUtils = require('../flyout/flyout-utils');
var events = DM.pageEvents;
var isAutoplaying = require('../../utils/isAutoplaying');

flyoutUtils.onDisableForceFlyout(function () {
  events.fireEvent(events.VIDEO_CONFIG_CHANGE, {
    value: {
      autoFlyAway: false
    }
  });
});

videojs.plugin('tracking', function molTrackingSetup(opts) {
  var player = this;
  var experienceId = player.id() + 'video';
  var options;
  var isVPAID;

  // Initialize Nielsen DCR tracking if required options set
  var nielsenEnabled = false;
  var nielsenAdSent = false;
  var nielsenSentMeta = false;

  // Nielsen tracking init ---
  var nielsenOptions;
  var queryOptions = urlUtils.queryStringToObj(window.location.search);
  var debugNielsenVideoDCR = utilities.isDefined(queryOptions.debugNielsenVideoDCR) &&
    queryOptions.debugNielsenVideoDCR === 'true';

  nielsenOptions = window._nolggGlobalParams;

  if (debugNielsenVideoDCR) {
    nielsenOptions = {
      // TODO: once in prod change to 'P1E713557-917A-4B7A-98A0-45AB34B64C08'
      apid: 'T1E713557-917A-4B7A-98A0-45AB34B64C08',
      apn: 'dailymail au',
      sfcode: 'dcr-cert'
    };
  }

  if (nielsenOptions && nielsenOptions.apid && nielsenOptions.apn) {
    nielsenEnabled = true;
    nielsen.initInstance(player, nielsenOptions).then(function (nielsenInstance) {
      if (!nielsenInstance) {
        nielsenEnabled = false;
      }
    });
  }

  // -------------------------

  function isStreaming() {
    return !!player.options().isStreaming;
  }

  function getTrackingType(player, options) {
    if (player.autoplayInline) {
      return 'autoplayInlineVideoPlayer';
    }

    if (player.flyoutOn) {
      if (player.forceFlyOutInProgress) {
        return player.flyoutExpanded ? 'flyout_expanded_automatic' : 'flyout_collapsed_automatic';
      } else {
        return player.flyoutExpanded ? 'flyout_expanded_user' : 'flyout_collapsed_user';
      }
    }

    return options.trackingType;
  }

  function videoData(eventType, error) {
    var isAd = eventType === 'ad';
    var noLength =  isAd ? isVPAID : isStreaming();
    var noTime =  isAd && isVPAID;
    var title;
    var data;

    try {
      options = player.options().plugins.tracking;
    }
    catch (e) {
      options = opts;
    }

    title = options.title || player.options().title;

    data = {
      autoplay: isAutoplaying(player),
      animatedPreviewEnabled: !!player.options().animatedPreviewEnabled,
      dmtvEpisode: options.dmtvEpisode || null,
      currentSrc: player.currentSrc(),
      currentType: player.currentType(),
      length: noLength ? -1 : (player.duration() * 1000),
      version: mol.getVideoPlayerVersion(),
      pageMetadata: events.getPageMetadata(),
      videoId: player.options().videoId,         // id from BC
      title: title,
      img: player.poster(),
      displayName:  title,                       // this is the title in tracking
      playerId: playerType(),       //let's add an id of this configuration!
      experienceId: experienceId,                // page position id + 'video'
      adBlockerActive: window.adBlockerActive,   // to be removed
      sponsored: options.sponsored,
      playerType: player.techName.toUpperCase(), // flash or HTML5
      playlist: 'playlistCounter' in player,
      continuePlayList: ('playlistCounter' in player ? player.playlistCounter > 0 : false) || ('autoplayIndex' in player ? player.autoplayIndex > 0 : false),
      trackingType: getTrackingType(player, options),
      channelShortName: options.channelShortName,
      initialVideo: !!player.options().initialVideo,
      referenceId: options.referenceId,          //this is ID from WPS (oracle)
      videoChannel: options.videoChannel || options.channelShortName,
      offset: noTime ? 0 : player.currentTime(),
      duration: noLength ? 0 : player.duration(),
      fullscreen: player.isFullscreen(),
      paused: player.paused(),
      muted: player.muted(),
      adSDK: player.adSDK
    };

    if (player.options()['fbia-embed']) {
      data.ito = 'FBIA';
    } else if (player.options().syndicationService) {
      data.ito = player.options().syndicationService.toUpperCase();
    }

    if (!player.playingRecommendedVideo) {
      data.recommendationModel = player.tagAttributes['data-recommendation-model'];
    } else if (options.recommendationModel) {
      data.recommendationModel = options.recommendationModel;
    }

    if (error) {
      data.errorMessage = error.message;

      if (error.code) {
        data.adErrorCode = error.code;
      }
    }

    if (nielsenEnabled) {
      data.program = 'DailyMail \'' + options.channelShortName + '\' Videos';
      data.isfullepisode = 'n';
      data.hasAds = player.adsEnabled ? 1 : 0;
      data.adloadtype = 1;
      data.subbrand = nielsen.getSubbrand(data.videoChannel);
    }

    return data;
  }

  function videoSegment(data) {
    return utilities.extend({}, data, videoData());
  }

  player.ready(function () {
    events.fireEvent(events.VIDEO_PLAYER_READY, { playerType: player.techName.toUpperCase(), experienceId: experienceId });
  });

  var streamStarted = false;

  function fireStreamStarted(eventType) {
    if (streamStarted === false) {
      streamStarted = true;
      events.fireEvent(events.VIDEO_STREAM_STARTED, videoData(eventType));
    }
  }

  function isLightbox(player) {
    return typeof player.tagAttributes['data-is-lightbox-video'] !== 'undefined';
  }

  /** function used to customize the player size; this is calculated by different sources;
   * isMobile, vjs-span size (one, two, three or responsive) and carousel
   * **/
  function playerType() {
    var classes = player.el().parentNode.className || '';
    var match = classes.match(/(vjs-span-)([^ ]+)*/);
    var sizeMap = {
      'vjs-span-one-col': 'one-col',
      'vjs-span-two-col': 'two-col',
      'vjs-span-three-col': 'three-col',
      null: 'default'
    };

    if (player.options()['fbia-embed']) {
      return 'fbiaembedded';
    } else if (player.options().syndicationService === 'fbia') {
      return 'fbiaembedded';
    } else if (player.options().syndicationService === 'gamp') {
      return 'gampembedded';
    }

    if (player.options().isEmbedded) {
      return 'thirdpartyembedded';
    }

    if (player.options().isMobile) {
      return 'mobile';
    }

    if (document.querySelectorAll('.ez-modal-on').length || isLightbox(player)) {
      return 'carousel';
    }

    if (match) {
      return 'article-' + sizeMap[match[0]];
    } else {
      return 'responsive';
    }
  }

  function trackAdError(event) {
    events.fireEvent(events.VIDEO_ADVERT_FAILED, videoData('ad', event.error));

    setTimeout(function () {
      if (!streamStarted) {
        events.fireEvent(events.VIDEO_STREAM_FAILED, videoData('', new Error('Stream failed possibly due to an ad error')));
      }
    }, 5000);
  }

  player.on('ima.adsError', trackAdError);
  player.on('vast.adError', trackAdError);

  player.on('error', function () {
    var tech = player.el().querySelector('.vjs-tech');
    var error = tech && tech.error;

    if (error) {
      events.fireEvent(events.VIDEO_STREAM_FAILED, videoData('', error));
    }
  });

  player.on('playerstate.enter.started', function () {
    events.fireEvent(events.VIDEO_DATA_READY, videoData());
    streamStarted = false;
  });

  player.on('playerstate.enter.adEnded', function () {
    if (nielsenEnabled) {
      nielsenAdSent = false;
      nielsen.prerollEnded(videoData('ad'), player);
    }

    events.fireEvent(events.VIDEO_ADVERT_COMPLETED, videoData('ad'));
  });

  player.on('playerstate.enter.contentReady', function () {
    fireStreamStarted();

    if (nielsenEnabled && !nielsenSentMeta) {
      nielsenSentMeta = true;
      nielsen.videoStart(videoData(), player, isStreaming());
      nielsen.setPlayhead(player, isStreaming());
    }

    events.fireEvent(events.VIDEO_CONTENT_STARTED, videoData());
  });

  player.on('playerstate.enter.contentPlaying', function () {
    if (nielsenEnabled && !nielsenSentMeta) {
      nielsenSentMeta = true;
      nielsen.videoStart(videoData(), player, isStreaming());
      nielsen.setPlayhead(player, isStreaming());
    }
  });

  player.on('playerstate.enter.adPlaying', function () {
    try {
      isVPAID = player.vast.type === 'IMA' || player.vast.adUnit.type === 'VPAID';
    }
    catch (e) {
      isVPAID = true;
      console.log('player.vast.adUnit is unexpectedly null');
    }

    fireStreamStarted('ad');

    if (nielsenEnabled && !nielsenAdSent) {
      // NOTE: Requested by Nielsen to sent the metadata before we send the ad metadata
      //       and the playhead postition after loading the ad metadata.
      nielsen.videoStart(videoData(), player, isStreaming());
      nielsenAdSent = true;
      nielsen.preroll(videoData('ad'), player, isVPAID);
      nielsen.setPlayhead(player, isStreaming());
    }

    events.fireEvent(events.VIDEO_ADVERT_STARTED, videoData('ad'));
  });

  player.on('playerstate.leave.pauseStandby', function () {
    events.fireEvent(events.VIDEO_RESUMED, videoData());
  });

  player.on('playerstate.enter.pauseStandby', function () {
    events.fireEvent(events.VIDEO_PAUSED, videoData());
  });

  // seeking
  player.on('playerstate.leave.seeking', function () {
    events.fireEvent(events.VIDEO_RESUMED, videoData());
  });

  player.on('playerstate.enter.seeking', function () {
    events.fireEvent(events.VIDEO_PAUSED, videoData());
  });

  player.on('fullscreenchange', function () {
    if (player.hasStarted()) {
      events.fireEvent(events.VIDEO_EXTRA, videoData());
    }
  });

  player.on('volumechange', function () {
    if (player.hasStarted()) {
      events.fireEvent(events.VIDEO_EXTRA, videoData());
    }
  });

  player.on('playerstate.enter.ended', function () {
    var data = videoData();

    //NOTE: if the offset or the duration are 0 it means that there was an error
    //      and it should not track a video_content_complete evt
    if (data.offset !== 0 && data.duration !== 0) {
      events.fireEvent(events.VIDEO_CONTENT_COMPLETED, data);

      if (nielsenEnabled) {
        nielsenSentMeta = false;
        nielsen.end(data, player);
      }
    }
  });

  // video player destroyed
  player.on('playerstate.enter.destroyedDuringAds', function () {
    var data = videoData('ad');

    events.fireEvent(events.VIDEO_ADVERT_ABORTED, data);
    nielsen.videoReset(data, player);
    nielsenAdSent = false;
    nielsenSentMeta = false;
  });

  // video player request reset
  player.on('playerstate.enter.aborting', function () {
    var data = videoData();

    events.fireEvent(events.VIDEO_CONTENT_ABORTED, data);
    nielsen.videoReset(data, player);
    nielsenAdSent = false;
    nielsenSentMeta = false;
  });

  player.on('playerstate.enter.destroyed', function () {
    events.fireEvent(events.VIDEO_CONTENT_ABORTED, videoData());
  });

  // progress
  player.on('playerstate.progress.enter.0', function () {
    if (isStreaming()) return;

    var seg = {
      begin: 0,
      end: 0.25,
      data: {
        segmentNum: 1,
        segment: 'M:0-25',
        ratio: 0
      }
    };
    seg.data.segmentLength = (seg.end - seg.begin) * player.duration();
    events.fireEvent(events.VIDEO_PROGRESS, videoSegment(seg.data));
  });

  player.on('playerstate.progress.enter.25', function () {
    if (isStreaming()) return;

    var seg = {
      begin: 0.25,
      end: 0.50,
      data: {
        segmentNum: 2,
        segment: 'M:25-50',
        ratio: 0.25
      }
    };
    seg.data.segmentLength = (seg.end - seg.begin) * player.duration();
    events.fireEvent(events.VIDEO_PROGRESS, videoSegment(seg.data));
  });

  player.on('playerstate.progress.enter.50', function () {
    if (isStreaming()) return;

    var seg = {
      begin: 0.50,
      end: 1,
      data: {
        segmentNum: 3,
        segment: 'M:50-100',
        ratio: 0.50
      }
    };
    seg.data.segmentLength = (seg.end - seg.begin) * player.duration();
    events.fireEvent(events.VIDEO_PROGRESS, videoSegment(seg.data));
  });

  player.on('brandedContentPlayer.progress', function () {
    events.fireEvent(events.VIDEO_BRANDED_CONTENT_PROGRESS, videoData());
  });

  if (nielsenEnabled) {
    var lastSent = 0;

    player.on('timeupdate', function () {
      var now = Date.now();
      if ((now - lastSent) >= 1000) {
        nielsen.setPlayhead(player, isStreaming());
        lastSent = now;
      }
    });
  }
});
