'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VideoPreviewBase = require('./base/VideoPreviewBase');
var VideoPreviewImgSimplePlay = require('./VideoPreviewImgSimplePlay');
var VideoPreviewImgFadePlay = require('./VideoPreviewImgFadePlay');

var _require = require('./utils'),
    waitForElementToLoad = _require.waitForElementToLoad,
    calcPaddingStyles = _require.calcPaddingStyles;

var VideoPreviewImg = function (_VideoPreviewBase) {
  _inherits(VideoPreviewImg, _VideoPreviewBase);

  function VideoPreviewImg(el, quality) {
    _classCallCheck(this, VideoPreviewImg);

    var _this = _possibleConstructorReturn(this, (VideoPreviewImg.__proto__ || Object.getPrototypeOf(VideoPreviewImg)).call(this, el));

    var attrPrefix = quality ? quality + '-' : '';
    _this.filmSrc = _this._getDataAttribute(attrPrefix + 'film');
    if (!_this.filmSrc) {
      throw new Error('Could not init VideoPreviewImg: data attribute ' + attrPrefix + 'film must not be empty');
    }

    _this.fps = parseFloat(_this._getDataAttribute('fps', quality)) || 5;
    _this.frameCount = parseInt(_this._getDataAttribute('frame-count', quality), 10);
    _this.frameWidth = parseInt(_this._getDataAttribute('frame-width', quality), 10);
    _this.frameHeight = parseInt(_this._getDataAttribute('frame-height', quality), 10);
    _this.frameRatio = _this.frameWidth / _this.frameHeight;
    if (_this._hasDataAttribute(attrPrefix + 'fade')) {
      _this.playbackManager = new VideoPreviewImgFadePlay(_this);
    } else {
      _this.playbackManager = new VideoPreviewImgSimplePlay(_this);
    }
    return _this;
  }

  _createClass(VideoPreviewImg, [{
    key: '_getDataAttribute',
    value: function _getDataAttribute(attr, prefix) {
      var finalPrefix = prefix ? '-' + prefix : '';
      return this.element.getAttribute('data' + finalPrefix + '-' + attr) || prefix && this.element.getAttribute('data-' + attr);
    }
  }, {
    key: '_hasDataAttribute',
    value: function _hasDataAttribute(attr, prefix) {
      var finalPrefix = prefix ? '-' + prefix : '';
      return this.element.hasAttribute('data' + finalPrefix + '-' + attr) || prefix && this.element.hasAttribute('data-' + attr);
    }
  }, {
    key: 'fixElement',
    value: function fixElement() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      _get(VideoPreviewImg.prototype.__proto__ || Object.getPrototypeOf(VideoPreviewImg.prototype), 'fixElement', this).call(this, element);
      Object.assign(element.style, calcPaddingStyles(this.originalElement, this.frameRatio));
      return this;
    }
  }, {
    key: '_createFilmElement',
    value: function _createFilmElement() {
      if (this.filmElement) return this.filmElement;
      if (!this.filmSrc) return;
      this.filmElement = new Image();
      this.filmElement.src = this.filmSrc;
      return this.filmElement;
    }
  }, {
    key: '_init',
    value: function _init() {
      var _this2 = this;

      return this._createFilmElement() && waitForElementToLoad(this.filmElement).then(function () {
        var container = VideoPreviewImg.createFramesContainer();
        _this2.fixElement(container);
        _this2._registerMainElement(container);
        _this2.playbackManager.init();
        _this2.initDone = true;
      }).catch(function (err) {
        _this2.synchIsAllowed = false;
        throw err;
      });
    }
  }, {
    key: '_play',
    value: function _play() {
      _get(VideoPreviewImg.prototype.__proto__ || Object.getPrototypeOf(VideoPreviewImg.prototype), '_play', this).call(this);
      this.playbackManager.play();
    }
  }, {
    key: '_pause',
    value: function _pause() {
      _get(VideoPreviewImg.prototype.__proto__ || Object.getPrototypeOf(VideoPreviewImg.prototype), '_pause', this).call(this);
      this.playbackManager.pause();
    }
  }], [{
    key: 'createFramesContainer',
    value: function createFramesContainer() {
      var container = document.createElement('div');
      container.className = 'frames-container';
      return container;
    }
  }]);

  return VideoPreviewImg;
}(VideoPreviewBase);

module.exports = VideoPreviewImg;
