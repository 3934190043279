'use strict';

var ModalWindowBackdrop = require('./../../components/modal-window-backdrop');
var ModalWindow = require('./../../components/modal-window');
var RelatedVideosPanelSocial = require('./related-videos-panel-social');
var RelatedVideosPanelPaused = require('./related-videos-panel-paused');

var RelatedVideosViewPaused = videojs.Component.extend({
  init: function (player, options) {
    videojs.Component.call(this, player, options);

    var modalWindowBackdrop = new ModalWindowBackdrop(player);
    var modalWindow = new ModalWindow(player);

    modalWindowBackdrop.addChild(modalWindow);

    modalWindow.addChild(new RelatedVideosPanelSocial(player));

    modalWindow.addChild(new RelatedVideosPanelPaused(player, {
      videos: options.videos
    }));

    this.addChild(modalWindowBackdrop);
  }
});

RelatedVideosViewPaused.prototype.createEl = function () {
  var posterUrl = this.player().options().poster;

  var viewElement = {
    className: 'mol-related-videos-view-paused'
  };

  if (posterUrl) {
    viewElement.innerHTML = '<div class="mol-related-videos-view-paused-backdrop" style="background-image: url(\'' + posterUrl + '\');"></div>';
  }

  return videojs.createEl('div', viewElement);
};

module.exports = RelatedVideosViewPaused;
