'use strict';

var async = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/async');
var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var systemInfo = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/systemInfo');

videojs.plugin('player-events', function resize() {
  var player = this;
  var el = player.el();

  var fireResize = utilities.debounce(function () {
    player.trigger('resize');
  }, 50);

  var isFlyoutSupported = function (player) {
    return 'flyoutState' in player;
  };

  var pauseAllPlayers = function (evt) {
    var currentPlayer = 'player' in evt ? evt.player : {};

    var currentPlayerFlyoutSupported = isFlyoutSupported(currentPlayer);

    var previousFlyoutState = -1;
    DM.molFeVideoplayer.forEachPlayer(function () {
      var flyoutState = isFlyoutSupported(this) ? this.flyoutState() : -1;
      if (this !== currentPlayer) {
        this.trigger('autoplay-next-video-cancel');
        if (flyoutState !== -1) {
          previousFlyoutState = flyoutState;
        }

        if (currentPlayerFlyoutSupported) {
          this.trigger({ type: 'flyout-switchoff', animationOff: true });
        }

        if (!this.paused() || (player._states && player._states.state.get() === 'adPlaying')) {
          this.pause();
        }
      }
    });

    if (currentPlayerFlyoutSupported && previousFlyoutState !== -1) {
      currentPlayer.trigger({ type: 'flyout-switchon', animationOff: true });
    }

    if (currentPlayerFlyoutSupported && previousFlyoutState === 1) {
      currentPlayer.trigger('flyout-expand');
    }
  };

  window.onresize = fireResize;

  player.on('fullscreenchange', fireResize);

  player.on('fullscreenchange', function () {
    if (player.isFullscreen()) {
      document.body.classList.add('video-js-fullscreen');
    } else {
      document.body.classList.remove('video-js-fullscreen');
    }
  });

  player.ready(function () {
    setTimeout(function () {
      dom.addClass(el, 'vjs-visually-ready');
    }, 10);
  });

  // only one video can play
  DM.molFeVideoplayer.on('videoPlaying', pauseAllPlayers);

  DM.molFeVideoplayer.on('pauseAll', pauseAllPlayers);

  [
    'play',
    'ima.firstPlay'
  ].forEach(function (event) {
    player.on(event, function () {
        DM.molFeVideoplayer.trigger('videoPlaying', { player: player });
      });
  });

  // Iphone native player (iphone4) does not fire seeking, seeked and play
  // try to do it manually
  // the seeking time is not exact as it is calculated after
  // the first jump. It is good enough when the user seek very slow
  if (systemInfo.isIPhone && systemInfo.osMajorVersion <= 7) {
    var isSeeking = false;
    var previousPos;

    var detectGaps = function detectGaps() {
      var currentPos = player.currentTime();
      var diff;

      if (typeof previousPos !== 'undefined') {
        diff = Math.abs(previousPos - currentPos);
        if (diff > 1.2) {
          player.trigger('seeking');
          isSeeking = true;
        } else {
          if (isSeeking) {
            player.trigger('seeked');
            isSeeking = false;
          }
        }
      }

      previousPos = currentPos;
    };

    // player.on('timeupdate', detectGaps);
    player.on('contenttimeupdate', detectGaps);

    player.on('playerstate.enter.contentReady', function () {
      previousPos = undefined;
    });

    player.on('playerstate.leave.seeking', function () {
      previousPos = undefined;
    });

  }

  // Iphone native player (iphone5+) does not fire play
  // try to do it manually
  // TODO : investigate ipad behaviour
  if (systemInfo.isIPhone) {

    var firePlay = utilities.debounce(function () {
      player.trigger('play-after-seek');
    }, 800);

    player.on('seeking', function () {
      if (!player.paused()) {
        firePlay();
      }
    });

    player.on('seeked', function () {
     if (!player.paused()) {
       firePlay();
     }
   });
  }

  player.currentTime = (function (originalCurrentTime) {
    return function (s) {

      if (typeof s !== 'undefined') {
        var t1 = originalCurrentTime.call(this);
        if (Math.abs(s - t1) > 1.2) {
          player.trigger('before-seeking');
        }

        var _this = this;
        async.defer(function () {
          originalCurrentTime.call(_this, s);
        });

      } else {
        return originalCurrentTime.call(this, s);
      }
    };
  }(player.currentTime));

  // issue on IE11 (only): when you seek you get this sequence of events:
  // chrome: seeking waiting seeked canplay canplaythrough ...
  // IE11: seeking waiting seeked
  // IE10: seeking seeked
  // on IE11 we add vjs-waiting on waiting but we don't remove it
  player.on('seeked', player.onWaitEnd);

  player.on('playerstate.leave.errored', function () {
    player.removeChild('errorDisplay');
  });

  player.on('warning', function (evt) {
    videojs.log.error(evt.message);
  });

  // cleaning up on dispose
  player.on('request-dispose', function () {
    dom.removeEventListener(window, 'resize', fireResize);
    player.pause();
    DM.molFeVideoplayer.off('videoPlaying', pauseAllPlayers);
    setTimeout(function () {
        player.dispose();
      }, 400);
  });
});
