'use strict';

var persistFn = function (testFn, cb) {
  var callFn = function (toGo, delay) {
    var testResult = testFn();

    if (testResult) {
      cb();
    } else if (toGo > 0) {
      setTimeout(function () {
        callFn(--toGo, delay);
      }, delay);
    }
  };

  return callFn;
};

module.exports = persistFn;
