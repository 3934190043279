'use strict';

function calcPositionStyles(originalElement) {
  var elementComputedStyle = document.defaultView.getComputedStyle(originalElement, '');
  var styles = {
    width: originalElement.clientWidth + 'px',
    height: originalElement.clientHeight + 'px',
    left: elementComputedStyle.right === '0px' ? '' : elementComputedStyle.left,
    right: elementComputedStyle.left === '0px' ? '' : elementComputedStyle.right,
    top: elementComputedStyle.bottom === '0px' ? '' : elementComputedStyle.top,
    bottom: elementComputedStyle.top === '0px' ? '' : elementComputedStyle.bottom,
    margin: elementComputedStyle.margin,
    float: elementComputedStyle.float,
    position: elementComputedStyle.position !== 'static' ? elementComputedStyle.position : ''
  };

  if (!elementComputedStyle.display || elementComputedStyle.display === 'inline') {
    styles.display = 'inline-block';
  } else {
    styles.display = elementComputedStyle.display;
  }

  return styles;
}

function calcPaddingStyles(originalElement, frameRatio) {
  var width = originalElement.clientWidth;
  var height = originalElement.clientHeight;
  var ratio = width / height;

  var left = 0,
      top = 0;

  if (ratio > frameRatio) {
    // X >> n is equivalent to Math.trunc( X / 2^n )
    left = width - height * frameRatio >> 1;
  } else {
    top = height - width / frameRatio >> 1;
  }

  return { padding: top + 'px ' + left + 'px' };
}

module.exports = { calcPositionStyles: calcPositionStyles, calcPaddingStyles: calcPaddingStyles };
