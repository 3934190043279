'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var mol = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/mol');

var adUtils = require('../../utils/ad-utils');

videojs.plugin('vast-setup', function molVastSetup(opts) {
  var player = this;
  var options = utilities.extend({}, opts);
  options.scor = new Date().valueOf(); // Needs to be different for each video player but the same for all the request within the same video player
  var adsCancelTimeout = utilities.isNumber(opts.adsCancelTimeout) ? opts.adsCancelTimeout : 3000;
  var vastAd = player.vastClient({
    getAdTag: getAdTag,
    playAdAlways: true,
    adCancelTimeout: adsCancelTimeout,
    adsEnabled: adUtils.isSupportedBrowser() && opts.adsEnabled,
    vpaidFlashLoaderPath: mol.getVpaidFlashLoaderPath(),
    disableSkipOnVpaid: true
  });

  player.adsEnabled = vastAd.adsEnabled;

  player.on('reset', function () {
    options = utilities.extend({}, options, player.options().plugins['ads-setup']);
    player.adsEnabled = adUtils.isSupportedBrowser() && options.adsEnabled;

    if (player.adsEnabled) {
      vastAd.enable();
    } else {
      vastAd.disable();
    }
  });

  player.on('request-reset', function () {
    player.trigger('vast.reset');
  });

  player.on('playerstate.enter.errored', function () {
    player.trigger('vast.reset');
  });

  /**** Local functions ******/
  function getAdTag(cb) {
    adUtils.buildDFPAdTag(player, options)
    .then(function (adTag) {
      cb(null, adTag);
    });
  }

});
