'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var _require = require('../utils'),
    elementIsVisibleInViewPort = _require.elementIsVisibleInViewPort,
    waitForElementToLoad = _require.waitForElementToLoad,
    calcPositionStyles = _require.calcPositionStyles;

var _require2 = require('../config'),
    elementAttribute = _require2.elementAttribute;

var VideoPreviewBase = function () {
  function VideoPreviewBase(el) {
    _classCallCheck(this, VideoPreviewBase);

    this.element = el;
    this.originalElement = el;
    this.initDone = false;
    this.synchPromise = null;
    this.synchIsAllowed = true;
    this.hasNewSize = true;
    this.showOriginalOnPause = false;
  }

  _createClass(VideoPreviewBase, [{
    key: 'isInViewPort',
    value: function isInViewPort() {
      return elementIsVisibleInViewPort(this.getActiveElement());
    }
  }, {
    key: 'setOriginalElementActive',
    value: function setOriginalElementActive() {
      if (this.getActiveElement() !== this.originalElement) {
        this.showOriginalElement().hideElement();
      }
    }
  }, {
    key: 'setMainElementActive',
    value: function setMainElementActive() {
      if (this.getActiveElement() !== this.element) {
        this.showElement().hideOriginalElement();
      }
    }
  }, {
    key: 'getActiveElement',
    value: function getActiveElement() {
      return this.originalElement.style.display !== 'none' ? this.originalElement : this.element;
    }
  }, {
    key: 'synch',
    value: function synch() {
      var _this = this;

      if (this.synchPromise) {
        return this.synchPromise;
      }

      this.synchPromise = waitForElementToLoad(this.element).catch(function () {}).then(function () {
        if (!_this.synchIsAllowed) {
          throw new Error('Synch is not allowed');
        }

        if (_this.hasNewSize) {
          _this.setOriginalElementActive();
        }

        var activeElement = _this.getActiveElement();
        if (activeElement.clientHeight > 1 && activeElement.clientWidth > 1 && _this.isInViewPort()) {
          return Promise.resolve(_this.initDone ? null : _this._init()).then(function () {
            if (_this.initDone) {
              _this.hasNewSize && _this.fixElement();
              return _this._play();
            }

            return _this._pause();
          }).catch(function () {});
        }

        return _this._pause();
      }).then(this._resetSynchPromise.bind(this)).catch(this._resetSynchPromise.bind(this));

      return Promise.resolve(this.synchPromise);
    }
  }, {
    key: 'synchResize',
    value: function synchResize() {
      this.hasNewSize = true;
      return this.synch();
    }

    /**
     * Fixes element position and dimensions taken from originalElement values
     * @param element
     * @returns {VideoPreviewBase}
     */

  }, {
    key: 'fixElement',
    value: function fixElement() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      if (this.element === this.originalElement) {
        return this;
      }

      Object.assign(element.style, calcPositionStyles(this.originalElement));
      this.hasNewSize = false;
      return this;
    }
  }, {
    key: '_resetSynchPromise',
    value: function _resetSynchPromise() {
      if (this.synchIsAllowed) {
        this.synchPromise = null;
      }
    }
  }, {
    key: '_registerMainElement',
    value: function _registerMainElement() {
      var newElement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.deRegisteredElement;

      this.hideElement(newElement);
      newElement.setAttribute(elementAttribute, this.element.getAttribute(elementAttribute) || '');
      this.element.parentNode && this.element.parentNode.replaceChild(newElement, this.element);
      this.originalElement = this.element;
      this.element = newElement;
      this.element.parentNode && this.element.parentNode.insertBefore(this.originalElement, this.element);
    }
  }, {
    key: '_deRegisterMainElement',
    value: function _deRegisterMainElement() {
      if (this.element !== this.originalElement) {
        this.deRegisteredElement = this.element;
        this.showOriginalElement();
        this.element.parentNode && this.element.parentNode.removeChild(this.element);
        this.element = this.originalElement;
      }
    }
  }, {
    key: 'hideElement',
    value: function hideElement() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      element && element.style && element.style.display !== 'none' && (element.style.display = 'none');
      return this;
    }
  }, {
    key: 'showElement',
    value: function showElement() {
      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.element;

      element && element.style && element.style.display !== '' && (element.style.display = '');
      return this;
    }
  }, {
    key: 'hideOriginalElement',
    value: function hideOriginalElement() {
      return this.hideElement(this.originalElement);
    }
  }, {
    key: 'showOriginalElement',
    value: function showOriginalElement() {
      return this.showElement(this.originalElement);
    }
  }, {
    key: '_init',
    value: function _init() {}
  }, {
    key: '_play',
    value: function _play() {
      this.setMainElementActive();
    }
  }, {
    key: '_pause',
    value: function _pause() {
      if (this.showOriginalOnPause) {
        this.setOriginalElementActive();
      }
    }
  }]);

  return VideoPreviewBase;
}();

module.exports = VideoPreviewBase;
