'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var systemInfo = utils.systemInfo;
var dom = utils.dom;

videojs.plugin('mobile', function mobile() {
  var player = this;

  var el = player.el();

  if (systemInfo.os == 'iOS') {
    dom.addClass(el, 'vjs-idevice');
  }

  if (systemInfo.isIPhone) {
    dom.addClass(el, 'vjs-iphone');
    if (systemInfo.osMajorVersion <= 7) {
      dom.addClass(el, 'vjs-ios-old');
    }
  }

  if (systemInfo.mobile) {
    dom.addClass(el, 'no-volume-slider');
  }
});

/**
 There is a bug on android 4.2 ont the way it parses string
 The code bellow fixes the problem if there is a problem
 */
(function () {
  var parseNum;
  if (parseInt('09') !== 9) {
    parseNum = window.parseInt;
    window.parseInt = function (str) {
      if (typeof str === 'string' && !/^(\s+)?0+(\s+)?$/.test(str)) {
        //We remove the 0 from the left of the number
        return parseNum(str.replace(/^0+/, ''));
      }

      return parseNum(str);
    };
  }
})();
