'use strict';

var SkipVideoButton = videojs.Button.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Button.call(this, player, options);
    this.player = player;
    this._enabled = false;
  }
});

SkipVideoButton.prototype.buttonText = 'Skip';

SkipVideoButton.prototype.buildCSSClass = function () {
  return 'mol-skip-control mol-disabled-control ' + videojs.Button.prototype.buildCSSClass.call(this);
};

SkipVideoButton.prototype.onClick = function () {
  if (this._enabled) {
    this.player.trigger('mol.play.next.video');
  }
};

SkipVideoButton.prototype.name = function () {
  return 'molSkipVideoButton';
};

SkipVideoButton.prototype.enable = function () {
  this.el().classList.remove('mol-disabled-control');
  this._enabled = true;
};

SkipVideoButton.prototype.disable = function () {
  this.el().classList.add('mol-disabled-control');
  this._enabled = false;
};

module.exports = SkipVideoButton;
