'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var VideoPreviewRegister = require('@mol-fe/mol-fe-video-preview/src/scripts/videoPreviewRegister');

/**
 * This plugin initiates animated preview instead of poster image
 */
videojs.plugin('animated-preview', function (config) {
  if (window.location.search && window.location.search.match(/disableVideoPreview=1/i)) {
    return null;
  }

  var player = this;
  player.ready(function () {
    var posterImg = player.posterImage.el_;
    var attrs = Object.keys(config);
    var previousControl = player.controlBar.getChild('molPreviousVideoButton');
    var skipControl = player.controlBar.getChild('molSkipVideoButton');

    for (var i = 0, l = attrs.length; i < l; i++) {
      var attr = attrs[i];
      posterImg.setAttribute(attr, config[attr]);
    }

    var videoPreview = VideoPreviewRegister.addElements(posterImg);

    player.el().classList.add('mol-fe-animated-preview');
    player.options().animatedPreviewEnabled = true;
    previousControl && previousControl.disable();
    skipControl && skipControl.disable();

    player.one('playerstate.enter.contentPlaying', function () {
      previousControl && previousControl.enable();
      skipControl && skipControl.enable();
    });

    utilities.once(player, ['play', 'ima.firstPlay'], function () {
      videoPreview._deRegisterMainElement();
    });
  });

});
