'use strict';
var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var Title = require('./title');
var dom = utils.dom;

videojs.plugin('title', function titlePlugin() {
  var player = this;

  function activateTitle() {
    var options = player.options();

    if (player.titleChild) {
      dom.remove(player.titleChild.el_);
      player.removeChild(player.titleChild);
      player.titleChild.dispose();
      delete player.titleChild;
    }

    if (options.title) {
      player.titleChild = player.addChild(new Title(player, { text: options.title }));
    }
  }

  activateTitle();

  player.on('reset', activateTitle);
});
