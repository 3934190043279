'use strict';

var async = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/async');
var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');

var StateKeeper = require('state-keeper');

videojs.plugin('player-state', function molTrackingSetup() {
  /*jshint camelcase: false*/
  var player = this;
  var ie8;

  /** adding an ie8 class to hide the ads black poster **/
  ie8 = document.addEventListener ? false : true;
  if (ie8) {
    dom.addClass(document.body, 'vjs-ie8');
  }

  var debugOn = (function () {
    return window.location.search.match(/logVideoplayerState/);
  })();

  // State keeper maps transitions from current player state
  // to the next state in response to an event, e.g.
  // {'play': [{from: 'autoplay', to: 'standby'}]}
  // This says:
  //     When event "play" is detected
  //     If current state is "autoplay" transition to "standby"
  var playerState = new StateKeeper(player, {

    'timer:waitingLoading': [
      {
        from: function (st) {
          if (st === 'adsWaiting') {
            if (player.vast.type === 'IMA') {
              return true;
            }

            if (!player.vast.adUnit) {
              return false;
            } else if (player.vast.adUnit.type === 'VAST') {
              return player.currentTime() > 0;
            } else if (player.vast.adUnit.type === 'VPAID') {
              return true;
            } else {
              return false;
            }
          }

          return false;
        },

        to: 'adPlaying'
      },
      {
        from: function (st) {
          return st === 'contentWaiting' && player.currentTime() > 0;
        },

        to: 'contentReady',
        timer: 'startPlaying', timer_time: 1400 // the delay is because it may happen a rogue seeking event
      }
    ],
    'timer:resumeAfterSeek': [
      { from: 'resumeAfterSeek', to: 'contentPlaying' }
    ],
    'vast.adStart, ima.adStart': [
      { from: 'started', to: 'adsWaiting', timer: 'waitingLoading', timer_interval: 10 },
      { from: 'contentWaiting', to: 'adsWaiting', timer: 'waitingLoading', timer_interval: 10 }
    ],
    'vast.adEnd, ima.adEnd': [
      { from: 'adPlaying', to: 'adEnded' },
      { from: 'adPaused', to: 'adEnded' },
      { from: 'adPauseStandby', to: 'adEnded' }
    ],
    playing: [
      { from: 'adEnded', to: 'contentWaiting', timer: 'waitingLoading', timer_interval: 10 }
    ],
    'timer:startPlaying': [
      { from: 'contentReady', to: 'contentPlaying' }
    ],// transition automatically
    'timer:end': [
      {
        from: function (st) {
          var plugins;

          if (st === 'ended') {
            plugins = player.options().plugins || {};

            if (plugins['autoplay-next-video']) {
              async.defer(function () {
                player.trigger('mol.autoplay-start');
              });

              return false;
            }

            return true;
          }

          return false;
        },

        to: 'standby'
      }
    ],
    'timer:error': [
      { from: 'errored', to: 'ended', timer: 'end', timer_time: 600 }
    ],
    'timer:pause': [
      { from: 'contentPaused', to: 'pauseStandby' }
    ],
    'timer:adPause': [
      { from: 'adPaused', to: 'adPauseStandby' }
    ],
    'timer:replay': [
      { from: 'started', to: 'contentWaiting', timer: 'waitingLoading', timer_interval: 10 }
    ],
    'timer:seeking_to_ended': [
      { from: 'contentPlaying', to: 'ended', timer: 'end', timer_time: 600 }
    ],
    'timer:stop_seeking': [
      { from: 'seeking', to: 'contentPlaying' }
    ],
    ended: [
      { from: 'contentPlaying', to: 'ended', timer: 'end', timer_time: 600 },
      { from: 'seeking', to: 'contentPlaying', timer: 'seeking_to_ended', timer_time: 100 },
      { from: 'resumeAfterSeek', to: 'contentPlaying', timer: 'seeking_to_ended', timer_time: 100 }
    ],
    error: [
      {
        from:  function (currentState) {
          return currentState !== 'adsWaiting' && currentState !== 'adPlaying';
        },

        to: 'errored', timer: 'error', timer_time: 3000
      }
    ],
    pause: [
      {
        from: function (s) {
          var perc = this.currentTime() / this.duration();
          return (s == 'contentReady' || s == 'contentPlaying' || s == 'resumeAfterSeek') && (!this.duration() || perc < 0.98);
        },

        to: 'contentPaused',
        timer: 'pause',
        timer_time: 200
      },
      {
        from: function (s) {
          var perc = this.currentTime() / this.duration();
          return s == 'adPlaying' && perc < 0.98;
        },

        to: 'adPaused',
        timer: 'adPause',
        timer_time: 200
      },
      {
        from: 'autoplay',
        to: 'standby'
      }
    ],
    'ima.firstPlay':[
      { from: 'ready', to: 'started' },
      { from: 'standby', to: 'started' },
      {
        from: function (currentState) {
          // because IOS autoplay is a video
          return currentState === 'autoplay' && !player.options().isMobile;
        },

        to: 'started'
      }
    ],
    play: [
      { from: 'ready', to: 'started' },
      { from: 'ended', to: 'started' },
      { from: 'standby', to: 'started' },
      { from: 'pauseStandby', to: 'contentPlaying' },
      { from: 'adPauseStandby', to: 'adPlaying' },
      { from: 'contentPaused', to: 'contentPlaying' }, // android patch
      { from: 'seeking', to: 'resumeAfterSeek', timer: 'resumeAfterSeek', timer_time: 1000 },
      { from: 'autoplay', to: 'standby' }
    ],
    'play-after-seek': [
      { from: 'seeking', to: 'contentPlaying' }
    ],
    'before-seeking, seeking':[
      { from: 'contentReady', to: 'contentPlaying' },
      { from: 'contentPlaying', to: 'seeking', timer: 'stop_seeking', timer_time: 3000 },
      { from: 'contentPaused',  to: 'seeking', timer: 'stop_seeking', timer_time: 3000 }, //dragging the bar it fires the pause event first (on chrome)
      { from: 'seeking', to: 'seeking', timer: 'stop_seeking', timer_time: 3000 }
    ],
    seeked:[
      { from: 'contentReady', to: 'contentPlaying' },// it should not happen (but just in case)
      { from: 'changingSrc', to: 'contentPlaying' }
    ],
    'mol.sources.renditionChanging': [
      { from: 'contentPlaying', to: 'changingSrc' }
    ],
    'vast.adsCancel, ima.adsCancel, ima.adsError': [
      { from: 'standby', to: 'contentWaiting', timer: 'waitingLoading', timer_interval: 10 },
      { from: 'started', to: 'contentWaiting', timer: 'waitingLoading', timer_interval: 10 },
      { from: 'adPlaying', to: 'contentWaiting', timer: 'waitingLoading', timer_interval: 10 }
    ],
    'request-dispose': [
      { from: 'adPlaying', to: 'destroyedDuringAds' },
      { from: /.*/, to: 'destroyed' }
    ],
    'request-reset': [
      { from: 'contentPlaying', to: 'aborting' },
      { from: 'pauseStandby', to: 'aborting' }
    ],
    'autoplay-next-video-cancel, autoplay-inline-cancel': [
        { from: 'autoplay', to: 'standby' }
    ],
    'autoplay-next-video-restart, autoplay-inline-restart': [
        { from: 'standby', to: 'autoplay' }
    ],
    'flyout-close': [
        { from: 'autoplay', to: 'standby' }
    ],
    'mol.autoplay-start':[
        { from: 'ended', to: 'autoplay' }
    ],
    reset: [
      { from: /.*/, to: 'started' }
    ]
  },
  {
    onTransition: function (oldState, currentState, evtName) {
      if (debugOn) console.log('STATE: ' + oldState + ' -> ' + evtName + ' -> ' + currentState + ' (' + Date.now() + ')');
    }
  });

  var triggerBetween = function (min, max) {
    var stateName = (min * 100);
    return function (st) {
      var perc;
      var currentProgress = parseInt(st, 10); // this should pass with '0' or '0-something'
      if (!isNaN(currentProgress) && stateName > currentProgress) {
        if (this.duration() === 0) {
          return false;
        }

        perc = this.currentTime() / this.duration();
        return perc >= min && perc < max;
      }

      return false;
    };
  };

  var progressState = new StateKeeper(player, {
    'playerstate.enter.contentPlaying': [
      { from: 'ready', to: '0' }
    ],
    'timeupdate,contenttimeupdate': [
      { from: triggerBetween(0.25, 1), to: '25' },
      { from: triggerBetween(0.50, 1), to: '50' }
    ],

    'playerstate.enter.seeking': [
      { from: '0',  to: 'seeking-0' },
      { from: '25', to: 'seeking-25' },
      { from: '50', to: 'seeking-50' }
    ],

    'playerstate.leave.seeking': [
      { from: 'seeking-0',  to: '0-resumed' }, // this has to be 'number first' as it will pass the test on the parseInt
      { from: 'seeking-25', to: '25-resumed' },
      { from: 'seeking-50', to: '50-resumed' }
    ],

    // need to go through all remaining states
    'playerstate.enter.ended': [
      { from: /^0.*/, to: '25', timer: 'from25toend' },
      { from: /^25.*/, to: '50', timer: 'from50toend' },
      { from: /^50.*/, to: 'ready' }
    ],

    'timer:from25toend': [
      { from: '25', to: '50', timer: 'from50toend' }
    ],
    'timer:from50toend': [
      { from: '50', to: 'ready' }
    ],

    reset: [{ from: /.*/, to: 'ready' }]
  },
  {
    onTransition: function (oldState, currentState, evtName) {
      if (debugOn) console.log('PROGRESS: ' + oldState + ' -> ' + evtName + ' -> ' + currentState);
    }
  });

  playerState.on(function (evt) {
    //the player is disposed
    if (player.el() === null) {
      return;
    }

    player.trigger('playerstate.' + evt.type + '.' + evt.state);
  });

  progressState.on(function (evt) {
    //the player is disposed
    if (player.el() === null) {
      return;
    }

    player.trigger('playerstate.progress.' + evt.type + '.' + evt.state);
  });

  playerState.on('destroyedDuringAds', function () {
    //the player is disposed
    if (player.el() === null) {
      return;
    }

    playerState.destroy();
    progressState.destroy();
  });

  playerState.on('destroyed', function () {
    //the player is disposed
    if (player.el() === null) {
      return;
    }

    playerState.destroy();
    progressState.destroy();
  });

  player._states = {
    state: playerState,
    progress: progressState
  };

  player.playerState = {
    getStateName: function () {
      return playerState.get();
    }
  };
});
