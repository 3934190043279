'use strict';

var historyStorage = require('./history-storage');

videojs.plugin('history', function molHistory() {
  var player = this;

  player.on('playerstate.progress.enter.25', function () {
    // Add video to history if user watched more than 25%
    var referenceId = player.options().referenceId;
    historyStorage.push(referenceId);
  });
});
