'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');

function Playlist(player, opts) {
  var options = utilities.extend({}, opts);

  this._playlistHistory = [];
  this._player = player;
  this._updatePlayerState();
  this._maxHistorySize = options.maxHistorySize || 0;
}

Playlist.prototype.add = function (video) {
  this._playlistHistory.push(video);

  if ((this._maxHistorySize !== 0) && (this._playlistHistory.length > this._maxHistorySize)) {
    this._playlistHistory.shift();
  }

  this._updatePlayerState();
};

Playlist.prototype.pop = function () {
  var removedVideo = this._playlistHistory.pop();
  this._updatePlayerState();
  return removedVideo;
};

Playlist.prototype.last = function () {
  return this._playlistHistory[this._playlistHistory.length - 1];
};

Playlist.prototype.isEmpty = function () {
  return !!!this._playlistHistory.length;
};

Playlist.prototype.contains = function (video) {
  return this._playlistHistory.some(function (playedVideo) {
    return playedVideo.referenceId === video.referenceId;
  });
};

Playlist.prototype._updatePlayerState = function () {
  this._player.el().classList.toggle('mol-empty-playlist', this.isEmpty());
};

module.exports = Playlist;
