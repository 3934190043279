'use strict';

var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');

videojs.plugin('post-content', function postContent(opts) {
  var player = this;

  if (!player.options().isMobile) {

    var afterVideoEl = document.getElementById(opts.id);
    var afterVideoElParent;
    var n;
    var tags;
    var afterVideoFormEl;
    var thankYouEl;
    var voted = false;

    if (afterVideoEl) {
      afterVideoElParent = afterVideoEl.parentNode;
    }

    /** If there is a post-video element on the page **/
    if (afterVideoEl && opts.id && afterVideoElParent) {
      afterVideoFormEl = document.querySelector('#' + opts.id + ' form');
      thankYouEl = document.createElement('p');
      thankYouEl.setAttribute('id', 'video-poll-feedback');
      thankYouEl.className = 'poll-question poll-question-feedback';
      thankYouEl.innerHTML = 'Thanks for the feedback';

      tags = afterVideoElParent.getElementsByTagName(opts.onCloseTriggerTag);
      n = tags.length;

      for (var i = 0; i < n; i++) {
        //add this class to only the first element
        if (i === 0) {
          dom.addClass(tags[i], 'answer-label-first');
        }

        dom.addEventListener(tags[i], 'click', function () {
          voted = true;

          //hide the form
          dom.addClass(afterVideoFormEl, 'vjs-hidden');

          //change text
          dom.prependChild(afterVideoEl, thankYouEl);

          //reset poll to original state
          setTimeout(function () {
            dom.removeClass(afterVideoElParent, 'vjs-shown');
            afterVideoEl.removeChild(thankYouEl);
            dom.removeClass(afterVideoFormEl, 'vjs-hidden');
          }, 3000);
        });
      }

      player.on('ended', function () {
        //removing some injected inline-styles from general polls handler;
        if (!voted) {
          setTimeout(function () {
            afterVideoFormEl.removeAttribute('style');
            dom.addClass(afterVideoElParent, 'vjs-shown');
          }, 500);

        }
      });

      player.on('destroyed', function () {
        dom.removeClass(afterVideoElParent, 'vjs-shown');
      });
    }
  }
});
