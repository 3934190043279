'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VideoPreviewImgPlayBase = require('./base/VideoPreviewImgPlayBase');

var VideoPreviewImgFadePlay = function (_VideoPreviewImgPlayB) {
  _inherits(VideoPreviewImgFadePlay, _VideoPreviewImgPlayB);

  function VideoPreviewImgFadePlay(videoPreviewImg) {
    _classCallCheck(this, VideoPreviewImgFadePlay);

    var _this = _possibleConstructorReturn(this, (VideoPreviewImgFadePlay.__proto__ || Object.getPrototypeOf(VideoPreviewImgFadePlay)).call(this, videoPreviewImg));

    var dataFade = parseFloat(_this.videoPreviewImg.element.getAttribute('data-fade'));
    _this.fadeDuration = dataFade || 1 / _this.videoPreviewImg.fps;
    _this._frameHoldersCount = 3;
    _this.fadeTiming = _this.videoPreviewImg.element.getAttribute('data-fade-timing') || 'ease-in-out';
    return _this;
  }

  _createClass(VideoPreviewImgFadePlay, [{
    key: '_createFrameHolder',
    value: function _createFrameHolder() {
      var frameHolder = _get(VideoPreviewImgFadePlay.prototype.__proto__ || Object.getPrototypeOf(VideoPreviewImgFadePlay.prototype), '_createFrameHolder', this).call(this);
      Object.assign(frameHolder.style, {
        'transition-duration': this.fadeDuration + 's',
        'transition-timing-function': this.fadeTiming
      });
      return frameHolder;
    }
  }, {
    key: 'nextFrame',
    value: function nextFrame(frameNum) {
      _get(VideoPreviewImgFadePlay.prototype.__proto__ || Object.getPrototypeOf(VideoPreviewImgFadePlay.prototype), 'nextFrame', this).call(this, frameNum);
      this._frameHolders.push(this._frameHolders.shift());
      this._frameHolders[0].className = this.frameHolderClassName;
      this._frameHolders[1].className = this.frameHolderClassName + ' frame-holder-current';
      this.setFrameHolderNext(this._frameHolders[2]);
    }
  }]);

  return VideoPreviewImgFadePlay;
}(VideoPreviewImgPlayBase);

module.exports = VideoPreviewImgFadePlay;
