'use strict';

/* Video Quality Menu Item - To select the allowed video qualities.
 ================================================================================ */
var VideoQualityItem = videojs.MenuItem.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.MenuItem.call(this, player, options);
    this.width = options.width;
    this.height = options.height;
    this.renditions = options.renditions || [];
  }
});

VideoQualityItem.prototype.createEl = function (type, props) {
  var label = this.options_.height + 'p';
  return videojs.Button.prototype.createEl.call(this, 'div', videojs.obj.merge({
    className: 'vjs-menu-item',
    innerHTML: label
  }, props));
};

VideoQualityItem.prototype.onClick = function () {
  var player = this.player();
  if (player.changeRendition) {
    player.changeRendition(this.renditions);
  }
};

VideoQualityItem.prototype.addRendition = function (rendition) {
  this.renditions.push(rendition);
};

module.exports = VideoQualityItem;
