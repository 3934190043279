'use strict';

var InteractiveSocialButton = require('../base/interactive-social-button');
var LinkPopup = require('./link-popup');

var LinkButton = InteractiveSocialButton.extend();
LinkButton.prototype.buttonName = 'LinkButton';

LinkButton.prototype.getPopup = function () {
  return new LinkPopup(this.player_);
};

module.exports = LinkButton;
