'use strict';

var async = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/async');

videojs.plugin('reset', function resetPluginSetup() {
  var player = this;

  player.on('request-reset', function (evt) {
    var video = evt.video,
        autoplay = evt.autoplay;

    //give the player tracking time to send abort event with old data;
    async.defer(function () {
      player.trigger({ type: 'reset-sources', video: video, autoplay: autoplay });
    });
  });

  player.on('reset-sources', function (evt) {
    var video = evt.video,
        autoplay = evt.autoplay;

    if (!video.src) {

      if (console && console.log) {
        console.log('reset-resources request ignored. The passed video has no sources', video);
      }

      return;
    }

    player.playingRecommendedVideo = true;
    player.autoplay(false);
    player.pause();
    player.src(video.src);
    player.poster(video.poster);
    player.options(video);
    player.playlistClickToPlay = !!video.playlistClickToPlay;

    player.options(sanitiseOpts(video.options || {}));

    if (video.unmute) {
      player.muted(false);
    }

    if (video.options && video.options.plugins && video.options.plugins.tracking && !video.options.plugins.tracking.dmtvEpisode) {
      // Needs to be reset
      player.options().plugins.tracking.dmtvEpisode = null;
    }

    notifyReset();

    player.on('reset', function () {
      if (autoplay) {
        //We need to execute this asynchronously to give time to the other plugins to reset.
        async.defer(function () {
          player.play();
        });
      }
    });
  });

  player.resetVideo = function (opts) {
    player.trigger({ type:'request-reset', video: opts.video, autoplay: opts.autoplay });
  };

  /*** local functions ***/
  function sanitiseOpts(opts) {
    // The platform is not going to change when we reset and we need to ensure that it stays the same after the reset
    delete opts.isMobile;
    return opts;
  }

  function notifyReset() {
    async.defer(function () {
      player.trigger('reset');
    });
  }
});
