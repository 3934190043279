'use strict';

function DFPTagBuilderError(message) {
  this.message = 'DFPTagBuilder Error: ' + (message || '');
}

DFPTagBuilderError.prototype = new Error();
DFPTagBuilderError.prototype.name = 'DFPTagBuilder Error';

module.exports = DFPTagBuilderError;
