'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var NODE_TYPE_ELEMENT = 1;

var Type = function () {
  function Type() {
    _classCallCheck(this, Type);
  }

  _createClass(Type, null, [{
    key: 'isNull',
    value: function isNull(o) {
      return o === null;
    }
  }, {
    key: 'isDefined',
    value: function isDefined(o) {
      return o !== undefined;
    }
  }, {
    key: 'isUndefined',
    value: function isUndefined(o) {
      return o === undefined;
    }
  }, {
    key: 'isObject',
    value: function isObject(obj) {
      return (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object';
    }
  }, {
    key: 'isFunction',
    value: function isFunction(str) {
      return typeof str === 'function';
    }
  }, {
    key: 'isNumber',
    value: function isNumber(num) {
      return typeof num === 'number';
    }
  }, {
    key: 'isArray',
    value: function isArray(array) {
      return Object.prototype.toString.call(array) === '[object Array]';
    }
  }, {
    key: 'isString',
    value: function isString(str) {
      return typeof str === 'string';
    }
  }, {
    key: 'isWindow',
    value: function isWindow(obj) {
      return Type.isObject(obj) && obj.window === obj;
    }
  }, {
    key: 'isArrayLike',
    value: function isArrayLike(obj) {
      if (obj === null || Type.isWindow(obj) || Type.isFunction(obj) || Type.isUndefined(obj)) {
        return false;
      }

      var length = obj.length;

      if (obj.nodeType === NODE_TYPE_ELEMENT && length) {
        return true;
      }

      return Type.isString(obj) || Type.isArray(obj) || length === 0 || typeof length === 'number' && length > 0 && length - 1 in obj;
    }
  }]);

  return Type;
}();

exports.default = Type;
