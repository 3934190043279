'use strict';

// Close Button - Circular close button that closes the popup when you click on it.
var CloseButton = videojs.Button.extend();

CloseButton.prototype.onClick = function () {
  this.player_.trigger('CloseButton.pressed');
};

CloseButton.prototype.createEl = function () {
  return videojs.Button.prototype.createEl.call(this, 'div', {
    className: 'vjs-close-button vjs-control',
    innerHTML: '×'  // alternative ✖ ⨯ ×
  });
};

module.exports = CloseButton;
