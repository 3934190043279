'use strict';

var dom = require('@mol-fe/mol-fe-videoplayer-utils').dom;

var Title = videojs.Component.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Component.call(this, player, options);
    var playerEl = player.el_;
    var titleEl = this.el_;

    //The set timeout is needed so that the titleEl have been added to the dom.
    setTimeout(function () {
      dom.prependChild(playerEl, titleEl);
    }, 0);
  }
});

Title.prototype.createEl = function () {
  var title = '<div class="vjs-title-text"><div>' + this.options().text + '</div></div>';
  var player = this.player_;
  var source = player.options().source || '';
  var isDMTVVideo = source.toUpperCase() === 'DAILYMAILTV';
  var className = isDMTVVideo ? 'vjs-title vjs-title-dmtv' : 'vjs-title';
  var innerHTML = isDMTVVideo ? '<div class="dmtv"></div>' + title : title;

  //for one-col, truncate title to fit within 2 lines max
  if (player.el_.parentNode && dom.hasClass(player.el_.parentNode, 'vjs-span-one-col')) {
    _formatOneColContainer(title);
  }

  return videojs.createEl('div', {
    className: className,
    innerHTML: innerHTML
  });

  /** local functions **/
  function _formatOneColContainer() {
    dom.addClass(player.el_.parentNode, 'vjs-one-col-title');
  }
};

module.exports = Title;
