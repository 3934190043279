'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var mol = utils.mol;
var dom = utils.dom;
var systemInfo = utils.systemInfo;

// chrome identifies a small flash object as a an Ad and doesnt autoplay it;
// this *****hack***** solves that issue
function chromeOneColFix() {
  var swfEl = _createSwfObject(
      mol.getZeroClipboardSWFPath(),
      {
        id: 'streaming-empty-object-chrome-bug-fix',
        class: 'myclass',
        width: 500,
        height: 400
      },
      {
        allowScriptAccess: 'always',
        wmode: 'opaque',
        allowNetworking: 'all'
      });

  if (systemInfo.os !== 'iOS' &&
      systemInfo.os !== 'Android') {
    window.addEventListener('load', _hideSwfEl);
  }

  /**** Local functions ***/
  function _createSwfObject(src, attributes, parameters) {
    var i,
        html,
        div,
        obj,
        attr = attributes || {},
        param = parameters || {};

    attr.type = 'application/x-shockwave-flash';

    if (window.ActiveXObject) {
      attr.classid = 'clsid:d27cdb6e-ae6d-11cf-96b8-444553540000';
      param.movie = src;
    } else {
      attr.data = src;
    }

    html = '<object';

    for (i in attr) {
      html += ' ' + i + '="' + attr[i] + '"';
    }

    html += '>';

    for (i in param) {
      html += '<param name="' + i + '" value="' + param[i] + '" />';
    }

    html += '</object>';
    div = document.createElement('div');
    div.innerHTML = html;
    obj = div.firstChild;
    div.removeChild(obj);

    return obj;
  }

  function _hideSwfEl() {
    document.body.appendChild(swfEl);
    setTimeout(function () {
      dom.hide(swfEl);
    }, 2000);
  }
}

if (systemInfo.browser === 'Chrome') {
  chromeOneColFix();
}
