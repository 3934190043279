'use strict';

function getConfig(name) {
  return window.adsTrackers &&
    window.adsTrackers.isPluginConfiguredOn(name);
}

module.exports = {
  getConfig: function () {
    var config = {};

    if (getConfig('vpaid')) {
      config.vpaidEnabled = true;
    }

    if (getConfig('imaSDK')) {
      config.useIMASDK = true;
    }

    return config;
  }
};
