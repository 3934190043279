'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var utilities = utils.utilityFunctions;
var mol = utils.mol;

// non commonjs module just set a gobal ZeroClipboard entity
var ZeroClipboard = require('zeroclipboard/dist/ZeroClipboard.js');

// setting the url for the swf
ZeroClipboard.config({ swfPath: mol.getZeroClipboardSWFPath() });

// Copy link Button - It wil copy the video link when you press on it.

var CopyButton = videojs.Button.extend({
  /** @Constructor */
  init: function (player, options) {
    videojs.Button.call(this, player, options);

    var client = new ZeroClipboard(this.el_);
    var _this = this;

    client.on('ready', function () {
      var text = _this.player_.el_.querySelector('.copyToClipboardEl').value;
      client.on('copy', function (event) {
        var clipboard = event.clipboardData;
        clipboard.setData('text/plain', text);
      });
    });
  }
});

CopyButton.prototype.options_ = {
  actionLabel: 'Copy'
};

CopyButton.prototype.createEl = function () {

  return videojs.Button.prototype.createEl.call(this, 'button', {
    className: 'vjs-copy-link-button vjs-popup-button vjs-popup-principal-button',
    innerHTML: this.options().actionLabel
  });
};

CopyButton.browserHasFlash = function browserHasFlash() {
  var hasFlash = false;
  try {
    var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
    if (fo) hasFlash = true;
  } catch (e) {
    if (navigator.mimeTypes ['application/x-shockwave-flash'] !== undefined) hasFlash = true;
  }

  return hasFlash;
};

CopyButton.isCopyToClipboardSupported = function isCopyToClipboardSupported() {
  // I am checking addEventListener because IE8 is not supported by ZeroClipboard
  return document.addEventListener && CopyButton.browserHasFlash() && utilities.isDefined(ZeroClipboard);
};

module.exports = CopyButton;
