'use strict';

var dom = require('@mol-fe/mol-fe-videoplayer-utils').dom;

/* Loading Spinner
================================================================================ */
/**
 * Loading spinner for waiting events
 * @param {videojs.Player|Object} player
 * @param {Object=} options
 * @class
 * @constructor
 */
var LoadingSpinner = videojs.Component.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Component.call(this, player, options);
  }
});

LoadingSpinner.prototype.createEl = function () {
  var spinner = videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-loading-spinner'
  });
  var spinnerIcon = document.createElement('div');

  dom.addClass(spinnerIcon, 'vjs-loading-spinner-icon');
  spinner.appendChild(spinnerIcon);
  return spinner;
};

module.exports = LoadingSpinner;
