'use strict';

var systemInfo = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/systemInfo');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var isElementInViewPort = require('./isElementInViewPort');

var createEvent = function (eventName) {
  var evt = document.createEvent('Event');
  evt.initEvent(eventName, true, true);

  return evt;
};

var checkedItems = [];

var checkElement = function (element) {
  checkedItems.push({
    element: element,
    inViewport: false
  });

  window.addEventListener('DOMContentLoaded', checkPosition, false);
  window.addEventListener('load', checkPosition, false);
  var scrollableContent = window;
  if (systemInfo.mobile) {
    scrollableContent = document.querySelector('.scrollable-content') || window;
  }

  scrollableContent.addEventListener('scroll', checkPosition, false);
  window.addEventListener('resize', checkPosition, false);
  document.addEventListener('visibilitychange', checkPosition, false);
};

var checkPosition = utilities.debounce(function () {
  checkedItems.forEach(function (item) {
    var element = item.element;

    // actual check is done async to avoid performance penalty
    setTimeout(function () {
      var elementInViewPort = isElementInViewPort(element) && !document.hidden;

      if (elementInViewPort && !item.inViewport) {
        item.inViewport = true;
        element.dispatchEvent(createEvent('viewport.enter'));
      } else if (!elementInViewPort && item.inViewport) {
        item.inViewport = false;
        element.dispatchEvent(createEvent('viewport.exit'));
      }
    }, 0);
  });
}, 100);

module.exports = {
  check: checkElement,
  checkedElements: checkedItems
};
