'use strict';

var FlyoutMinimize = videojs.Button.extend({});
FlyoutMinimize.prototype.createEl = function () {
  return videojs.createEl('span', {
    className: 'vjs-flyout-minimize vjs-flyout-button',
    innerHTML: 'Minimize'
  });
};

FlyoutMinimize.prototype.onClick = function () {
  this.player().trigger('flyout-minimize');
};

module.exports = FlyoutMinimize;
