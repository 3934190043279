'use strict';

var systemInfo = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/systemInfo');

videojs.plugin('autoplay-next-video', function () {
  var player = this;

  if (player.autoplayIndex === undefined) {
    player.autoplayIndex = 0;
  }

  if (systemInfo.mobile) {
    player.el().querySelector('.vjs-tech').setAttribute('playsinline', '');
  }

  player.on('playerstate.enter.autoplay', function () {
    var videoElement = player.el().getElementsByTagName('video')[0];

    // Targets webkit browsers that open video in full screen mode (ios6, ios7).
    // Exits the full screen mode.
    if (videoElement && videoElement.webkitExitFullScreen) {
      videoElement.webkitExitFullScreen();
    }
  });

  player.on('playerstate.enter.autoplay', autoplayNextVideo.bind(this));

  player.on('playerstate.leave.autoplay', function () {
    player.autoplayIndex += 1;
  });

  function autoplayNextVideo() {
    player.fetchRelatedVideos(function (error, relatedVideoList) {
      var video = relatedVideoList[0];

      player.trigger({
        type:'request-reset',
        video: {
          src: video.src,
          poster: video.poster,
          options: video
        },
        autoplay: true
      });
    });
  }
});
