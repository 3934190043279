'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dom = require('../util/dom');

var _dom2 = _interopRequireDefault(_dom);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ControlVolume = function () {
  function ControlVolume(videoElement) {
    _classCallCheck(this, ControlVolume);

    this.ui = {};
    this.videoElement = videoElement;
    this.el = createVolumeControl.call(this);
    this.updateVideoState();
  }

  _createClass(ControlVolume, [{
    key: 'setVolume',
    value: function setVolume(volume, mute) {
      if (mute !== undefined) {
        this.muted = mute;

        this.trigger('ima.volume', { volume: this.volume });
        this.trigger(mute ? 'ima.muted' : 'ima.non-muted');
      } else {
        var prevVolume = this.volume;

        this.volume = volume;
        this.trigger('ima.volume', { volume: volume });

        if (prevVolume !== volume) {
          if (volume === 0) {
            this.trigger('ima.muted');
          } else if (prevVolume === 0) {
            this.trigger('ima.non-muted');
          } else if (this.muted && volume > 0) {
            this.muted = false;
            this.trigger('ima.non-muted');
          }
        }
      }

      updateVolumeUI.call(this);
    }
  }, {
    key: 'getVolume',
    value: function getVolume() {
      return this.muted ? 0 : this.volume;
    }
  }, {
    key: 'trigger',
    value: function trigger(evtName, opts) {
      _dom2.default.trigger(this.el, evtName, opts);
    }
  }, {
    key: 'onAdMuteClick',
    value: function onAdMuteClick() {
      this.setVolume(this.videoElement.volume, !this.videoElement.muted);
    }
  }, {
    key: 'onVolumeTrackDown',
    value: function onVolumeTrackDown(e) {
      var _this = this;
      var volumeTrack = this.ui.volumeTrack;
      var volumeDiv = this.ui.volumeDiv;
      var eventsMove = ['pointermove', 'touchmove', 'MSPointerMove', 'mousemove'],
          eventsUp = ['pointerup', 'touchstop', 'MSPointerUp', 'mouseup'];

      toggleDrag(true);
      onMove(e);

      function onUp() {
        toggleDrag(false);
      }

      function toggleDrag(toDrag) {
        _dom2.default.toggleClass(volumeDiv, 'ima-volume-interact', toDrag);
        _dom2.default.toggleEventListener(window, eventsUp, onUp, toDrag);
        _dom2.default.toggleEventListener(volumeDiv, eventsMove, onMove, toDrag);
      }

      function onMove(e) {
        var bounding = _dom2.default.getPositionOfElement(volumeTrack);
        var posTop = (bounding.height - (e.clientY - bounding.top)) / bounding.height;
        posTop = Math.min(1, Math.max(0, posTop));
        _this.setVolume(posTop);
      }
    }
  }, {
    key: 'updateVideoState',
    value: function updateVideoState() {
      this.volume = this.videoElement.volume;
      this.muted = this.videoElement.muted;
      updateVolumeUI.call(this);
    }
  }]);

  return ControlVolume;
}();

exports.default = ControlVolume;


function updateVolumeUI() {
  var _this2 = this;

  var volume = this.muted ? 0 : this.volume;
  this.ui.handler.style.bottom = volume * 100 + '%';
  this.ui.volumeProgress.style.height = volume * 100 + '%';

  [{ cssClass: 'ima-sound--loud', volumeMin: 0.64, volumeMax: 1 }, { cssClass: 'ima-sound--medium', volumeMin: 0.33, volumeMax: 0.64 }, { cssClass: 'ima-sound--low', volumeMin: 0, volumeMax: 0.33 }, { cssClass: 'ima-sound--muted', volumeMin: -1, volumeMax: 0 }].forEach(function (soundConfig) {
    var show = volume <= soundConfig.volumeMax && volume > soundConfig.volumeMin;
    _dom2.default.toggleClass(_this2.ui.muteControl, soundConfig.cssClass, show);
  });
}

function createVolumeControl() {
  this.ui.volumeDiv = _dom2.default.createElementWithClasses(['ima-control', 'ima-volume-div']);

  _dom2.default.appendChildren(this.ui.volumeDiv, [createVolumeSlider.call(this), createMuteControl.call(this)]);

  return this.ui.volumeDiv;
}

function createMuteControl() {
  this.ui.muteControl = _dom2.default.createElementWithClasses(['ima-control', 'ima-sound']);

  _dom2.default.click(this.ui.muteControl, this.onAdMuteClick.bind(this));

  return this.ui.muteControl;
}

function createVolumeSlider() {
  this.ui.volumeSlider = _dom2.default.createElementWithClasses(['ima-volume-slider']);
  this.ui.volumeProgress = _dom2.default.createElementWithClasses(['ima-volume-progress']);
  this.ui.volumeTrack = _dom2.default.createElementWithClasses(['ima-volume-track']);
  this.ui.handler = _dom2.default.createElementWithClasses(['ima-volume-handler']);

  _dom2.default.appendChildren(this.ui.volumeTrack, [this.ui.volumeProgress, this.ui.handler]);

  this.ui.volumeSlider.appendChild(this.ui.volumeTrack);

  _dom2.default.addEventListener(this.ui.volumeTrack, ['pointerdown', 'touchstart', 'MSPointerDown', 'mousedown'], this.onVolumeTrackDown.bind(this));

  return this.ui.volumeSlider;
}
