'use strict';

var CancelButton = require('../base/cancel-button');
var CopyButton = require('../base/copy-button');
var PopupButtons = require('../base/popup-buttons');

// Embed Video Popup Content - it will contain the content of the popup

var EmbedPopupContent = videojs.Component.extend({
  /** @Contructor */
  init: function (player) {
    videojs.Component.call(this, player);
    if (CopyButton.isCopyToClipboardSupported()) {
      var buttons = new PopupButtons(player);

      buttons.addChild(new CancelButton(player));
      buttons.addChild(new CopyButton(player, { actionLabel: 'Copy Code' }));

      this.addChild(buttons);
    }
  }
});

EmbedPopupContent.prototype.createEl = function () {
  var player = this.player();
  var embed = player.socials && player.socials.embedSnippet && player.socials.embedSnippet();
  if (!embed) {
    embed = 'This video can\'t support the embed';
  }

  var innerHTML = '<div class="clearfix"></div>' +
    '<div class="vjs-popup-title">' +
    'Embed this Video' +
    '</div>' +
    '<div class="vjs-popup-content">' +
    '<textarea class="copyToClipboardEl" readonly rows="3">' +
    embed +
    '</textarea>' +
    '</div>';

  if (!CopyButton.isCopyToClipboardSupported()) {
    innerHTML += '<div class="vjs-popup-helper-msg">' +
    'Please copy the text above' +
    '</div>';
  }

  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-embed-popup-content vjs-popup-wrapper',
    innerHTML: innerHTML
  });
};

module.exports = EmbedPopupContent;
