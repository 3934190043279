'use strict';

var urlUtils = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/urlUtils');
var isElementInViewPort = require('../utils/isElementInViewPort');
var persistFn = require('../utils/persistFn');

// this code initialize any video in a modal; whether built dynamically or inPage (set in the options)
DM.later('bundle', function () {

  document.addEventListener('ez-modal-after-load', function (evt) {
    var el = evt.ui.modal.get(0);
    if (el) {
      DM.molFeVideoplayer.init(el);
    }
  });

  document.addEventListener('ez-modal-after-open', function (evt) {
    var el = evt.ui.modal.get(0);
    var opt = evt.opt;
    if (opt.modalType && opt.modalType === 'video-overlay') {
      DM.molFeVideoplayer.forEachPlayer(el, function () {
        if (this.options().inPage) {
          this.play();
        }
      });
    }
  });

  document.addEventListener('ez-modal-before-close', function (evt) {
    var el = evt.ui.modal.get(0);
    var opt = evt.opt;
    var container;

    if (opt.modalType && opt.modalType === 'video-overlay') {
      DM.molFeVideoplayer.forEachPlayer(el, function () {
        this.pause();
      });
    } else if (el) {
      DM.molFeVideoplayer.destroy(el);
      container = el.querySelector('.vjs-video-container');

      if (container) {
        container.removeAttribute('id'); // remove conflicting id
      }
    }
  });

  var getJSON = function (url, callback) {
    var request = new XMLHttpRequest();

    function handleResponse() {
      callback(JSON.parse(request.responseText));
    }

    request.onload = handleResponse;
    request.open('GET', url, true);
    request.send();
  };

  // OPEN VIDEO INTEGRATION
  document.addEventListener('click', function (evt) {
    var withAttr = evt.target.closest('[data-videoplayer-open-playerid]');

    if (!withAttr)  {
      return;
    }

    var opts = withAttr.getAttribute('data-videoplayer-open-opts');
    var newvideo;

    evt.preventDefault();
    var id = withAttr.getAttribute('data-videoplayer-open-playerid');
    var player = getVideoPlayerFromId(id, withAttr);
    if (!player || typeof opts !== 'string') return;

    try {
      newvideo = JSON.parse(opts);
    }
    catch (e) {
      // opts is probably a URL
      getJSON(urlUtils.appendGeolocation(opts), changeVideo);
      return false;
    }

    // opts was a deserializable object
    changeVideo(newvideo);

    return false;

    function changeVideo(video) {
      player.resetVideo({
        video: {
          src: video.src,
          poster: video.poster,
          title: video.title,
          initialVideo: false,
          options: video
        },
        autoplay: true
      });
    }
  });

  // PLAYLIST INTEGRATION
  document.addEventListener('click', function (evt) {
    var withAttr = evt.target.closest('[data-videoplayer-playlist-playerid]');

    if (!withAttr)  {
      return;
    }

    evt.preventDefault();
    var id = withAttr.getAttribute('data-videoplayer-playlist-playerid');
    var player = getVideoPlayerFromId(id, withAttr);
    if (!player) return;

    var playlistNumber = withAttr.getAttribute('data-videoplayer-playlist-number');

    if (playlistNumber === 'next') {
      player.next();
    } else if (playlistNumber === 'previous') {
      player.previous();
    } else {
      player.gotoVideo(parseInt(playlistNumber));
    }

    return false;
  });

  // MOL-GALLERY INTEGRATION
  var galleryModal = document.querySelector('#mobileGalleryModal');

  if (galleryModal) {
    galleryModal.addEventListener('open', function () {
      DM.molFeVideoplayer.trigger('pauseAll', {});
    });
  }

});

var getVideoPlayerFromId = function (id, closeTo) {
  var player;
  var selector = '#' + id + ' [data-opts]';
  var nodes = document.querySelectorAll(selector);

  if (!nodes.length) return;

  if (nodes.length > 1) {
    // search the closest one
    while ((closeTo = closeTo.parentNode)) {
      nodes = closeTo.querySelectorAll(selector);
      if (nodes.length > 0) break;
    }
  }

  // get the player form the node
  var playerFromNode = nodes[0].querySelector('[data-opts]');

  player = (playerFromNode && playerFromNode.player) || closeTo.querySelector(selector).player;

  return player;
};

DM.later('DOM_READY', function () {
  try {
    if (!window.PageCriteria.isMobile && DM.getPageMetadata().articleId && /\#video(\?.*)?$/.test(window.location.hash)) {
      var videoElementToPlay = document.querySelector('#video,[name="video"]').parentNode.querySelector('video');
      var testFn = function () {
        return isElementInViewPort(videoElementToPlay);
      };

      var autoplayFn = function () {
        if (videoElementToPlay.player) {
          videoElementToPlay.player.play();
        } else {
          videoElementToPlay.autoplay = true;
        }
      };

      var persistedAutoplay = persistFn(testFn, autoplayFn);

      persistedAutoplay(10, 500);
    }
  } catch (err) {}
});
