'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dom = require('../util/dom');

var _dom2 = _interopRequireDefault(_dom);

var _logger = require('../util/logger');

var _ima = require('./ima');

var _ima2 = _interopRequireDefault(_ima);

var _imaControlBar = require('./imaControlBar');

var _imaControlBar2 = _interopRequireDefault(_imaControlBar);

var _initMoatTracking = require('../moat/initMoatTracking');

var _initMoatTracking2 = _interopRequireDefault(_initMoatTracking);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ImaClient = function () {
  function ImaClient(videoEl, adContainerDiv, settings) {
    var _this = this;

    _classCallCheck(this, ImaClient);

    sanityCheck(videoEl, adContainerDiv);

    initAdContainerDiv(adContainerDiv);

    // we not using the promise but a flag because in mobile breaks the touch event
    this._isLoaded = false;
    this.videoEl = videoEl;
    this._settings = settings;
    this._isFullscreen = false;
    this.adContainerDiv = adContainerDiv;
    this.controlBar = new _imaControlBar2.default(adContainerDiv, videoEl);

    // bind event listeners
    this.resizeAd = this.resizeAd.bind(this);
    this.unregisterResizeAd = this.toogleResize.bind(this, false);
    this.init();

    if (this.imaPromise) {
      this.imaPromise = this.imaPromise.then(function () {
        if (window.google) {
          _this.adDisplayContainer = new window.google.ima.AdDisplayContainer(_this.adContainerDiv, _this.videoEl);
          _this.adsLoader = createAdsLoader.call(_this, _this.adDisplayContainer);
        }
      });
    }

    /*** Local functions ***/
    function sanityCheck(videoEl, adContainerDiv) {
      if (!isVideoElement(videoEl)) {
        throw new Error('Ima Class Error: missing required video element on constructor');
      }

      if (!isDivElement(adContainerDiv)) {
        throw new Error('Ima Class Error: missing required adContainer div element on constructor');
      }
    }

    function isVideoElement(el) {
      return el && el.nodeName && el.nodeName.toUpperCase() === 'VIDEO';
    }

    function isDivElement(el) {
      return el && el.nodeName && el.nodeName.toUpperCase() === 'DIV';
    }

    function initAdContainerDiv(adContainerDiv) {
      var spinnerDiv = document.createElement('div');
      _dom2.default.addClass(spinnerDiv, 'ima-loading-spinner');

      _dom2.default.addClass(adContainerDiv, 'ima-ad-container');
      _dom2.default.prependChild(adContainerDiv, spinnerDiv);
    }

    /**
     AdsLoader allows clients to request ads from ad servers. To do so, users must register for the AdsManagerLoadedEvent
     event and then request ads.
     */
    function createAdsLoader(adContainer) {
      var adsLoader = new window.google.ima.AdsLoader(adContainer);

      adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded.bind(this), false);

      adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdErrorEvent.bind(this), false);

      //Configure adsLoader
      var settings = adsLoader.getSettings();

      settings.setLocale('en');
      if (videojs) {
        settings.setPlayerType('videojs');
        settings.setPlayerVersion(videojs.version);
      }

      if (this._settings.vpaidEnabled) {
        settings.setVpaidMode(window.google.ima.ImaSdkSettings.VpaidMode.INSECURE);
      } else {
        settings.setVpaidMode(window.google.ima.ImaSdkSettings.VpaidMode.DISABLED);
      }

      settings.setAutoPlayAdBreaks(true);

      return adsLoader;
    }

    function onAdsManagerLoaded(adsManagerLoadedEvent) {
      var adsRenderingSettings = new window.google.ima.AdsRenderingSettings();

      adsRenderingSettings.loadVideoTimeout = this._settings.adCancelTimeout || 15000;

      // Tell the SDK to save and restore content video state on our behalf.
      adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

      /**
        The window.google.ima.AdsManager class is responsible for playing ads.
        */
      var adsManager = adsManagerLoadedEvent.getAdsManager(this.videoEl, adsRenderingSettings);
      this.adsManager = adsManager;
      this.controlBar.init(adsManager);

      if (this._settings.moat) {
        var ids = Object.assign({
          viewMode: window.google.ima.ViewMode.NORMAL,
          slicer1: '%esid!',
          slicer2: '%esid!'
        }, this._settings.moat);

        (0, _initMoatTracking2.default)(this.adsManager, ids, this.adContainerDiv);
      }

      //Set the adsManager event listeners
      adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, onAdErrorEvent.bind(this));

      adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, handleImaAdStart.bind(this));

      adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, handleImaAdEnd.bind(this));

      adsManager.addEventListener(window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED, onAdEvent.bind(this));

      // Listen to any additional events, if necessary.
      adsManager.addEventListener(window.google.ima.AdEvent.Type.LOADED, onAdEvent.bind(this));
      adsManager.addEventListener(window.google.ima.AdEvent.Type.STARTED, onAdEvent.bind(this));
      adsManager.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, onAdEvent.bind(this));

      try {
        var videoDimension = _dom2.default.getDimension(this.videoEl);

        // Initialize the ads manager. Ad rules playlist will start at this time.
        adsManager.init(videoDimension.width, videoDimension.height, window.google.ima.ViewMode.NORMAL);
        adsManager.start(); //We request the start of the ad
      } catch (adError) {
        this.handleImaError(adError);
      }
    }

    function onAdErrorEvent(adErrorEvt) {
      this.handleImaError(adErrorEvt.getError());
    }

    function handleImaAdStart() {
      var videoEl = this.videoEl;
      var adContainerDiv = this.adContainerDiv;

      _dom2.default.removeClass(adContainerDiv, 'ima-ad-loading');
      _dom2.default.addClass(adContainerDiv, 'ima-ad-playing');
      _dom2.default.dispatchEvent(videoEl, createEvt('ima.adStart'));

      this.toogleResize(true);
    }

    function handleImaAdEnd() {
      _dom2.default.removeClass(this.adContainerDiv, 'ima-ad-playing');
      _dom2.default.dispatchEvent(this.videoEl, createEvt('ima.adEnd'));
    }

    //TODO: REVIEW THIS WHOLE METHOD TO See if is necessary
    function onAdEvent(adEvent) {

      // Retrieve the ad from the event. Some events (e.g. ALL_ADS_COMPLETED)
      // don't have ad object associated.
      var ad = adEvent.getAd();
      switch (adEvent.type) {
        case window.google.ima.AdEvent.Type.LOADED:

          // This is the first event sent for an ad - it is possible to
          // determine whether the ad is a video ad or an overlay.
          if (!ad.isLinear()) {

            // Position AdDisplayContainer correctly for overlay.
            // Use ad.width and ad.height.
            this.videoEl.play();
          }

          break;
        case window.google.ima.AdEvent.Type.STARTED:
          break;
        case window.google.ima.AdEvent.Type.COMPLETE:
          break;
      }
    }
  }

  _createClass(ImaClient, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      this.imaPromise = _ima2.default.load().then(function () {
        // we not using the promise but a flag because in mobile breaks the touch event
        _this2._isLoaded = true;
      }).catch(this.handleImaError.bind(this));

      return this.imaPromise;
    }
  }, {
    key: 'toogleResize',
    value: function toogleResize(force) {

      var events = [{ target: window, name: 'resize', handler: this.resizeAd }, { target: this.videoEl, name: 'ima.adEnd', handler: this.unregisterResizeAd }, { target: this.videoEl, name: 'ima.adsError', handler: this.unregisterResizeAd }];

      events.forEach(function (event) {
        if (event.target) {
          _dom2.default.toggleEventListener(event.target, event.name, event.handler, force);
        }
      });
    }

    /**
     * This function gets call whenever we need to handle an error triggered by IMA SDK
     * @param error
     */

  }, {
    key: 'handleImaError',
    value: function handleImaError(error) {
      this.reset();
      _dom2.default.dispatchEvent(this.videoEl, createAdsErrorEvt(error));

      /*** local function ***/
      function createAdsErrorEvt(error) {
        if (typeof error.getVastErrorCode === 'function') {
          error.message = error.getMessage();
          error.code = error.getVastErrorCode();
        }

        var event = createEvt('ima.adsError');
        event.error = error;
        return event;
      }
    }
  }, {
    key: 'reset',
    value: function reset() {
      if (this.adsLoader) {
        this.adsLoader.contentComplete();
      }

      if (this.adsManager) {
        this.unregisterResizeAd();
        this.controlBar.reset();
        this.adsManager.destroy();
        this.adsManager = null;
      }

      _dom2.default.removeClass(this.adContainerDiv, 'ima-ad-playing');
      _dom2.default.removeClass(this.adContainerDiv, 'ima-ad-loading');
    }
  }, {
    key: 'resume',
    value: function resume() {
      if (this.adsManager) {
        this.adsManager.resume();
      }
    }
  }, {
    key: 'pause',
    value: function pause() {
      if (this.adsManager) {
        this.adsManager.pause();
      }
    }
  }, {
    key: 'resizeAd',
    value: function resizeAd(isFullscreen) {
      if (!this.adsManager) {
        //Don't do anything if there is no adsManager
        return;
      }

      this._isFullscreen = isFullscreen === undefined ? this._isFullscreen : isFullscreen;
      this.controlBar.onFullscreenChange(this._isFullscreen);

      var videoDimension = _dom2.default.getDimension(this.videoEl);
      this.adsManager.resize(videoDimension.width, videoDimension.height, window.google.ima.ViewMode[this._isFullscreen ? 'FULLSCREEN' : 'NORMAL']);
    }
  }, {
    key: 'requestAd',
    value: function requestAd(adTagUrl) {
      // we not using the promise but a flag because in mobile breaks the touch event
      if (!this._isLoaded) {
        var errorMsg = 'Ima Class Error: Tried to request an ad but the IMA SDK was not loaded';
        (0, _logger.log)(errorMsg);
        _dom2.default.dispatchEvent(this.videoEl, createEvt('ima.adsCancel'));
        throw new Error(errorMsg);
      }

      _requestAds.call(this, adTagUrl);

      //should we check first if was init or not?
      this.adDisplayContainer.initialize();

      initializeContent.call(this);

      /*** Local functions ***/
      function _requestAds() {
        var videoEl = this.videoEl;
        var adsLoader = this.adsLoader;
        var videoDimension = _dom2.default.getDimension(videoEl);

        if (!isReady.call(this)) {
          (0, _logger.log)('Ima Class Error: Tried to request an ad but the IMA SDK was not ready');
          _dom2.default.dispatchEvent(videoEl, createEvt('ima.adsCancel'));
          return;
        }

        try {
          _dom2.default.addClass(this.adContainerDiv, 'ima-ad-loading');
          adsLoader.requestAds(createAdRequest(videoDimension, adTagUrl, !!this._settings.isAutoplaying && this._settings.isAutoplaying()));
        } catch (adError) {
          this.handleImaError(adError);
        }

        function isReady() {
          return !!this.adDisplayContainer && !!this.adsLoader;
        }
      }

      function initializeContent() {

        //TODO: do we neeed this????
        this.videoEl.addEventListener('loadedmetadata', function () {});

        // IMPORTANT: On older Android devices (2.3.5 or earlier), the loadedmetadata
        // event is not fired as a result of calling load() on the video element.
        // Instead, the event is received after calling play().
        this.videoEl.load();
      }

      function createAdRequest(videoDimension, adTagUrl, autoplayAllowed) {
        var adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = adTagUrl;

        // Specify the linear and nonlinear slot sizes. This helps the SDK to
        // select the correct creative if multiple are returned.
        adsRequest.linearAdSlotWidth = videoDimension.width;
        adsRequest.linearAdSlotHeight = videoDimension.height;

        adsRequest.nonLinearAdSlotWidth = videoDimension.width;
        adsRequest.nonLinearAdSlotHeight = videoDimension.height;

        adsRequest.setAdWillAutoPlay(autoplayAllowed);
        adsRequest.setAdWillPlayMuted(autoplayAllowed);

        return adsRequest;
      }
    }
  }]);

  return ImaClient;
}();

exports.default = ImaClient;


function createEvt(evtName) {
  var event = document.createEvent('Event');
  event.initEvent(evtName, true, true);
  return event;
}
