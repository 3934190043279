'use strict';

var RelatedPanel = require('../../components/related-panel/related-panel');

var NextVideoLink = RelatedPanel.extend({
  init: function (player, options) {
    RelatedPanel.call(this, player, options);

    player.on('playerstate.enter.contentPlaying', this.updateClamp);
  }
});

NextVideoLink.prototype.show = function () {
  videojs.Button.prototype.show.call(this);
  this.trigger('show');
};

NextVideoLink.prototype.createEl = function () {
  var opts = this.options();
  var video = opts.video;
  var thumb = video.thumbnail || video.poster;

  return videojs.createEl('div', {
    className: 'vjs-playlist-container',
    innerHTML: '<div class="vjs-playlist-close-button" role="button" aria-live="polite" tabindex="-1">×</div>' +
               '<h3>Up next</h3>' +
               '<div class="vjs-playlist-wrapper"><img src="' + thumb + '" /></div>' +
               '<div class="vjs-playlist-caption"><h4>' + video.title + '</h4></div>'
  });
};

NextVideoLink.prototype.onClick = function (evt) {
  var player = this.player();
  if (evt.target.className == 'vjs-playlist-close-button') {
    this.hide();
  }  else {
    player.trigger('mol.playlist.next');
  }
};

module.exports = NextVideoLink;
