'use strict';

var FullWidthPopupContent = require('../base/full-width-popup-content');
var Clearfix = require('../base/clearfix');

var FacebookButton = require('./social-buttons').FacebookButton;
var MailButton = require('./social-buttons').MailButton;
var TwitterButton = require('./social-buttons').TwitterButton;
var PinterestButton = require('./social-buttons').PinterestButton;
var GooglePlusButton = require('./social-buttons').GooglePlusButton;
var RedditButton = require('./social-buttons').RedditButton;
var StumbleUponButton = require('./social-buttons').StumbleUponButton;
var DiggButton = require('./social-buttons').DiggButton;
var LinkedinButton = require('./social-buttons').LinkedinButton;

/* Share Social Share Buttons
 ================================================================================ */

// Embed Video Popup Content - it will contain the content of the popup

var SharePopupContent = videojs.Component.extend({
  /** @Contructor */
  init: function (player) {
    videojs.Component.call(this, player);
    var content = new FullWidthPopupContent(player);

    content.addChild(new FacebookButton(player));
    content.addChild(new TwitterButton(player));
    content.addChild(new PinterestButton(player));
    content.addChild(new MailButton(player));
    content.addChild(new GooglePlusButton(player));
    content.addChild(new RedditButton(player));
    content.addChild(new StumbleUponButton(player));
    content.addChild(new DiggButton(player));
    content.addChild(new LinkedinButton(player));

    this.addChild(content);
    this.addChild(new Clearfix(player));

  }
});

SharePopupContent.prototype.createEl = function () {
  var innerHTML = '<div class="clearfix"></div>' +
                  '<div class="vjs-popup-title">' +
                      'Share this Video' +
                  '</div>';

  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-social-popup-content vjs-popup-wrapper',
    innerHTML: innerHTML
  });
};

module.exports = SharePopupContent;
