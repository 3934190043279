'use strict';

var dataSource = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dataSource');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');
var RelatedVideosViewPaused = require('./related-videos-view-paused');
var RelatedVideosViewEnded = require('./related-videos-view-ended');
var Playlist = require('./Playlist');
var historyStorage = require('../history/history-storage');

videojs.plugin('related-videos', function molRelatedSetup(opts) {
  var player = this;
  var options = utilities.extend({}, opts);
  var relatedVideos = dataSource(options.videos);
  var playlistHistory = new Playlist(player, options);
  var previousControl = player.controlBar.getChild('molPreviousVideoButton');
  var skipControl = player.controlBar.getChild('molSkipVideoButton');
  var activeView;
  var minRelatedVideos = 15;

  previousControl && previousControl.enable();
  skipControl && skipControl.enable();
  player.fetchRelatedVideos = fetchRelatedVideos;
  player.relatedVideos = relatedVideos;
  player.disableControlsListeners = disableControlsListeners;

  player.on('playerstate.enter.pauseStandby', enterRelatedVideosPaused);
  player.on('playerstate.leave.pauseStandby', hideCurrentView);

  player.on('playerstate.enter.standby', enterRelatedVideosEnded);
  player.on('playerstate.leave.standby', hideCurrentView);

  player.on('mol.play.next.video', playNextVideo);
  player.on('mol.play.previous.video', playPreviousVideo);

  player.on('reset', function () {
    relatedVideos = dataSource(player.options().plugins['related-videos'].videos);
    player.relatedVideos = relatedVideos;
  });

  player.on('request-reset', function (evt) {
    var futureVideo = evt && evt.video || {};
    var currentVideo = utilities.extend({
      src: player.src(),
      poster: player.poster()
    }, player.options());

    if (playlistHistory.contains(futureVideo)) { // Went to the previous video
      playlistHistory.pop();
    } else {
      playlistHistory.add(currentVideo);
    }
  });

  player.trigger('related-videos-ready');

  function hideCurrentView() {
    if (activeView) {
      activeView.dispose();
      player.removeChild(activeView);
      activeView = null;
    }
  }

  function enterRelatedVideosPaused() {
    hideCurrentView();

    fetchRelatedVideos(function (err, videos) {
      // This is required when quickly entering and leaving the state,
      // e.g. when autoplay -> standby -> contentWaiting ...
      //
      // A cleaner approach would be to abort the `relatedVideos.get`
      // request, though that would require adding `abort` method
      // to the `@mol-fe/mol-fe-videoplayer-utils/src/utils/dataSource`.
      if (player.playerState.getStateName() !== 'pauseStandby') {
        return;
      }

      activeView = new RelatedVideosViewPaused(player, {
        videos: videos
      });

      player.addChild(activeView);
    });
  }

  function enterRelatedVideosEnded() {
    hideCurrentView();

    fetchRelatedVideos(function (err, videos) {
      if (player.playerState.getStateName() !== 'standby') {
        return;
      }

      activeView = new RelatedVideosViewEnded(player, {
        videos: videos
      });

      player.addChild(activeView);
    });
  }

  function isAdPlaying() {
    return player._states &&
      player._states.state &&
      (player._states.state.get() === 'adPlaying' || player._states.state.get() === 'adPauseStandby');
  }

  function playNextVideo() {
    if (isAdPlaying()) {
      return;
    }

    fetchRelatedVideos(function (error, relatedVideoList) {
      if (error) {
        return;
      }

      if (relatedVideoList.length > 0) {
        changeVideo(relatedVideoList[0]);
      } else {
        player.currentTime(player.duration());
      }
    });
  }

  function playPreviousVideo() {
    if (isAdPlaying()) {
      return;
    }

    if (player.currentTime() <= 3 && !playlistHistory.isEmpty()) {
      changeVideo(playlistHistory.last());
      return;
    }

    player.currentTime(0);
  }

  function changeVideo(video) {
    player.resetVideo({
      video: {
        src: video.src,
        poster: video.poster,
        title: video.title,
        initialVideo: false,
        options: video,
        playlistClickToPlay: true
      },
      autoplay: !player.paused()
    });
  }

  function fetchRelatedVideos(callback) {
    relatedVideos.get(function (error, relatedVideoList) {
      if (error) {
        console.log('plugin.related-videos Error: ' + error.message);
      }

      if (!relatedVideoList || relatedVideoList.length === 0) {
        console.log('plugin.related-videos Warning: No related videos');
        relatedVideoList = [];
      }

      var historyMap = historyStorage.getMap();

      var playedRelatedVideos = [];
      var unplayedRelatedVideos = [];
      relatedVideoList.forEach(function (relatedVideo) {
        if (!playlistHistory.contains(relatedVideo) &&
          !historyMap[relatedVideo.referenceId] &&
          relatedVideo.referenceId !== player.options().referenceId) {

          unplayedRelatedVideos.push(relatedVideo);
        } else {
          playedRelatedVideos.push(relatedVideo);
        }
      });

      if (unplayedRelatedVideos.length < minRelatedVideos) {
        // if we have less than the minimun related videos required
        // we concat the watched ones at the end, up to the minumun
        // number of videos required
        callback(error, unplayedRelatedVideos.concat(playedRelatedVideos).slice(0, minRelatedVideos));
      } else {
        callback(error, unplayedRelatedVideos);
      }
    });
  }

  function disableControlsListeners() {
    player.off('mol.play.next.video', playNextVideo);
    player.off('mol.play.previous.video', playPreviousVideo);
  }

});
