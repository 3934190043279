'use strict';

var simpleClamp = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/simpleClamp');

var RelatedPanel = videojs.Button.extend({
  init: function (player, options) {
    videojs.Button.call(this, player, options);

    var el = this.el();

    this.updateClamp = function () {
      simpleClamp(el.getElementsByTagName('h4'));
    };

    player.on('resize', this.updateClamp);

    this.on('show', this.updateClamp);
    this.on('dispose', function () {
      player.off('resize', this.updateClamp);
    });
  }
});

module.exports = RelatedPanel;
