'use strict';

var TimerControls = require('./timer-controls/timer-controls');
var StreamingLabel = require('./streaming-label/streaming-label');
var VideoQuality = require('./video-quality/video-quality');
var SocialControls = require('./social-controls/social-controls');
var PreviousVideoControl = require('./flow-controls/previous-video-control');
var SkipVideoControl = require('./flow-controls/skip-video-control');

/**
 * Container of main controls
 * @param {videojs.Player|Object} player
 * @param {Object=} options
 * @class
 * @constructor
 * @extends videojs.Component
 */

var ControlBar = videojs.Component.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Component.call(this, player, options);

    this.addChild(new videojs.ProgressControl(player));
    this.addChild(new PreviousVideoControl(player));
    this.addChild(new videojs.PlayToggle(player));
    this.addChild(new SkipVideoControl(player));
    this.addChild(new StreamingLabel(player));
    this.addChild(new videojs.VolumeMenuButton(player, {
      volumeBar: {
        vertical: true
      }
    }));
    this.addChild(new TimerControls(player));
    this.addChild(new videojs.FullscreenToggle(player));
    this.addChild(new VideoQuality(player));
    this.addChild(new SocialControls(player));

    try {
      player.volume(0.7);
      if (player.options().muted) {
        player.muted(true);
      }
    }
    catch (e) {
      console.log('Failing to set volume');
    }

    player.on('ended', this.onVideoEnded);
    player.on('play', this.onVideoStart);
  }
});

ControlBar.prototype.options_ = {
  loadEvent: 'play'
};

ControlBar.prototype.createEl = function () {
  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-control-bar'
  });
};

ControlBar.prototype.onVideoEnded = function () {
  this.addClass('vjs-video-finished');
};

ControlBar.prototype.onVideoStart = function () {
  this.removeClass('vjs-video-finished');
};

module.exports = ControlBar;

