'use strict';

var utils = require('@mol-fe/mol-fe-videoplayer-utils');
var mailTo = require('../../components/control-bar/social-controls/mail/mail-button');
var utilities = utils.utilityFunctions;
var dom = utils.dom;
var mol = utils.mol;

videojs.plugin('social-share', function socialShareSetup(opts) {
  var player = this;
  var videoEl = player.el();

  /*
   private methods
   */
  var sanitise = function (s) {
    if (!s) return '';
    s = s.replace(/&quot;/g, '"');
    return s.replace(/&#39;/g, '\'');
  };

  var getTitle = function () {
    return sanitise(player.options().title);
  };

  var getDescr = function () {
    return sanitise(player.options().descr);
  };

  var getPlacement = function () {
    return opts.placement || 'videoplayer';
  };

  var getVia = function () {
    return opts.via || window.currentChannelTwitterFollow || '';
  };

  var getTrackingOptions = function () {
    return {
      referenceId: player.options().referenceId,
      type: 'video_share',
      version: mol.getVideoPlayerVersion(),
      videoId: player.options().videoId
    };
  };

  var disable = function () {
    function removeDisableClasses() {
      utilities.forEach(videoEl.className.split(/ +/), function (cls) {
        if (cls.indexOf('vjs-disable-social-') !== -1) {
          dom.removeClass(videoEl, cls);
        }
      });
    }

    opts.disabled = utilities.isString(opts.disabled) ? opts.disabled.split(/ +/) : opts.disabled;
    removeDisableClasses();

    if (opts.disabled) {
      utilities.forEach(opts.disabled, function (item) {
        dom.addClass(videoEl, 'vjs-disable-social-' + item);
      });
    }
  };

  disable();

  player.on('reset', function () {
    opts = player.options().plugins['social-share'];
    disable();
  });

  /*
   public methods
   */
  var socials = {};

  socials.postToFB = function () {
    window.DMS.Facebook.postToFB(getPlacement(), JSON.stringify({
      link: player.options().linkBaseURL,
      picture: player.options().thumbnail || player.options().poster || '',
      name: getTitle(),
      description: getDescr()
    }), getTrackingOptions());
  };

  socials.postToMail = function () {
    //Display email
    mailTo(player.socials.nativeEmailContent());
    window.DMS.Email.emailArticle(getPlacement(), JSON.stringify({
      link: player.options().linkBaseURL,
      picture: player.options().thumbnail || player.options().poster || '',
      name: getTitle(),
      description: getDescr()
    }), getTrackingOptions());
  };

  socials.postToGPlus = function () {
    window.DMS.Google.postToGPlus(getPlacement(), {
      url: player.options().linkBaseURL
    }, getTrackingOptions());
  };

  socials.postToTWTTR = function () {
    window.DMS.Twitter.postToTWTTR(getPlacement(), JSON.stringify({
      url: player.options().linkBaseURL,
      text: getTitle(),
      via: getVia()
    }), getTrackingOptions());
  };

  socials.postToPinterest = function () {
    window.DMS.Pinterest.postToPinterest(getPlacement(), {
      description: getDescr(),
      via: getVia(),
      media: player.options().poster,
      type: 'image',
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.renderInteractivePost = function (el) {
    window.DMS.Google.renderInteractivePost(el, getPlacement(), {
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.postToReddit = function () {
    window.DMS.Reddit.postToReddit(getPlacement(), {
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.postToStumbleUpon = function () {
    window.DMS.StumbleUpon.postToStumbleUpon(getPlacement(), {
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.postToFark = function () {
    window.DMS.Fark.postToFark(getPlacement(), {
      u: player.options().linkBaseURL,
      h: getTitle()
    }, getTrackingOptions());
  };

  socials.postToDiggit = function () {
    window.DMS.Diggit.postToDiggit(getPlacement(), {
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.postToLinkedIn = function () {
    window.DMS.LinkedIn.postToLinkedIn(getPlacement(), {
      url: player.options().linkBaseURL,
      title: getTitle()
    }, getTrackingOptions());
  };

  socials.embedSnippet = function () {
    var url = opts.embedUrl || '';

    /** OLD frame **/
    return (url) ? '<iframe allowfullscreen frameborder="0" width="698" height="573" scrolling="no" id="molvideoplayer" title="MailOnline Embed Player" src="' + url + '"></iframe>' : '';
  };

  socials.videoLink = function () {
    return player.options().linkBaseURL;
  };

  socials.mailLink = function () {
    return player.options().linkBaseURL;
  };

  socials.nativeEmailContent = function () {
    return {
      title: getTitle(),
      link: player.options().linkBaseURL
    };
  };

  player.socials = socials;
});
