'use strict';

var BrandedContentEndView = videojs.Component.extend({
  init: function (player, options) {
    videojs.Component.call(this, player, options);
  }
});

BrandedContentEndView.prototype.createEl = function () {
  var options = this.options();

  var viewElement =  videojs.createEl('div', {
    className: 'mol-branded-content__end-view'
  });

  var clickThroughUri = options.clickThroughUri ? options.clickThroughUri : '';
  var target = options.clickThroughUri ? '_blank' : '';

  viewElement.innerHTML =
    '<a class="mol-branded-content__end-view-blocker" href="' + clickThroughUri + '" target="' + target + '">' +
      '<img class="mol-branded-content__end-view-poster" src="' + options.poster + '">' +
    '</a>';

  return viewElement;
};

module.exports = BrandedContentEndView;
