'use strict';

function elementIsVisibleInViewPort(element) {
  var windowHeight = window.innerHeight;
  var windowWidth = window.innerWidth;
  var dH = Math.max(element.clientHeight, element.clientHeight - windowHeight) >> 1;
  var dW = Math.max(element.clientWidth, element.clientWidth - windowWidth) >> 1;
  var clientRect = element.getBoundingClientRect();
  var inHorizontal = clientRect.left > -dW && clientRect.right < windowWidth + dW || clientRect.left < 0 && clientRect.right > windowWidth;
  var inVertical = clientRect.top > -dH && clientRect.bottom < windowHeight + dH || clientRect.top < 0 && clientRect.bottom > windowHeight;

  return inHorizontal && inVertical;
}

module.exports = elementIsVisibleInViewPort;
