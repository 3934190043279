'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Browser = function () {
  function Browser() {
    _classCallCheck(this, Browser);
  }

  _createClass(Browser, null, [{
    key: 'navigator',
    value: function navigator() {
      return window.navigator;
    }

    /**
     * Returns the version of Internet Explorer or a -1 (indicating the use of another browser).
     * Source: https://msdn.microsoft.com/en-us/library/ms537509(v=vs.85).aspx
     * @returns {number} the version of Internet Explorer or a -1 (indicating the use of another browser).
     */

  }, {
    key: 'getInternetExplorerVersion',
    value: function getInternetExplorerVersion(navigator) {
      var rv = -1;

      if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
        var res = re.exec(ua);
        if (res !== null) {
          rv = parseFloat(res[1]);
        }
      }

      return rv;
    }

    /**
     * Checks if the Browser is IE9 and below
     * @returns {boolean} true if the browser is IE9 and below
     */

  }, {
    key: 'isOldIE',
    value: function isOldIE() {
      var navigator = Browser.navigator();
      var version = Browser.getInternetExplorerVersion(navigator);
      if (version === -1) {
        return false;
      }

      return version < 10;
    }

    /*** Mobile Utility functions ***/

  }, {
    key: 'isIDevice',
    value: function isIDevice() {
      var navigator = Browser.navigator();
      return (/iP(hone|ad)/.test(navigator.userAgent)
      );
    }
  }, {
    key: 'isMobile',
    value: function isMobile() {
      var navigator = Browser.navigator();
      return (/iP(hone|ad|od)|Android|Windows Phone/.test(navigator.userAgent)
      );
    }
  }, {
    key: 'isIPhone',
    value: function isIPhone() {
      var navigator = Browser.navigator();
      return (/iP(hone|od)/.test(navigator.userAgent)
      );
    }
  }, {
    key: 'isAndroid',
    value: function isAndroid() {
      var navigator = Browser.navigator();
      return (/Android/.test(navigator.userAgent)
      );
    }
  }]);

  return Browser;
}();

exports.default = Browser;
