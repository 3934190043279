'use strict';

var InteractiveSocialButton = require('../base/interactive-social-button');
var EmbedPopup = require('./embed-popup');

var EmbedButton = InteractiveSocialButton.extend();
EmbedButton.prototype.buttonName = 'EmbedButton';

EmbedButton.prototype.getPopup = function () {
  return new EmbedPopup(this.player_);
};

module.exports = EmbedButton;
