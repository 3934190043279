'use strict';

var CloseButton = require('./close-button');

// Cancel Button - Generic cancel button that closes the popup when you click on it
var CancelButton = CloseButton.extend();

CancelButton.prototype.createEl = function () {
  return videojs.Button.prototype.createEl.call(this, 'button', {
    className: 'vjs-cancel-button vjs-popup-button',
    innerHTML: 'Cancel'
  });
};

module.exports = CancelButton;
