'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function isCallable(_ref) {
  var cb = _ref.cb,
      _ref$throwError = _ref.throwError,
      throwError = _ref$throwError === undefined ? false : _ref$throwError;

  var cbType = typeof cb === 'undefined' ? 'undefined' : _typeof(cb);
  if (cbType !== 'function') {
    if (throwError) {
      throw new Error('onDocumentLoad: expected function, but received "' + cbType + '"');
    }

    return false;
  }

  return true;
}

module.exports = isCallable;
