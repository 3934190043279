'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dom = require('../util/dom');

var _dom2 = _interopRequireDefault(_dom);

var _fullscreen = require('../util/fullscreen');

var _fullscreen2 = _interopRequireDefault(_fullscreen);

var _imaControlVolume = require('./imaControlVolume');

var _imaControlVolume2 = _interopRequireDefault(_imaControlVolume);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ControlBar = function () {
  function ControlBar(adDisplayDiv, videoEl) {
    var _this = this;

    _classCallCheck(this, ControlBar);

    this.videoEl = videoEl;

    this.controlsContainer = createControl('ima-controls-div');
    this.seekBarControl = createSeekBarControl();
    this.progressBarControl = createProgressBarControl(this.seekBarControl);
    this.playPauseControl = createPlayPauseControl.call(this);
    this.volumeControl = new _imaControlVolume2.default(videoEl);
    this.countdownControl = createControl('ima-countdown-div');
    this.previousControl = createControl('ima-previous-div', 'mol-disabled-control');
    this.skipControl = createControl('ima-skip-div', 'mol-disabled-control');

    _dom2.default.appendChildren(this.controlsContainer, [this.seekBarControl, this.previousControl, this.playPauseControl, this.skipControl, this.volumeControl.el, this.countdownControl]);

    adDisplayDiv.appendChild(this.controlsContainer);

    if (_fullscreen2.default.isSupported()) {
      this.fullScreenControl = createFullscreenControl.call(this);
      this.controlsContainer.appendChild(this.fullScreenControl);
    }

    _dom2.default.addEventListener(this.volumeControl.el, 'ima.volume', function () {
      updateAdsVolume(_this.volumeControl, _this.adsManager);
    });

    /*** local functions ***/

    function createSeekBarControl() {
      var seekBarDiv = document.createElement('div');
      _dom2.default.addClass(seekBarDiv, 'ima-seek-bar-div');
      return seekBarDiv;
    }

    function createProgressBarControl(seekBarDiv) {
      var progressBarDiv = document.createElement('div');
      _dom2.default.addClass(progressBarDiv, 'ima-progress-div');
      seekBarDiv.appendChild(progressBarDiv);
      return progressBarDiv;
    }

    function createPlayPauseControl() {
      var playPauseControl = createControl('ima-play-pause-div');

      _dom2.default.click(playPauseControl, this.onAdPlayPauseClick.bind(this));

      return playPauseControl;
    }

    function createFullscreenControl() {
      var fullscreenControl = createControl('ima-fullscreen-div');

      _dom2.default.click(fullscreenControl, this.trigger.bind(this, 'ima.fullscreen'));

      return fullscreenControl;
    }

    function createControl() {
      for (var _len = arguments.length, classes = Array(_len), _key = 0; _key < _len; _key++) {
        classes[_key] = arguments[_key];
      }

      return _dom2.default.createElementWithClasses(['ima-control'].concat(classes));
    }
  }

  _createClass(ControlBar, [{
    key: 'updateVideoState',
    value: function updateVideoState() {
      this.volumeControl.updateVideoState();
      updateAdsVolume(this.volumeControl, this.adsManager);
    }
  }, {
    key: 'init',
    value: function init(adsManager) {
      var _this2 = this;

      this.adsManager = adsManager;
      this.updateVideoState();

      //NOTE: Some ads fail to update the video state when the control bar init so we try again on the loaded and started events
      [google.ima.AdEvent.Type.LOADED, google.ima.AdEvent.Type.STARTED].forEach(function (adEvent) {
        adsManager.addEventListener(adEvent, _this2.updateVideoState.bind(_this2));
      });

      adsManager.addEventListener(google.ima.AdEvent.Type.CLICK, this.onAdPlayPauseClick.bind(this));

      adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, this.onAdStarted.bind(this));

      adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, this.onAdComplete.bind(this));

      adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, this.onAdComplete.bind(this));

      adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, this.onAdPlayStatusUpdated.bind(this, false));

      adsManager.addEventListener(google.ima.AdEvent.Type.RESUMED, this.onAdPlayStatusUpdated.bind(this, true));
    }
  }, {
    key: 'onAdPlayPauseClick',
    value: function onAdPlayPauseClick() {
      var adPlaying = !_dom2.default.hasClass(this.playPauseControl, 'ima-play');
      if (this.adsManager) {
        this.adsManager[adPlaying ? 'pause' : 'resume']();
      }

      this.trigger(adPlaying ? 'pause' : 'play');
    }
  }, {
    key: 'onAdPlayStatusUpdated',
    value: function onAdPlayStatusUpdated(isPlaying) {
      _dom2.default.toggleClass(this.playPauseControl, 'ima-play', !isPlaying);
    }
  }, {
    key: 'onAdStarted',
    value: function onAdStarted(adEvent) {
      var currentAd = adEvent.getAd();
      this.currentAd = currentAd;
      if (currentAd.isLinear()) {
        this.adTrackingTimer = setInterval(this.onAdPlayheadTrackerInterval.bind(this), 250);
      }
    }
  }, {
    key: 'onAdPlayheadTrackerInterval',
    value: function onAdPlayheadTrackerInterval() {
      var remainingTime = this.adsManager.getRemainingTime();
      var duration = this.currentAd.getDuration();
      var currentTime = duration - remainingTime;
      currentTime = currentTime > 0 ? currentTime : 0;
      var remainingMinutes = Math.floor(remainingTime / 60);
      var remainingSeconds = Math.floor(remainingTime % 60);
      if (remainingSeconds.toString().length < 2) {
        remainingSeconds = '0' + remainingSeconds;
      }

      this.countdownControl.innerHTML = 'Advertisement ' + remainingMinutes + ':' + remainingSeconds;

      // Update UI
      var playProgressRatio = currentTime / duration;
      var playProgressPercent = playProgressRatio * 100;
      this.progressBarControl.style.width = playProgressPercent + '%';

      // fire event for tracking
      this.trigger('ima.timeupdate', { time: currentTime });
    }
  }, {
    key: 'onFullscreenChange',
    value: function onFullscreenChange(isFullscreen) {
      if (this.fullscreenControl) {
        _dom2.default.toggleClass(this.fullScreenControl, 'ima-fullscreen', isFullscreen);
      }
    }
  }, {
    key: 'onAdComplete',
    value: function onAdComplete() {
      this.adsManager = null;
      this.currentAd = null;
      if (this.adTrackingTimer) {
        clearInterval(this.adTrackingTimer);
      }

      this.progressBarControl.style.width = '0%';
    }
  }, {
    key: 'trigger',
    value: function trigger(evtName, opts) {
      _dom2.default.trigger(this.videoEl, evtName, opts);
    }
  }, {
    key: 'reset',
    value: function reset() {
      this.onAdComplete();
    }
  }]);

  return ControlBar;
}();

exports.default = ControlBar;


function updateAdsVolume(volumeControl, adsManager) {
  var volume = volumeControl.getVolume();
  adsManager && adsManager.setVolume(volume);
}
