'use strict';

var isCallable = require('../common/isCallable');
var removableListener = require('../common/removableListener');

/**
 * @link https://github.com/mobify/mobifyjs/issues/136
 * @param cb
 * @returns {remove}
 */
function onDocumentLoad(cb) {
  isCallable({ cb: cb, throwError: true });

  function eventHandler() {
    if (_removableListener.isRemoved) {
      return;
    }

    _removableListener.remove();
    cb();
  }

  var documentEventValues = ['DOMContentLoaded', eventHandler];
  var windowEventValues = ['load', eventHandler];

  var _removableListener = removableListener(function () {
    var _document, _window;

    (_document = document).removeEventListener.apply(_document, documentEventValues);
    (_window = window).removeEventListener.apply(_window, windowEventValues);
  });

  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    eventHandler();
  } else {
    var _document2, _window2;

    (_document2 = document).addEventListener.apply(_document2, documentEventValues);
    (_window2 = window).addEventListener.apply(_window2, windowEventValues);
  }

  return _removableListener;
}

module.exports = onDocumentLoad;
