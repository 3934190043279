'use strict';

var isCallable = require('./isCallable');

function removableListener(cb) {
  isCallable({ cb: cb, throwError: true });

  return {
    isRemoved: false,
    remove: function remove() {
      this.isRemoved = true;
      cb();
    }
  };
}

module.exports = removableListener;
