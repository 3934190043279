'use strict';

var dom = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/dom');
var utilities = require('@mol-fe/mol-fe-videoplayer-utils/src/utils/utilityFunctions');

var PlayersSetup = require('./players-setup');

var VIDEOJSCLASS = 'video-js';

//TODO: [CS, MM] we need this duplication until we update gallery and carousel after the decoupling of videos is stable please check MOL-7904
var molFeVideoplayer = function (el) {
  var setup = new PlayersSetup();
  setup.run(el);
};

molFeVideoplayer.init = function (el) {
  var setup = new PlayersSetup();

  return setup.run(el);
};

molFeVideoplayer.clone = function (el, override) {
  var player = el.player;
  var opts = PlayersSetup.getMediaOpts(el);

  if (!player || !opts) return;

  if (override) {
    opts = utilities.extend({}, opts, override);
  }

  var video = document.createElement('video');

  video.className = 'video-js';
  video.setAttribute('width', player.width());
  video.setAttribute('height', player.height());
  video.setAttribute('controls', 'true');
  video.setAttribute('preload', 'false');
  video.setAttribute('data-opts', JSON.stringify(opts));

  if (player.autoplay()) {
    video.setAttribute('autoplay', 'autoplay');
  }

  utilities.forEach(player.options().sources, function (s) {
    var src = document.createElement('source');
    src.setAttribute('src', s.src);
    src.setAttribute('width', s.width);
    src.setAttribute('height', s.height);
    src.setAttribute('type', s.type);
    video.appendChild(src);
  });

  return video;
};

molFeVideoplayer.destroy = function (el) {
  molFeVideoplayer.forEachPlayer(el, function () {
    this.trigger('request-dispose');
  });
};

molFeVideoplayer.forEachPlayer = function (el, cb) {
  var vids;

  if (typeof el == 'function') {
    cb = el;
    el = undefined;
  }

  el = el || document;

  if (el !== document && dom.hasClass(el, VIDEOJSCLASS)) {
    vids = [el];
  } else {
    vids = el.querySelectorAll('.' + VIDEOJSCLASS);
  }

  utilities.forEach(vids, function (mediaEl) {
    if (mediaEl.player) {
      cb.apply(mediaEl.player);
    }
  });
};

var events = {};
molFeVideoplayer.on = function (name, func) {
  if (!(name in events)) events[name] = [];
  events[name].push(func);
};

molFeVideoplayer.off = function (name, func) {
  if (!(name in events)) return;
  if (typeof func === undefined) {
    delete events[name];
  } else {
    events[name] = events[name].filter(function (f) {
      return func !== f;
    });
  }
};

molFeVideoplayer.trigger = function (name, evt) {
  var cbs = !(name in events) ? [] : events[name];
  for (var i = 0, len = cbs.length; i < len; i++) {
    cbs[i](evt);
  }
};

module.exports = molFeVideoplayer;
