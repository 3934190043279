'use strict';

var isElementInViewPort = function (el) {
  var rect = el.getBoundingClientRect();
  var height = rect.height;
  var width = rect.width;

  if (!height || !width) {
    return false;
  }

  var verticalOffset = height * 0.5;
  var horizontalOffset = width * 0.5;
  var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  return ((rect.top + verticalOffset >= 0) &&
    (rect.bottom - verticalOffset <= viewportHeight) &&
    rect.left >= 0 && rect.right <= viewportWidth) ||
    ((rect.left + horizontalOffset >= 0) &&
    (rect.right - horizontalOffset <= viewportWidth) &&
    rect.top >= 0 && rect.bottom <= viewportHeight);
};

module.exports = isElementInViewPort;
