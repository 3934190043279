'use strict';

var FlyoutExpand = videojs.Button.extend({});
FlyoutExpand.prototype.createEl = function () {
  return videojs.createEl('span', {
    className: 'vjs-flyout-expand vjs-flyout-button',
    innerHTML: 'Expand'
  });
};

FlyoutExpand.prototype.onClick = function () {
  this.player().trigger('flyout-expand');
};

module.exports = FlyoutExpand;
