'use strict';

function onScroll(targets, cb) {
  var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;

  if (targets.constructor !== Array) {
    targets = [targets];
  }

  var timeoutId = void 0;
  function eventHandler() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(cb, delay);
  }

  targets.forEach(function (target) {
    if (!target || !target.addEventListener) {
      return;
    }

    target.addEventListener('scroll', eventHandler);
  });

  return eventHandler;
}

module.exports = onScroll;
