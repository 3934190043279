'use strict';

//Popup Buttons - Container component for the popup buttons
var PopupButtons = videojs.Component.extend();

PopupButtons.prototype.createEl = function () {
  return videojs.Component.prototype.createEl.call(this, 'div', {
    className: 'vjs-popup-buttons'
  });
};

module.exports = PopupButtons;
