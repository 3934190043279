'use strict';

var CloseButton = require('./close-button');

// Full width Popup - Base Class for the full with popups, it automatically adds the close button and it pause and resumes
//                    the video when you open and close the popup.

var FullWidthPopup = videojs.Component.extend({
  /** @constructor */
  init: function (player, options) {
    videojs.Component.call(this, player, options);
    var closeButton = new CloseButton(player, options);
    this.addChild(closeButton);
    this.player_.on('CloseButton.pressed', videojs.bind(this, function () {
      if (this.el_) {
        this.trigger('FullWidthPopup.close');
      }
    }));
  }
});

FullWidthPopup.prototype.createEl = function (type, props) {
  props = props || {};
  props.className = (props.className || '') + ' vjs-full-width-popup vjs-control';
  return videojs.Component.prototype.createEl.call(this, type || 'div', props);
};

module.exports = FullWidthPopup;
