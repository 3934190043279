'use strict';

var dom = require('@mol-fe/mol-fe-videoplayer-utils').dom;

videojs.plugin('streaming', function streaming() {
  var player = this;

  if (player.options().isStreaming) {

    dom.addClass(player.el(), 'streaming');
    dom.addClass(player.el().parentNode, 'vjs-video-container-streaming');
  }
});
