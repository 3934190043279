'use strict';

var utilities = require('@mol-fe/mol-fe-videoplayer-utils').utilityFunctions;

// This plugin fix the multiple click issue existing on android 4.3 default browsers
// For more info please refer to https://github.com/videojs/video.js/issues/975
videojs.plugin('multiple-clicks-fix', function mobile() {
  var player = this;

  player.ready(function () {
    walkChildrenTree(player, function (child) {
      if (isClickable(child)) {
        preventMultipleClicks(child);
      }
    });
  });

  /*** Local functions ***/
  function walkChildrenTree(component, handler) {
    component.children().forEach(function (child) {
      walkChildrenTree(child, handler);
      handler(child);
    });
  }

  function isClickable(component) {
    return typeof component.onClick === 'function';
  }

  function preventMultipleClicks(component) {
    var debouncedClick = utilities.debounce(component.onClick, 500, true);
    ['tap', 'click'].forEach(function (evt) {
      component.off(evt, component.onClick);
      component.on(evt, debouncedClick);
    });
  }
});
