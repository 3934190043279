'use strict';
var Promise = require('es6-promise').Promise;
var url = 'http://cdn-gl.imrworldwide.com/novms/js/2/ggcmb510.js';

function addScript() {
  return new Promise(function (resolve, reject) {
    if (window.NOLCMB) {
      resolve();
    } else {
      (function () {
        var a, s = document.getElementsByTagName('script')[0];
        a = document.createElement('script');
        a.type = 'text/javascript';
        a.async = true;
        a.onload = resolve;
        a.onerror = reject;
        a.src = url;
        s.parentNode.insertBefore(a, s);
      })();
    }
  });
}

var addScriptPromise;
var instances = {};
var loadedMetadata = {};

function getSDK() {
  if (!addScriptPromise) {
    addScriptPromise = addScript();
  }

  return addScriptPromise.then(function () {
    return window.NOLCMB;
  });
}

function getSection() {
  return window._nolggGlobalParams && window._nolggGlobalParams.section;
}

function initInstance(player, nielsenOptions) {
  var playerId = player.id();
  instances[playerId] = getSDK()
    .then(function (sdk) {
      var globalParams = {
        sfcode: nielsenOptions.sfcode || 'dcr',
        apid: nielsenOptions.apid,
        apn: nielsenOptions.apn,
        nsdkv: '511'
      };
      var nSdkInstance = sdk.getInstance(globalParams.apid);
      nSdkInstance.ggInitialize(globalParams);

      return nSdkInstance;
    })
    .catch(function () {
      console.error('Failed to instance Nielsen DCR Video Tracking');

      return null;
    });

  return instances[playerId];
}

function getInstance(player) {
  return instances[player.id()];
}

function loadVideoMetadata(videoData, player, isStreaming) {
  return getInstance(player).then(function (nielsenInstance) {
    if (!nielsenInstance) {
      return null;
    }

    if (!loadedMetadata[player.id()] || loadedMetadata[player.id()] !== 'video') {
      var meta = {
        type: 'content',
        dataSrc: 'cms',

        // The difference in case (assetid vs assetId is intentional)
        assetid: videoData.videoId,
        section: getSection(),
        title: videoData.title,
        length: isStreaming ? 86400 : Math.floor(videoData.duration),
        program: videoData.program,
        isfullepisode: videoData.isfullepisode,
        hasAds: videoData.hasAds,
        adloadtype: videoData.adloadtype,
        subbrand: videoData.subbrand
      };

      nielsenInstance.ggPM('loadMetadata', meta);
      loadedMetadata[player.id()] = 'video';
    }
  });
}

function videoStart(videoData, player, isStreaming) {
  return loadVideoMetadata(videoData, player, isStreaming);
}

function preroll(videoData, player, isVPAID) {
  return getInstance(player).then(function (nielsenInstance) {
    if (!nielsenInstance) {
      return null;
    }

    var meta = {
      type: 'preroll',

      // The difference in case (assetid vs assetId is intentional)
      assetid: videoData.experienceId + (isVPAID ? '-vpaid' : '')
    };

    nielsenInstance.ggPM('loadMetadata', meta);
    loadedMetadata[player.id()] = 'preroll';
  });
}

function stop(videoData, player) {
  return getInstance(player).then(function (nielsenInstance) {
    if (!nielsenInstance) {
      return null;
    }

    nielsenInstance.ggPM('stop', Math.ceil(videoData.offset));
    loadedMetadata[player.id()] = null;
  });
}

function end(videoData, player) {
  return getInstance(player).then(function (nielsenInstance) {
    if (!nielsenInstance) {
      return null;
    }

    nielsenInstance.ggPM('end', Math.ceil(videoData.offset));
    loadedMetadata[player.id()] = null;
  });
}

function setPlayhead(player, isStreaming) {
  return getInstance(player).then(function (nielsenInstance) {
    if (!nielsenInstance) {
      return null;
    }

    if (loadedMetadata[player.id()]) {
      var seconds = isStreaming ? Math.floor(Date.now() / 1000) : Math.round(player.currentTime());

      nielsenInstance.ggPM('setPlayheadPosition', seconds);
    }
  });
}

function getSubbrand(channel) {
  if (typeof channel !== 'string') {
    return '';
  }

  if (/femail/gi.test(channel)) {
    return 'c01';
  }

  if (/health/gi.test(channel)) {
    return 'c02';
  }

  if (/home/gi.test(channel)) {
    return 'c03';
  }

  if (/news/gi.test(channel)) {
    return 'c04';
  }

  if (/science/gi.test(channel)) {
    return 'c05';
  }

  if (/sport/gi.test(channel)) {
    return 'c06';
  }

  if (/showbiz/gi.test(channel)) {
    return 'c07';
  }

  if (/travel/gi.test(channel)) {
    return 'c08';
  }

  return 'b01';
}

module.exports = {
  end: end,
  initInstance: initInstance,
  preroll: preroll,
  prerollEnded: stop,
  videoReset: stop,
  setPlayhead: setPlayhead,
  videoStart: videoStart,
  getSubbrand: getSubbrand
};
